import { useState, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

const UseKotPrint = (arr1, roomNo, totalAmount) => {
  const [kotData, setKotData] = useState({
    foodItem: [],
    roomNo: roomNo, // Assuming roomNo is passed as an argument
    kotNo: '',
    totalBilling: totalAmount
  });
  
  const customerId = localStorage.getItem('customerId');

  const kotPrint = async () => {
    const randomKotNumber = Math.floor(Math.random() * 10000);

    setKotData({
      ...kotData,
      foodItem: arr1,
      roomNo: roomNo,
      kotNo: `${roomNo}-${randomKotNumber}`,
      totalBilling: totalAmount
    });
  };

  useEffect(() => {
    const printKot = async () => {
      if (kotData.kotNo) {
        const printData = `
          <h2>KOT Details</h2>
          <p>Food Items:</p>
          <table>
            <thead>
              <tr>
                <td>Name</td>
                <td>Quantity</td>
                <td>Price</td>
              </tr>
            </thead>
            <tbody>
              ${kotData.foodItem.map((item) => `
                <tr key="${item._id}">
                  <td>${item.name}</td>
                  <td>${item.quantity}</td>
                  <td>${item.price}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
          <p>Room No.: ${kotData.roomNo}</p>
          <p>KOT No.: ${kotData.kotNo}</p>
        `;
        
        try {
          const response = await axios.post('https://api.hotel.tdrsoftware.in/api/kotBillRegister', {
            customerId: customerId,
            data: kotData
          });
          
          console.log('Bill saved:', response.data);
          toast.success(response.data.message);
          
          // Create a hidden iframe
          const iframe = document.createElement('iframe');
          iframe.style.position = 'fixed';
          iframe.style.top = '-9999px'; // Hide offscreen
          document.body.appendChild(iframe);
          
          // Write content to iframe
          const iframeDoc = iframe.contentWindow.document;
          iframeDoc.open();
          iframeDoc.write(printData);
          iframeDoc.close();
          
          // Trigger print dialog
          iframe.contentWindow.focus();
          iframe.contentWindow.print();
          
          // Remove iframe after printing
          setTimeout(() => {
            document.body.removeChild(iframe);
          }, 1000); // Adjust timeout as needed
         
        } catch (error) {
          console.error('Error saving bill:', error);
          toast.error("Error in Kot billing");
        }
      }
    };

    printKot();
  }, [kotData]);

  return kotPrint;
};

export default UseKotPrint;
