// api.js

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {toast} from 'react-hot-toast'
//https://api.hotel.tdrsoftware.in/api

const baseURL = 'https://api.hotel.tdrsoftware.in/api'; // Replace with your API base URL

export const loginUser = async (credentials) => {
    
  
    try {
        const response = await axios.post(`${baseURL}/login`, credentials);
       console.log(response.data.user);
       toast.success(response.data.message,{
        duration: 3000, // toast duration in milliseconds
         // toast position
      })
       
        return response.data; // Assuming your API returns user data including customerId
       
    } catch (error) {
        toast.error(error.response.data.message)
        throw new Error(error.response.data.message); // Handle error responses from API
    }
};

export const registerUser = async (userData) => {
    try {
        const response = await axios.post(`${baseURL}/register`, userData);
        return response.data; // Assuming your API returns user data including customerId
    } catch (error) {
        throw new Error(error.response.data.message); // Handle error responses from API
    }
};
