
export function numberToWords(number) {
    if (isNaN(number) || number === null) {
        return 'Invalid number';
    }

    const units = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine","Ten"];
    const teens = ["", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    const tens = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    const thousands = ["", "Thousand", "Million", "Billion"];

    function getHundreds(num) {
        let str = '';
        if (num >= 100) {
            str += units[Math.floor(num / 100)] + ' Hundred ';
            num %= 100;
        }
        if (num >= 20) {
            str += tens[Math.floor(num / 10)] + ' ';
            num %= 10;
        }
        if (num >= 11 && num <= 19) {
            str += teens[num - 10] + ' ';
        } else if (num > 0) {
            str += units[num] + ' ';
        }
        return str.trim();
    }

    function convert(num) {
        if (num === 0) return 'Zero';

        let result = '';
        let i = 0;
        while (num > 0) {
            let chunk = num % 1000;
            if (chunk) {
                result = getHundreds(chunk) + ' ' + thousands[i] + ' ' + result;
            }
            num = Math.floor(num / 1000);
            i++;
        }
        return result.trim();
    }

    // Handle integer and decimal parts
    const integerPart = Math.floor(number);
    const decimalPart = Math.round((number - integerPart) * 100); // Convert decimal part to integer

    let result = convert(integerPart);
    if (decimalPart > 0) {
        // Handle the case where the decimal part is less than 10 (single digit)
        result += ' and ' + (decimalPart < 10 ? 'Zero ' : '') + convert(decimalPart) + ' Cents Only';
    }else{
        result+=" Only"
    }
    
    return result.trim();
}