// Dashboard.js
import React,{useState,useEffect} from 'react';
import {
  Box,
  Grid,
  GridItem,
  Heading,
  Flex,
  Text,
  Stat,
  StatLabel,Button,
  StatNumber,Table,Tbody,Thead,Tr,Td,Select,Input
} from '@chakra-ui/react';
import Chart from './Chart';
import Donut from './dountChart';

import axios from 'axios';
import { FaRupeeSign } from 'react-icons/fa';

const Dashboard = () => {
    const customerId=localStorage.getItem("customerId")
    const [data,seTdata]=useState([])
    const [roomData,setRoomData]=useState([]);
    const [roomBill,setRoomBill]=useState([]);
    const [foodBill,setFoodBill]=useState([]);
    const [copyButtonText, setCopyButtonText] = useState('Copy'); 
    const [filteredData, setFilteredData] = useState([]);
    const [copiedRegistration, setCopiedRegistration] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); // State for selected date

    const fetchData=async()=>{
        const response=await axios.get(`https://api.hotel.tdrsoftware.in/api/getroomData/${customerId}`)
        seTdata(response.data)
        
    }
    const fetchRoomData=async()=>{
      const response=await axios.get(`https://api.hotel.tdrsoftware.in/api/getRoomBookData/${customerId}`)
      setRoomData(response.data)
      setFilteredData(response.data)
     
  }
  const fetchRoomBillData=async()=>{
    const response=await axios.get(`https://api.hotel.tdrsoftware.in/api/todayRoomBillSum/${customerId}`)
      setRoomBill(response.data.totalSum)
      
  }
  console.log(roomBill,'roombill')
  const fetchFoodBillData=async()=>{
    const response=await axios.get(`https://api.hotel.tdrsoftware.in/api/todayFoodBillSum/${customerId}`)
      setFoodBill(response.data.totalFoodSum)
      
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterData(event.target.value);
  };
  const filterData = (term) => {
    if (!term) {
      setFilteredData(data); // Reset filteredData to all data if search term is empty
    } else {
      const filtered = roomData.filter(item =>
         item.name.includes(term) ||  item.phoneNo.includes(term) 
      );
      setFilteredData(filtered);
    }
  };
   // Function to copy registration number to clipboard
   const copyRegistrationNumber = (registrationNumber,id) => {
    navigator.clipboard.writeText(registrationNumber);
    setCopiedRegistration(registrationNumber); 
    // alert(`Registration number ${registrationNumber} copied to clipboard!`);
  };
    

console.log(roomData,'roomData')
    let booked=0;
    let notBooked=0;
    const bookedRooms=data.map((item)=>{
        if(item.availability == false){
            return booked++;
        }
    })
    const notBookedRooms=data.map((item)=>{
        if(item.availability == true){
            return notBooked++;
        }
    })
    const bookedRoomNos=data.filter((item)=>{
      if(item.availability == true){
        return item.roomNo;
      }
    })
    
    console.log(bookedRoomNos,"bookedRooms");
   
    useEffect(()=>{
        fetchData()
        fetchRoomData()
        fetchFoodBillData();
        fetchRoomBillData()
       
    },[customerId])
   
  return (
    <Box p="6" bg='
#e6cca3
'>
      <Text as='b' fontSize='2xl' mb={20}>Admin Dashboard</Text>
      <Box display='flex' flexDirection='row' justifyContent='space-around' alignItems='center' mt={10}>
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' bg='#0F172B' borderRadius='12px' p={4} >
          <Text  fontSize='2xl' color='white'>Today's Sales </Text>
          <Text display='flex' alignItems='center' color='#FEA116' as='b' fontSize='xl'><FaRupeeSign/> {roomBill+foodBill} </Text>
        </Box>
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' bg='#0F172B'  borderRadius='12px' p={4}>
          <Text  fontSize='2xl' color='white'>Today's RoomBill </Text>
          <Text display='flex' alignItems='center' color='#FEA116' as='b' fontSize='xl'><FaRupeeSign/> {roomBill} </Text>
        </Box>
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' bg='#0F172B'  borderRadius='12px' p={4}>
          <Text  fontSize='2xl' color='white'>Today's FoodBill </Text>
          <Text display='flex' alignItems='center' color='#FEA116' as='b' fontSize='xl'><FaRupeeSign/> {foodBill} </Text>
        </Box>
      </Box>
      
      <Grid templateColumns="repeat(2, 1fr)" gap={8} mt={10}>
      <GridItem bg='#EAE5DF ' p={2} borderRadius={6} height="350px" overflowY="auto" overflowX='auto'  >
          
          <Flex align="center" justify="center" >
            <Stat>
              <StatLabel as='b' fontSize='2xl'>Total Rooms available</StatLabel>
              <StatNumber>{notBooked}</StatNumber>
                 {/* <StatLabel as='b' fontSize='2xl'>
                  RoomNo</StatLabel> */}
                  <Table size='sm' variant='striped' colorScheme='teal' overflowY='auto'>
                    <Thead>
                      <Tr>
                      <Td>RoomNo</Td>
                      <Td>Category</Td>
                      <Td>Ac/Non-Ac</Td>
                      <Td>Rate</Td>
                      </Tr>
                    </Thead>
                    <Tbody>
                  {bookedRoomNos.map((room)=>(
                    <Tr ml={3}>
                      <Td>{room.roomNo}</Td>
                      <Td>{room.category}</Td>
                      <Td>{room.ac}</Td>
                      <Td>{room.rate}</Td>
                    </Tr>
                  ))}
                  </Tbody>
                  </Table>


            </Stat>
          </Flex>
        </GridItem>
        <GridItem bg='#EAE5DF ' p={2} borderRadius={6} height='350px'  overflowY='auto' overflowX='auto' >
        {/* <Input w='40%'
        type="text"
        placeholder="Search by Phone No or Registration"
        value={searchTerm}
        onChange={handleSearchChange}
        mb={4} // Adds margin bottom for spacing
      /> */}
          <Flex align="center" justify="center" >
      
            <Stat>
              <StatLabel as='b' fontSize='2xl'>Total Rooms Booked</StatLabel>

              <StatNumber>{booked}</StatNumber>
              {/* <Input w='80%'
        type="text"
        placeholder="Search by  Registration and PhoneNo"
        value={searchTerm}
        onChange={handleSearchChange}
        mb={4} // Adds margin bottom for spacing
      /> */}
              <Table size='sm' variant='striped' colorScheme='blue' overflowY='auto'>
                    <Thead>
                      <Tr>
                      <Td>RoomNo</Td>
                      <Td>Name</Td>
                     
                      <Td>Phone</Td>
                      <Td>Registration</Td>
                      <Td>Copy</Td>
                      
                      </Tr>
                    </Thead>
                    <Tbody>
                  {roomData.map((room)=>(
                    <Tr ml={1}>
                      <Td>{room.roomNo}</Td>
                      <Td>{room.name}</Td>
                      
                      <Td>{room.phoneNo}</Td>
                      <Td>{room.registration}</Td>
                      <Td>
                      <Button
                          size="sm"
                          onClick={() => copyRegistrationNumber(room.registration)}
                          colorScheme='blue'
                          disabled={copiedRegistration === room.registration ? true : false} // Disable button if already copied
                        >
                          {copiedRegistration === room.registration ? 'Copied' : 'Copy'}
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                  </Tbody>
                  </Table>
            </Stat>
          </Flex>
        </GridItem>
        <GridItem bg='#EAE5DF ' p={5} borderRadius={6} overflowX='auto'>
          <Chart />
          <Flex align="center" justify="center" >
            <Stat>
              <StatLabel>RoomBill</StatLabel>
              {/* <StatNumber>245</StatNumber> */}
            </Stat>
          </Flex>
        </GridItem>
        <GridItem  bg='#EAE5DF ' p={2} borderRadius={6}>
          <Donut mt={5}/>
          <Flex align="center" justify="center" >
            <Stat>
              <StatLabel>Total Bills</StatLabel>
              {/* <StatNumber>245</StatNumber> */}
            </Stat>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Dashboard;
