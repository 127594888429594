import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Select,
    Box,
    Flex,Image,
    Text,Button,Card,CardBody,SimpleGrid,
    Textarea
  } from "@chakra-ui/react";
  import { toast } from "react-hot-toast";
  import { useSelector } from "react-redux";
  import menu1 from '../../assets/img/biriyani1.jpg'
  import  menu2 from '../../assets/img/chickencurry.jpg'
  import menu3 from '../../assets/img/polao.jpg'
  import axios from 'axios'
  import "react-calendar/dist/Calendar.css";
  import Calendar from "react-calendar";
  import React, { useState,useEffect } from "react";
  const array = [
    { src: menu1, label: 'Biriyani' },
    { src: menu2, label: 'Chicken Curry' },
    { src: menu3, label: 'Polao' },
   
  ];
  
 
  
  const FoodRegister = () => {
    // const [data1, setData1] = useState(new Date());
    // console.log(data1.toDateString());
    // const [data2, setData2] = useState(new Date());
    // console.log(data2.toDateString());
    // const [total, setTotal] = useState(0);
    // const [advance, setAdvance] = useState(0);
    //let dueAmount = 0;
    const [dueAmount, setDueAmount] = useState(0); 
    const [selectedFile, setSelectedFile] = useState(null);
  const[imageURL,setImageURL]=useState(null);
  const [uploading, setUploading] = useState(false);
  const [images,setImages]=useState([])
 const customerId=localStorage.getItem('customerId')
    const handlePost = async () => {
      try {
        setUploading(true);
        const formData = new FormData();
        formData.append('customerId', customerId);
        formData.append('image', selectedFile);  // Ensure the field name is 'image' here
    
        const response = await axios.post("https://api.hotel.tdrsoftware.in/images", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        const data = response.data;
        setImageURL(Object.values(data).join());
     toast.success("Image uploaded successfully");
        console.log(data,'data')
      } catch (err) {
        toast.error(err);
      } finally {
        setUploading(false);
      }
      
    };
    console.log(imageURL,'imageURL')
    
    const [formData, setFormData] = useState({
      name: "",
      description: "",
      category:"",
     
      rate: 0,
      sgst: 0,
      cgst: 0,
      image: "",
      type:'',code:''
      
      
    });
    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
     
      
    }
      

    const validateForm = () => {
      const requiredFields = [
        "name",
        "description",
        "category",
       
        "rate",
          
       
        
        "type",
        "code"
        
      ];
  
      for (const field of requiredFields) {
        if (!formData[field]) {
          return false;
        }
      }
      return true;
    };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    const handleSubmit = async(e) => {
      if (!validateForm()) {
        toast.error("Please fill in all required fields.");
        return;
      }
      e.preventDefault();
      // Here you can send formData to your backend server or process it further
      console.log("Form submitted with data:", formData);
     
      // Reset form after submission
      setFormData({
        name: formData.name,
      description: formData.description,
      category:formData.category,
     
      rate: formData.rate,
      sgst: formData.rate*0.025,
      cgst: formData.rate*0.025,
      image: imageURL,
      
      type:formData.type,
      code:formData.code
      
       
      });
    
      
    };
    const save=async(e)=>{
      if (!validateForm()) {
        toast.error("Please fill in all required fields.");
        return;
      }
      try{
        const response=await axios.post("https://api.hotel.tdrsoftware.in/api/foodRegister",{
          customerId:customerId,data:formData
        });
        console.log('Data Submitted: ',response.data);
        toast.success(response.data.message)
        setFormData({
          name: "",
        description: "",
        category:"",
       
        rate: "",
        sgst: "",
        cgst: "",
        image:"",
        
        type:"",
        code:''
        
        
         
        });
      }catch(error){
        toast.error(error)
      }
    }
    
    return (
      <Box   bg='#EAE5DF '
      columns={{ sm: 2, md: 4 }}
      spacing='8'
      p='10'
      textAlign='center'
      
      color='black' width='full' height='fit-content' overflowX='auto'  >
        
        <FormControl isRequired onSubmit={handleSubmit}>
          <Box display="grid"  gridGap={1} >
            <Flex
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
             
            >
              <Box width="40%"  display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              
              p={4}>
                <FormLabel  >Food Name</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Enter food Item"  name='name' value={formData.name} onChange={handleInputChange} />
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Description</FormLabel>
                <Textarea w='70%' bg='#FAFAFA ' type="text" placeholder="Enter Description" name='description' value={formData.description} onChange={handleInputChange} />
              </Box>
            </Flex>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Category</FormLabel>
                <Input w='66%' bg='#FAFAFA ' placeholder="Enter Category" name='category' value={formData.category} onChange={handleInputChange}/>
                 
                 
                
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Type</FormLabel>
                <Select w='70%'  bg='#FAFAFA ' placeholder="Enter Type" name='type' value={formData.type} onChange={handleInputChange} >
                  
                  <option>Veg</option>
                  <option>Non-Veg</option>
                </Select>
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Code</FormLabel>
                <Input w='70%'  bg='#FAFAFA ' placeholder="Enter Code" name='code' value={formData.code} onChange={handleInputChange} />
                  
                  
              </Box>
             
            </Box>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Rate</FormLabel>
                <Input w='80%'  bg='#FAFAFA ' placeholder="Enter Food Rate" name='rate' value={formData.rate} onChange={handleInputChange}/>
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>SGST</FormLabel>
                <Input w='80%' bg='#FAFAFA ' placeholder="Enter SGST" name='sgst' value={formData.sgst} onChange={handleInputChange} />
              </Box>
            </Box>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>CGST</FormLabel>
                <Input w='80%' bg='#FAFAFA ' placeholder="Enter SGST" name='cgst' value={formData.cgst} onChange={handleInputChange} />
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Image</FormLabel>
                <Input w='70%' type="file" bg='#FAFAFA ' onChange={handleFileChange}  />
                <Button variant='solid' onClick={handlePost} disabled={uploading}>
        {uploading ? "Uploading..." : "Upload"}
      </Button>
                
              </Box>
             
            </Box>
            
            
           
             
             
           
            
           
            
            
          <hr/>
          

          </Box>
         
          <Button type="submit" variant='solid' mr={4} color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={handleSubmit}>Generate Gst</Button>
          <Button type="submit" variant='solid' color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={save}>Save</Button>
        </FormControl>
        
      </Box>
    );
  };
  
  export default FoodRegister;
  