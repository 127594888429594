import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Select,
  Box,
  Flex,
  Image,
  Text,
  Button,
  Card,
  CardBody,
  SimpleGrid,
  Textarea,
  TableContainer,
  Table,
  TableCaption,
  Thead,List,ListItem,
  Tr,Th,Tbody,Td
} from "@chakra-ui/react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import menu1 from "../../assets/img/biriyani1.jpg";
import menu2 from "../../assets/img/chickencurry.jpg";
import menu3 from "../../assets/img/polao.jpg";
import axios from "axios";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";

const StockTransfer = () => {
  const customerId = localStorage.getItem("customerId");

  const [store, setStore] = useState([]);
  const [staff, setStaff] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
 
  const [selctUnit,setSelectUnit]=useState("")
  const [searchTerm, setSearchTerm] = useState("");
  const [formData, setFormData] = useState({
    departmentFrom: "",
    departmentTo: "",
    transferBy: "",
    docNo: "",
    transferDate: "",
    item: [],
  });
  const [itemFormData, setItemFormData] = useState({
    name: "",
    quantity: 0,
    unit: '',
    
    
  });

  const storeData = async() => {
    try {
      const responsne =await  axios.get(
        `https://api.hotel.tdrsoftware.in/api/store/${customerId}`
      );
     // toast.success(responsne.data.message);
      setStore(responsne.data);
    } catch (error) {
      toast.error(error);
    }
  };
  const staffData = async() => {
    try {
      const responsne =await axios.get(
        `https://api.hotel.tdrsoftware.in/api/staff/${customerId}`
      );
     // toast.success(responsne.data.message);
      setStaff(responsne.data.staff);
    } catch (error) {
      toast.error(error);
    }
  };
  const itemFetchData = async() => {
    try {
      const responsne =await axios.get(
        `https://api.hotel.tdrsoftware.in/api/item/${customerId}`
      );
     // toast.success(responsne.data.message);
      setItemData(responsne.data.item);
    } catch (error) {
      toast.error(error);
    }
  };
  console.log(itemData,'item')

  useEffect(() => {
    storeData();
    staffData();
    itemFetchData()
  }, [customerId]);
  const validateForm = () => {
    const requiredFields = [
      "deparmentFrom",
      "departmentTo",
      "transferBy",

      "docNo",

      "transferDate",
    ];

    for (const field of requiredFields) {
      if (!formData[field]) {
        return false;
      }
    }
    return true;
  };
  const handleDelete=(index)=>{
    const newItems=itemList.filter((_,i)=> i !== index)
    setItemList(newItems)
  }
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const filtered = itemData.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleSelectItem = (item) => {
    setItemList([
      ...itemList,
      {
        name:item.name,
        quantity: 0,
        unit: item.purchaseUnit,
      },
    ]);

    setSearchTerm("");
    setFilteredItems([]);
  };

  const handleItemChange = (index, field, value) => {
    const updatedItemList = [...itemList];
    setFormData({
      name:"",
      quantity:0,
      unit:""

    })
    updatedItemList[index][field] = value;
    setItemList(updatedItemList);
  };
  //console.log(formData,'formData')
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit=(e)=>{
    e.preventDefault()
    setFormData({
      departmentFrom:formData.departmentFrom,
      departmentTo:formData.departmentTo,
      transferBy:formData.transferBy,
      docNo:formData.docNo,
      transferDate:formData.transferDate,
      item:[...itemList]
    })

  }

  const save = async (e) => {
   
    // if (!validateForm()) {
    //   console.log(validateForm)
    //   toast.error("Please fill in all required fields.");
    //   return;
    // }
    
    try {
      const response = await axios.post(
        "https://api.hotel.tdrsoftware.in/api/stockTransfer",
        {
          customerId: customerId,
          data: formData,
        }
      );
      
      toast.success(response.data.message);
      setFormData({
        departmentFrom: "",
        departmentTo: "",
        transferBy: "",
        docNo: "",
        transferDate: "",
        item: [],
      });
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Box
      bg="#EAE5DF "
      columns={{ sm: 2, md: 4 }}
      spacing="8"
      p="10"
      textAlign="center"
      color="black"
      width="full"
      height="fit-content"
      overflowX="auto"
    >
      <FormControl isRequired onSubmit={handleSubmit}>
        <Box display="grid" gridGap={1}>
          <Flex
            display="flex"
            gap="10px"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Box
              width="40%"
              size='sm'
              display="flex"
              alignItems="left"
              flexDirection='column'
              border='1px solid #d7fff7'
              bg='white'
              borderRadius="5px"
              p={4}
            >
              <FormLabel fontSize='sm'>Department From</FormLabel>
              <Select
                w="100%"
                size='sm'
                bg="#FAFAFA "
                placeholder="Department From"
                name="departmentFrom"
                value={formData.departmentFrom}
                onChange={handleInputChange}
              >
                {store?.map((item,index) => (
                  <option key={index}>{item.name}</option>
                ))}
              </Select>
            </Box>
            <Box
              width="40%"
              display="flex"
              alignItems="left"
              boxShadow="xl"
              border='1px solid #d7fff7'
              bg='white'
              flexDirection='column'
              borderRadius="5px"
              p={4}
            >
              <FormLabel fontSize='sm'>Department To</FormLabel>
              <Select
                w="100%"
                size='sm'
                bg="#FAFAFA "
                type="text"
                placeholder="Department To"
                name="departmentTo"
                value={formData.departmentTo}
                onChange={handleInputChange}
              >
                {store?.map((item,index) => (
                  <option key={index}>{item.name}</option>
                ))}
              </Select>
            </Box>
            <Box
              width="30%"
              display="flex"
              alignItems="left"
              flexDirection='column'
              border='1px solid #d7fff7'
              bg='white'
             
              borderRadius="5px"
              p={4}
            >
              <FormLabel>TransferBy</FormLabel>
              <Select
                w="100%"
                size='sm'
                bg="#FAFAFA "
                placeholder="Transfer By"
                name="transferBy"
                value={formData.transferBy}
                onChange={handleInputChange}
              >
                {staff?.map((item,index) => (
                  <option key={index}>{item.name}</option>
                ))}
              </Select>
            </Box>
            <Box
              width="30%"
              display="flex"
              alignItems="left"
              flexDirection='column'
              border='1px solid #d7fff7'
              bg='white'
              borderRadius="5px"
              p={4}
            >
              <FormLabel>Doc No</FormLabel>
              <Input
                w="100%"
                size='sm'
                bg="#FAFAFA "
                placeholder="Doc No"
                name="docNo"
                value={formData.docNo}
                onChange={handleInputChange}
              />
            </Box>
            <Box
              width="30%"
              display="flex"
              alignItems="left"
              flexDirection='column'
              border='1px solid #d7fff7'
              bg='white'
              borderRadius="5px"
              p={4}
            >
              <FormLabel>Transfer Date</FormLabel>
              <Input
              type='date'
                w="100%"
                size='sm'
                bg="#FAFAFA "
                placeholder="Transfer Date"
                name="transferDate"
                value={formData.transferDate}
                onChange={handleInputChange}
              />
            </Box>
          
          </Flex>
          

          <hr />
        </Box>


         <Box mt={4}>
          <FormLabel>Search Item</FormLabel>
          <Input
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Enter Item Name"
            bg="white"
            size="sm"
          />
          {filteredItems.length > 0 && (
            <List
              spacing={2}
              mt={2}
              bg="white"
              borderRadius="md"
              boxShadow="md"
              p={2}
            >
              {filteredItems.map((item, index) => (
                <ListItem
                  key={index}
                  p={2}
                  cursor="pointer"
                  _hover={{ bg: "#EAEAEA" }}
                  onClick={() => handleSelectItem(item)}
                >
                  {item.name}
                </ListItem>
              ))}
            </List>
          )}
        </Box>
        <TableContainer
          width="full"
          mt={10}
          border="1px solid black"
          p={4}
          bg="white"
          borderRadius="10px"
          fontSize="sm"
        >
          <Table size="sm" variant="striped" colorScheme="teal">
            <TableCaption>Item Data</TableCaption>
            <Thead>
              <Tr>
                <Th>Sl No</Th>
                <Th>Item Name</Th>
                <Th>Quantity</Th>
                <Th>Unit</Th>
                <Th>Delete</Th>
              </Tr>
            </Thead>
            <Tbody>
              {itemList.map((item, index) => (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td>{item.name}</Td>
                  <Td>
                    <Input
                      width="60%"
                      bg="white"
                      placeholder="Enter Quantity"
                      size="sm"
                      value={item.quantity}
                      onChange={(e) =>
                        handleItemChange(index, "quantity", e.target.value)
                      }
                    />
                  </Td>
                  <Td>
                    <Input
                      width="80%"
                      bg="white"
                      placeholder="Enter Unit"
                      size="sm"
                      value={item.unit}
                      onChange={(e) =>
                        handleItemChange(index, "unit", e.target.value)
                      }
                    />
                      
                  </Td>
                  <Td><Button colorScheme="red" onClick={()=>handleDelete(index)}><FaTrash/></Button></Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        <Button
          type="submit"
          variant="solid"
          color="#0F172B"
          bg="#FEA116"
          _hover={{ color: "white" }}
          mt={5}
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Button
          type="submit"
          variant="solid"
          color="#0F172B"
          bg="#FEA116"
          _hover={{ color: "white" }}
          mt={5}
          onClick={save}
        >
          Save
        </Button>
      </FormControl>
    </Box>
  );
};

export default StockTransfer;
