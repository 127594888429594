import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Image,
    Select,
    Box,
    Flex,
    Text,Button,SimpleGrid
  } from "@chakra-ui/react";
  import { useSelector } from "react-redux";
  import image from '../../assets/img/carousel-1.jpg'
  import image1 from '../../assets/img/room-2.jpg'
  import { Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react'
  import { useRef } from "react";
  import { useReactToPrint } from "react-to-print";
  import "react-calendar/dist/Calendar.css";
  import axios from 'axios'
  import Calendar from "react-calendar";
  import React, { useState,useEffect } from "react";
  import toast from "react-hot-toast";
import { numberToWords } from "../../utils/numberToWords";
import { useLocation } from "react-router-dom";
  const array = [
    "Malda",
    "Uttar Dinajpur",
    "Dakshin Dinajpur",
    "Murshidabad",
    "Birbhum",
    "Hoogly",
    "Paschim Bardhaman",
    "Purba Bardhaman",
    "Alipurduar",
    "Cooch Behar",
    "Darjeeling",
    "Jalipaiguri",
    "Kalingpong",
    "Howrah",
    "Kolkata",
    "Nadia",
    "North 24 Pargana",
    "South 24 Pargana",
    "Bankura",
    "Jhargram",
    "Purulia",
    "Purba Medinipur",
    "Paschim Medinipur",
  ];
  
  let fiterArray = array.sort((a, b) => {
    return a.localeCompare(b);
  });
  
  //console.log(fiterArray);
  const CashRegister = () => {
    const location = useLocation();
  const { roomNo, registration } = location.state || {};
  console.log('registration',registration);
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    const customerId=localStorage.getItem('customerId')
    // const [data1, setData1] = useState(new Date());
    // console.log(data1.toDateString());
    // const [data2, setData2] = useState(new Date());
    // console.log(data2.toDateString());
    // const [total, setTotal] = useState(0);
    // const [advance, setAdvance] = useState(0);
    //let dueAmount = 0;
    //const [dueAmount, setDueAmount] = useState(0);
    const token=localStorage.getItem('token');
    const [mergeData,setMergeData]=useState([])
     const [submittedData,setSubmittedData] = useState([]);
     const [toggle,setToggle] = useState(false);
     const [roomBook,setRoomBook] = useState(false);
     const [settle,setSettle]=useState([]);
     const [advanced,setAdvanced]=useState([])
     const [foodData,setFoodData]=useState([]);
     const [estimate,setEstimate]=useState([]);
     const [hallRoom,setHallRoom]=useState([]);
     const [selectedRooms,setSelectedRooms]=useState([])
    const [formData, setFormData] = useState({
      name: "",
      date: "",
      registration:"",
      paymentType:'',
      roomNo:0,
      total:'',
      advance:"",
      due:"",
      discount:"",
      phoneNo:"",
      purpose:'',
      mode:"",
      cash:'',
      remarks:'',
      online:'',
      transaction:'',
      checkInDate:"",
      checkOutDate:""
      
    });
    useEffect(() => {
      if (registration) {
          setFormData({
            name: "",
            date: "",
            registration: registration || "",
            paymentType:'',
            roomNo:0,
            total:'',
            advance:"",
            due:"",
            discount:"",
            phoneNo:"",
            purpose:'',
            mode:"",
            cash:'',
            remarks:'',
            online:'',
            transaction:'',
            checkInDate:"",
            checkOutDate:""
          });
      }
  }, [registration]);
    const fetchRoomData=async()=>{
      const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getRoomBookData/${customerId}`)
      setRoomBook(response.data)
  }
  const fetchData2=async()=>{
    const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getEstimateData/${customerId}`)
    setEstimate(response.data)
}
const fetcHallData = async () => {
  const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getCustomerdata/${customerId}`);
  setHallRoom(response.data);
  
};

  const fetchRoomData2=async()=>{
    const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getMergeBill/${customerId}`)
    setSettle(response.data)
}
  const [printData,setPrintData]=useState([])

  const fetchData = async () => {
    const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getCashRecipt/${customerId}`);
    setAdvanced(response.data);
   
  };
  const fetchFoodData = async () => {
    const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getFoodBillData/${customerId}`);
    setFoodData(response.data);
    
  };

  //const customerId=localStorage.getItem('customerId');
  const fetchData3=async()=>{
    const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getPrintData/${customerId}`)
    setPrintData(response.data)
    const rooms = response.data;
    const updatedRooms = await Promise.all(
      rooms.map(async (room) => {
        const imageResponse = await axios.get('https://api.hotel.tdrsoftware.in/image', {
          params: { customerId, key: room.image }
        });
        room.imageUrl = imageResponse.data;
        return room;
      })
    );
    //console.log(updatedRooms, 'updatedRooms');
    setPrintData(updatedRooms);
}
  const filterData = () => {
    const merge=[...roomBook,...estimate,...hallRoom];
    setMergeData(merge)
    
    // Assuming roomBook is an array and formData is an object
    const filteredRoom = merge.filter((room) => room.registration === formData.registration);
   let data=filteredRoom.map((item)=>item);
  //  const rooms = filteredRoom.map((item) => {
  //   if (item.roomNo) {
  //     return item.roomNo;
  //   } else {
  //     // Split the room string by commas, trim each part, and remove extra quotes
  //     return item.room.split(',')
  //       .map(s => s.trim().replace(/^'|'$/g, ''))  // Remove single quotes if they exist
  //         // Ensure any extra spaces are removed
  //   }
  // });
  const rooms = filteredRoom.flatMap((item) => {
    if (item.roomNo) {
      // If item.roomNo exists, return it directly
      return [item.roomNo];
    } else if(item.rooms){
      return item.rooms.split(',') 
        .map(s => s.trim().replace(/^'|'$/g, ''));
    }
    else {
      // If item.roomNo does not exist, process item.room
      return item.room.split(',') 
        .map(s => s.trim().replace(/^'|'$/g, ''));  // Process and clean up each part
    }
  });
  //  const result = `[${filteredRoom.map(item =>
  //   item.roomNo ? item.roomNo :
  //   item.room.split(',').map(s => `'${s.trim()}'`).join(',')
  // ).join(',')}]`;
  //console.log(result,'result')
 // console.log(rooms,'rooms')
   setSelectedRooms(rooms)
 //  console.log(selectedRooms,'selectedRooms')
   let room=rooms.join(", ")
   // const rooms = filteredRoom.roomNo.join(", ");
    if (filteredRoom) {
      setFormData({
        ...formData,
        registration:data[0].registration,
        name: data[0].name,
        phoneNo:data[0].phoneNo,
        checkInDate:data[0].checkIn,
        checkOutDate:data[0].checkOut,
    roomNo:data[0].room?data[0].room.trim():room,
    total:filteredRoom.mergeBill

    

      });
    }
  };
 // console.log(mergeData,'merge');
  const filterData2 = () => {
    // Assuming roomBook is an array and formData is an object
    const filteredRoom = settle.find((room) => room.registration === formData.registration);
    if (filteredRoom) {
      setFormData({
        ...formData,
       
        
    total:filteredRoom.mergeBill

    

      });
    }
  };
//console.log(advanced,'advanced')
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
//     const handleToggle=async()=>{
//       if(formData.paymentType === "Settlement"){
        
//         setToggle(!toggle);
//         if(!Array.isArray(settle) || settle.length > 0){
//         const filteredRoom = settle.find((room) => room.registration === formData.registration);
//         const filterRoom2=advanced.filter((item)=>item.registration === formData.registration);
//         const data=filterRoom2.filter((item)=>item.paymentType === "Advance" );
//         const totalOnline=data.reduce((acc,d)=>{return acc+parseInt(d.online? d.online : 0)},0)
//         const totalCash=data.reduce((acc,d)=>{ return acc+parseInt(d.cash)},0)
//         const data2=filterRoom2.filter((item)=>item.paymentType === "Settlement" );
//         const totalOnline2=data2.reduce((acc,d)=>{return acc+parseInt(d.online? d.online : 0)},0)
//         const totalCash2=data2.reduce((acc,d)=>{ return acc+parseInt(d.cash)},0);
//         const totalSettlement=totalOnline2+totalCash2;
//         console.log(totalSettlement,totalOnline2,totalCash2,"totalsettlement")
//         console.log(filteredRoom.mergeBill,"totalsettlement")
        
//         if (filteredRoom) {
//           const totalAmount = filteredRoom.mergeBill !== undefined 
//                               ? parseInt(filteredRoom.mergeBill || 0) 
//                               : parseInt(filteredRoom.total || 0);
//           setFormData({
//               ...formData,
//               total: totalAmount - totalSettlement,
//               advance: totalCash + totalOnline
//           });
//   }
// }else{
//     const filteredRoom = estimate.find((room) => room.registration === formData.registration);
//         const filterRoom2=advanced.filter((item)=>item.registration === formData.registration);
//         const data=filterRoom2.filter((item)=>item.paymentType === "Advance" );
//         const totalOnline=data.reduce((acc,d)=>{return acc+parseInt(d.online? d.online : 0)},0)
//         const totalCash=data.reduce((acc,d)=>{ return acc+parseInt(d.cash)},0)
//         const data2=filterRoom2.filter((item)=>item.paymentType === "Settlement" );
//         const totalOnline2=data2.reduce((acc,d)=>{return acc+parseInt(d.online? d.online : 0)},0)
//         const totalCash2=data2.reduce((acc,d)=>{ return acc+parseInt(d.cash)},0);
//         const totalSettlement=totalOnline2+totalCash2;
//         console.log(totalSettlement,totalOnline2,totalCash2,"totalsettlement")
//         console.log(filteredRoom.total,"totalsettlement")
//         console.log(filteredRoom.room,"totalsettlement")
        
//     if (filteredRoom) {
//       setFormData({
//         ...formData,
       
        
//     total:parseInt(filteredRoom.total || 0)-totalSettlement,
//     advance:totalCash+totalOnline
    

//       });
//     }

//   }
//       }else{
//         setToggle(false)
//       }
//     }
const handleToggle = async () => {
  if (formData.paymentType === "Settlement") {
      setToggle(!toggle);

      // First, attempt to find the room in `settle`
      const filteredRoomFromSettle = settle.find(room => room.registration === formData.registration);
      const filterhallRoom = hallRoom.filter(item => item.registration === formData.registration);

      if (filteredRoomFromSettle) {
          // If the room is found in `settle`, proceed with calculations for `settle`
          const filterRoom2 = advanced.filter(item => item.registration === formData.registration);
          const data = filterRoom2.filter(item => item.paymentType === "Advance");
          const totalOnline = data.reduce((acc, d) => acc + parseInt(d.online || 0), 0);
          const totalCash = data.reduce((acc, d) => acc + parseInt(d.cash || 0), 0);
          const data2 = filterRoom2.filter(item => item.paymentType === "Settlement");
          const totalOnline2 = data2.reduce((acc, d) => acc + parseInt(d.online || 0), 0);
          const totalCash2 = data2.reduce((acc, d) => acc + parseInt(d.cash || 0), 0);
          const totalSettlement = totalOnline2 + totalCash2;
        //  console.log(totalSettlement, totalOnline2, totalCash2, "totalsettlement");
         // console.log(filteredRoomFromSettle.mergeBill, "mergeBill");

          // Use mergeBill if it exists, otherwise use total
          const totalAmount = filteredRoomFromSettle.mergeBill !== undefined 
                              ? parseInt(filteredRoomFromSettle.mergeBill || 0) 
                              : parseInt(filteredRoomFromSettle.total || 0);
          setFormData({
              ...formData,
              total: totalAmount - totalSettlement,
              advance: totalCash + totalOnline
          });
      }else if(filterhallRoom){
        //const filterhallRoom=hallRoom.filter(item => item.registration === formData.registration);
        const total=filterhallRoom.reduce((acc,d)=>{return acc+parseInt(d.total)},0)
        const advance=filterhallRoom.reduce((acc,d)=>{return acc+parseInt(d.advance)},0)
        const due=filterhallRoom.reduce((acc,d)=>{return acc+parseInt(d.due)},0)
        setFormData({
          ...formData,
          total:total,
          advance:advance,
          due:due
        })
      }
       else {
          // If the room is not found in `settle`, fall back to `estimate`
          const filteredRoomFromEstimate = estimate.find(room => room.registration === formData.registration);
          if (filteredRoomFromEstimate) {
              const filterRoom2 = advanced.filter(item => item.registration === formData.registration);
              const data = filterRoom2.filter(item => item.paymentType === "Advance");
              const totalOnline = data.reduce((acc, d) => acc + parseInt(d.online || 0), 0);
              const totalCash = data.reduce((acc, d) => acc + parseInt(d.cash || 0), 0);
              const data2 = filterRoom2.filter(item => item.paymentType === "Settlement");
              const totalOnline2 = data2.reduce((acc, d) => acc + parseInt(d.online || 0), 0);
              const totalCash2 = data2.reduce((acc, d) => acc + parseInt(d.cash || 0), 0);
              const totalSettlement = totalOnline2 + totalCash2;
            //  console.log(totalSettlement, totalOnline2, totalCash2, "totalsettlement");
           //   console.log(filteredRoomFromEstimate.total, "total");

              // Set form data with the total from `estimate`
              setFormData({
                  ...formData,
                  total: parseInt(filteredRoomFromEstimate.total || 0) - totalSettlement,
                  advance: totalCash + totalOnline
              });
          } else {
              // Handle the case where no room is found in either `settle` or `estimate`
             // console.error("No matching room found in settle or estimate.");
              setFormData({
                  ...formData,
                  total: 0, // Or some appropriate default value
                  advance: 0
              });
          }
      }
  } else {
      setToggle(false);
  }
};

  //  console.log(toggle,formData.paymentType)
   // console.log(customerId,token);
    const validateForm = () => {
      const requiredFields = [
        "name",
        "date",
        "roomNo",
        "phoneNo",
        "remarks"
        
     
        
      ];
  
      for (const field of requiredFields) {
        if (!formData[field]) {
          return false;
        }
      }
      return true;
    };
  useEffect(()=>{
    fetchRoomData()
    fetchData3()
    fetchRoomData2()
    fetchData()
    fetchData2()
    fetcHallData()
  },[customerId])
  const handleRoomAvailability=async()=>{
    let rooms=selectedRooms.map((item)=>parseInt(item))
    try {
      const availabilityRequests = rooms.map(async (room) => {
        const response = await axios.put(`https://api.hotel.tdrsoftware.in/api/updateRoomAvailability/${customerId}/${room}`);
       // console.log(response.data);
        toast.success(response.data.message);
        return response;
      });
      
  
      // Wait for all availability requests to complete
      const responses = await Promise.all(availabilityRequests);
      console.log("All rooms availability updated:", responses);
  
    } catch (error) {
      console.error("Error updating room availability:", error);
      toast.error("Failed to update room availability. Please try again.");
    }
  }
  const handleFoodStatus=async()=>{
    try{
      const response =await axios.put(`https://api.hotel.tdrsoftware.in/api//updateFoodBillsByRegistration/${customerId}/${formData.registration}`)
    //  console.log(response.data)
      toast.success(response.data.message)

    }catch(error){
      console.log(error)
    }
  }
    const handleSubmit = async(e) => {
      e.preventDefault();
      if (!validateForm()) {
        toast.error("Please fill in all required fields.");
        return;
      }
  
  
      
  
      
      setFormData({
        name: formData.name,
      date: formData.date,
      registration:formData.registration,
      paymentType:formData.paymentType,
      roomNo:formData.roomNo,
      total:formData.total,
      advance:formData.advance,
      due:formData.due,
discount:formData.discount,
      phoneNo:formData.phoneNo,
      purpose:formData.purpose,
      mode:formData.mode,
      cash:formData.cash,
      remarks:formData.remarks,
      online:formData.online,
      transaction:formData.transaction,
      checkInDate:formData.checkInDate,
      checkOutDate:formData.checkOutDate
       
       
      
      });
      setSubmittedData(formData);
    
      
    };
    const cash = formData.cash || '0';  // Default to '0' if formData.cash is an empty string
const online = formData.online || '0';  // Default to '0' if formData.online is an empty string
    const handleDueFocus = () => {
      setFormData({
        ...formData,
        due: formData.total - formData.advance-formData.discount,
      });
    };
    const handleOnlineFocus = () => {
      if(formData.paymentType === "Settlement"){
      setFormData({
        ...formData,
        online: formData.due - formData.cash,
      });
    }
    };
    const save=async(e)=>{
      if (!validateForm()) {
        toast.error("Please fill in all required fields.");
        return;
      }
      try{
        const response=await axios.post("https://api.hotel.tdrsoftware.in/api/cashRecipt",{
          customerId:customerId,data:formData
        });
       // console.log('Data Submitted: ',response.data);
        
        toast.success(response.data.message)
        if(formData.paymentType === "Settlement" ){
        handleRoomAvailability()
        handleFoodStatus()
        }
        setFormData({
          name: "",
      date: "",
      registration:"",
      paymentType:'',
      roomNo:0,
      total:'',
      advance:"",
      due:"",

      phoneNo:"",
      purpose:'',
      mode:"",
      cash:'',
      remarks:'',
      online:'',
      transaction:'',
      checkInDate:"",
      checkOutDate:"",
      discount:""
        })

      }catch(error){
        toast.error(error)
      }
    }
  //  console.log(formData,'formdata');
    let arr=Object.entries(submittedData);
   // console.log(arr)
   
    //console.log(newObj);
   
   
    
    // due=total-advance
    return (
      <Box   bg='#EAE5DF '
      columns={{ sm: 2, md: 4 }}
      spacing='8'
      p='10'
      textAlign='center'
      
      
      color='black' width='100%' height='fit-content'   >
        
        <FormControl isRequired onSubmit={handleSubmit}>
          <Box display="grid"  gridGap={1} >
          <Box width="full" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4} justifyContent='center' gap='5px' mb='6'>
                <FormLabel>RegistrationNo</FormLabel>
                <Input w='38%' bg='#FAFAFA' placeholder="Enter Registration" name='registration' value={formData.registration} onChange={handleInputChange} />
                <Button  variant='solid' mr={4} color='#0F172B' bg='#FEA116' _hover={{color:'white'}}   onClick={filterData}>Search</Button>
              </Box>
            <Flex
              display="flex "
              gap="10px"
              alignItems="center"
              
              justifyContent="space-evenly"
             
            >
              <Box width="40%"  display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              
              p={4}>
                <FormLabel  >Name</FormLabel>
                <Input w='80%'  bg='#FAFAFA ' placeholder="First name"  name='name' value={formData.name} onChange={handleInputChange} />
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Date</FormLabel>
                <Input w='80%'  bg='#FAFAFA ' type="date" placeholder="Date" name='date' value={formData.date} onChange={handleInputChange} />
              </Box>
            </Flex>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>PhoneNo</FormLabel>
                <Input w='74%'  bg='#FAFAFA ' placeholder="Enter your mobile No" name='phoneNo' value={formData.phoneNo} onChange={handleInputChange}/>
              </Box>
              {/* <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Purpose</FormLabel>
                <Input w='70%'  bg='#FAFAFA ' placeholder="Enter purpose for cash " name='purpose' value={formData.purpose} onChange={handleInputChange}/>
              </Box> */}
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Room No</FormLabel>
                <Input w='71%' bg='#FAFAFA ' placeholder="Enter room no" name='roomNo' value={formData.roomNo} onChange={handleInputChange}/>
              </Box>
              </Box>
              <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>CheckIn</FormLabel>
                <Input type='date' w='76%'  bg='#FAFAFA ' placeholder="checkIn" name='checkInDate' value={formData.checkInDate} onChange={handleInputChange}/>
              </Box>
              {/* <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Purpose</FormLabel>
                <Input w='70%'  bg='#FAFAFA ' placeholder="Enter purpose for cash " name='purpose' value={formData.purpose} onChange={handleInputChange}/>
              </Box> */}
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>CheckOut</FormLabel>
                <Input type='date' w='76%' bg='#FAFAFA ' placeholder="Enter room no" name='checkOutDate' value={formData.checkOutDate} onChange={handleInputChange}/>
              </Box>
              </Box>
              <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Payment Type</FormLabel>
                <Select w='65%'  bg='#FAFAFA ' placeholder="Enter Payment Type" name='paymentType'  value={formData.paymentType} onChange={handleInputChange} onClick={handleToggle}>
                  <option value={"Advance"}>Advance</option>
                  
                  <option value={"Settlement"} >Settlement</option>
                </Select>
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Remarks</FormLabel>
                <Input w='76%'  bg='#FAFAFA ' placeholder="Explain Remakrs" name='remarks' value={formData.remarks} onChange={handleInputChange} />
                  
              </Box>
              
              </Box>
              {toggle ?
              <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="23%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Total</FormLabel>
                <Input w='65%'  bg='#FAFAFA ' placeholder="Enter Total " name='total' value={formData.total} onChange={handleInputChange}/>
              </Box>
              <Box width="23%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Discount</FormLabel>
                <Input w='56%'  bg='#FAFAFA ' placeholder="Discount" name='discount' value={formData.discount} onChange={handleInputChange}/>
              </Box>
              <Box width="23%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Advance</FormLabel>
                <Input w='56%'  bg='#FAFAFA ' placeholder="Enter Advance amount " name='advance' value={formData.advance} onChange={handleInputChange}/>
              </Box>
              <Box width="23%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Due</FormLabel>
                <Input w='50%' bg='#FAFAFA ' placeholder="Enter due" name='due' value={formData.due} onChange={handleInputChange} onFocus={handleDueFocus}/>
              </Box>
              </Box>:""}
              
              <Box display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly">
                <Box width="23%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Cash</FormLabel>
                <Input w='50%' bg='#FAFAFA ' placeholder="Cash" name='cash' value={formData.cash} onChange={handleInputChange}/>
              </Box>
              <Box width="23%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Mode</FormLabel>
                <Select w='65%' bg='#FAFAFA ' placeholder="Mode" name='mode' value={formData.mode} onChange={handleInputChange}>
                  
                  <option>GooglePe</option>
                  <option>Phonepe</option>
                  <option>Paytm</option>
                </Select>
              </Box>
              <Box width="23%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel> ID</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Enter Transaction" name='transaction' value={formData.transaction}  onChange={handleInputChange}/>
              </Box>
              
              <Box width="23%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>{formData.mode} Amount</FormLabel>
                <Input w='60%' bg='#FAFAFA ' placeholder="Enter Online Amount" name='online' value={formData.online} onFocus={handleOnlineFocus} onChange={handleInputChange}/>
              </Box>
            </Box>
            
            
            
            
              
             
           
           
            {/* <Box
              display="flex"
              rowGap="10px"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Ground Floor Hall Room</FormLabel>
                <Select width="50%"  name='groundHall' value={formData.groundHall} onChange={handleInputChange}>
                  <option>Yes</option>
                  <option>No</option>
                </Select>
              </Box>
              <Box
                width="40%"
                display="flex"
                alignItems="center"
                overflowY="srcollable"
                boxShadow='xl'
                borderRadius="5px"
                p={4}
              >
                <FormLabel>First Floor Hall Room</FormLabel>
                <Select width="50%" name='firstFloor' value={formData.firstFloor} onChange={handleInputChange} >
                  <option>A.C</option>
                  <option>Non-A.C</option>
                </Select>
              </Box>
            </Box> */}
            {/* <Box
              display="flex"
              columnGap="20px"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Box width="20%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4} >
                <FormLabel>Stall</FormLabel>
                <Select w='80%'  bg='#FAFAFA ' placeholder="Yes/No" width="50%" name='stall' value={formData.stall} onChange={handleInputChange}>
                  <option>Yes</option>
                  <option>No</option>
                </Select>
              </Box>
              <Box width="20%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Extra Electricity</FormLabel>
                <Select w='80%' bg='#FAFAFA ' width="50%" placeholder="Yes/No" name='electricity' value={formData.electricity} onChange={handleInputChange}>
                  <option>Yes</option>
                  <option>No</option>
                </Select>
              </Box>
              <Box
                width="30%"
                display="flex"
                alignItems="center"
                overflowY="srcollable"
                boxShadow='xl'
                borderRadius="5px"
                
                p={4}
              >
                <FormLabel>Coffe Machine/Fridge</FormLabel>
                <Select w='40%' bg='#FAFAFA ' width="50%" placeholder="Yes/No" name='coffee' value={formData.coffee} onChange={handleInputChange} >
                  <option>Yes</option>
                  <option>No</option>
                </Select>
              </Box>
              <Box
                width="20%"
                display="flex"
                alignItems="center"
                overflowY="srcollable"
                boxShadow='xl'
                borderRadius="5px"
                
                p={2}
              >
                <FormLabel>Generator</FormLabel>
                <Select w='50%' bg='#FAFAFA ' width="50%" placeholder="Yes/No" name='generator' value={formData.generator} onChange={handleInputChange} >
                  <option>Yes</option>
                  <option>No</option>
                </Select>
              </Box>
            </Box> */}
          </Box>
         
          <Button type="submit" variant='solid' color='#0F172B' mr={3} bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={handleSubmit}>Cash Bill</Button>
          <Button type="submit" variant='solid' color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={save}>Save</Button>
         
        </FormControl>
        {arr ? <Card bg={image1} mt={10} textAlign='center'  ref={componentRef} p={2} ml={2} mr={2} border='1px solid black'>
        <Text color='purple' fontSize='18px' fontFamily='sans-serif' mt={5}>Cash Invoice</Text>
          <CardHeader display='flex' justifyContent='center' alignItems='center' gap={5} p={4} boxShadow='md'>
         
          <Box>
          {printData.map((item)=>(
          <Image src={item.imageUrl} w='200px' h='120px' />
         ))}
        </Box>
         
            <Box color='purple' fontSize='18px' fontFamily='sans-serif' bg={image1} textAlign='left' >
            {printData.map((item)=>(<> 
              <Text fontSize='2xl' as='b' >{item.name}</Text>
          <Text fontSize='sm' >{item.adress}</Text>
          <Text fontSize='sm'>{item.phoneNo}</Text>
          <Text fontSize='sm'>{item.website}</Text>
          
          <Text fontSize='sm'>Email id:{item.email}</Text>
          <Text fontSize='sm'>Gst No:{item.gstNo}</Text>
          </> 
        ))}
            </Box>
          </CardHeader>
            <SimpleGrid columns={3} spacing={2} p={8} textAlign='left' border='1px solid black'>
            {arr.map(([key,value]) => (
  
              <CardBody   borderRadius="5px" p={3}>
              
              
              
  <Text color='purple' fontSize='15px' fontFamily='sans-serif'>
    {key[0].toUpperCase() + key.slice(1)}--{value}
  </Text>


             
              
                
               
              
              </CardBody>
            ))}
            </SimpleGrid>
            <Box display='flex' justifyContent='space-between' alignItems='center' color='purple'  >
          <Box ml={4} textAlign='left'>
            <Text fontSize='md' as='b'>Bank Details</Text>
        {printData.map((item)=>(
                <>
            <Text fontSize='sm'>A/C NO - {item.acNo}</Text>
            <Text fontSize='sm'>IFSC CODE - {item.ifsc}</Text>
            <Text fontSize='sm'>BRANCH - {item.branch}</Text>
            {/* <Text>CONTACT NO - {item.phoneNo}</Text> */}
            <Text as='b' display='flex' justifyContent='end' alignItems='center'  fontSize='md'>
            Total Amount(INR - In Words): {formData.cash || formData.online ? numberToWords(parseInt(formData.cash ? formData.cash : 0)+parseInt(formData.online ? formData.online : 0)) : 'Zero'} 
        </Text>
            
            
            </>
          ))}
          </Box>
          <Box display='flex' flexDirection='row'   p={2} mr={3}>
          <Box display='flex' flexDirection='column' border='1px solid black' >
            <Text  borderBottom='1px solid black' bg='gray.200' p={1}>Summary</Text>
            <Text p={0.5}>Cash</Text>
            <Text p={0.5}>{formData.mode} </Text>
            <Text p={0.5}>Total</Text>
            {/* <Text p={0.5}>SGST Amount</Text>
            <Text p={0.5}>Grand Total</Text> */}
          </Box>
          <Box display='flex' flexDirection='column' borderTop="1px solid black"
      borderRight="1px solid black"
      borderBottom="1px solid black"
      borderLeft="0" >
         <Text p={1} borderBottom='1px solid black' bg='gray.200'>Amount</Text>
          <Text p={0.5}>{cash}</Text>
            <Text p={0.5}>{formData.online}</Text>
            <Text p={0.5}>{ parseInt(cash)+parseInt(online) }</Text>
            {/* <Text p={0.5}>{totalSgst}</Text>
            <Text p={0.5} borderTop='1px solid black'>{total}</Text> */}
          </Box>
          </Box>
        </Box>
          <CardFooter display='flex' justifyContent='space-between' p={25} textAlign='left' >
              <Box color='purple' fontSize='15px' fontFamily='sans-serif'>
              {printData.map((item)=>(
                <>
            {/* <Text>A/C NO - {item.acNo}</Text>
            <Text>IFSC CODE - {item.ifsc}</Text>
            <Text>BRANCH - {item.branch}</Text> */}
            {/* <Text>CONTACT NO - {item.phoneNo}</Text> */}
            
            
            <Text>Booking Party Signature</Text>
            <Text>Date-</Text>
            </>
          ))}
              </Box>
              <Box mr={20} color='purple' fontSize='15px' fontFamily='sans-serif'>
            <Text>Authorized Signature</Text>
            <Text>Date-</Text>
            </Box>
  
          </CardFooter>
          
        </Card>:''}
       {arr[0] ? <Button type="submit" variant='solid' color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={handlePrint}>Print</Button> : ""}
        
      </Box>
    );
  };
  
  export default CashRegister;
  