import { useSelector } from "react-redux";

import Note from './Note';
import Notes from "./Note";


export default function ShowTodo(){
    const list = useSelector((state) => state.food);
  //  console.log(list);
    //let arr=Object.entries(list);
   if (!Array.isArray(list)) {
    return <div>No FoodItems found</div>;
}


    return (
        <>
            {
                 list.map((item) => {
                    return <Notes bg='#FAFAFA '  text={item.item} price={item.price} quantity={item.quantity} total={item.total}  key={item.id} id={item.id} />
                })
            }
        </>
    )
}
