import React, { useEffect, useState,useRef } from 'react';
import { Table, TableCaption, Thead, Tbody,Button, Tr, Th, Td, TableContainer, Input,useDisclosure,AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay, } from "@chakra-ui/react";
import axios from 'axios';

import { FaEdit, FaTrash } from 'react-icons/fa';
import UpdateRoomBookModal from './updateRoomBookModal';
import toast from 'react-hot-toast';

const RoomBookData = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [roomBook, setRoomBookData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [registrationNo,setRegistrationNo]=useState('')
  const [id,setId]=useState('')
  const [isOpen1,setIsOpen]=useState(false)
  const [deleteItemId, setDeleteItemId] = useState(null);
  const cancelRef = useRef();
  //const [toggle,setToggle]=useState(false)
  const customerId = localStorage.getItem('customerId');
const { isOpen, onOpen, onClose } = useDisclosure();
  const fetchData = async () => {
    const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getRoomBookdata/${customerId}`);
    setData(response.data);
    setFilteredData(response.data); // Initialize filteredData with all data
  };
  
  const edit = async (id,registration) => {
    try {
      const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getRoomBookDataById/${customerId}/${id}`);
      setRoomBookData(response.data);
      setId(id);
      setRegistrationNo(registration)
      onOpen(); // Open the modal after fetching data
    } catch (error) {
      toast.error('Failed to fetch Cash data');
    }
  };
  const handleDelete = async () => {
    try {
      const response = await axios.delete(`https://api.hotel.tdrsoftware.in/api/deleteRoomBook/${customerId}/${deleteItemId}`);
      
        toast.success('Data deleted successfully');
        onClose1()
        fetchData();
        
    } catch (error) {
      console.error('Error deleting data:', error);
      ;
      toast.error('Failed to delete data');
    }
  }
  const DeleteModal = (id) => {
    setDeleteItemId(id);
    setIsOpen(true);
  };

  const onClose1 = () => {
    setIsOpen(false);
    setDeleteItemId(null);
  };

  useEffect(() => {
    fetchData();
  }, [customerId]);

  // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterData(event.target.value);
  };

  // Function to filter data based on search term
  const filterData = (term) => {
    if (!term) {
      setFilteredData(data); // Reset filteredData to all data if search term is empty
    } else {
      const filtered = data.filter(item =>
        item.phoneNo.includes(term) || item.registration.includes(term)
      );
      setFilteredData(filtered);
    }
  };

  return (
    <div>
     <UpdateRoomBookModal id={id} data={roomBook} registrationNo={registrationNo}  isOpen={isOpen} onClose={onClose} onOpen={onOpen} customerId={customerId} />
      <Input w='40%'
        type="text"
        placeholder="Search by Phone No or Registration"
        value={searchTerm}
        onChange={handleSearchChange}
        mb={4} // Adds margin bottom for spacing
      />

      <TableContainer width='full'>
        <Table size='sm' variant='striped' colorScheme='teal' >
          <TableCaption>Room Data</TableCaption>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Registration</Th>
              <Th>Nationality</Th>
              <Th>Purpose</Th>

              <Th>Date</Th>
              <Th>Address</Th>
              <Th>Phone No</Th>
              <Th>Arrived From</Th>
              <Th>Next Destination</Th>
              <Th>Aadhar</Th>
              <Th>CheckIn</Th>
              <Th>CheckOut</Th>
              <Th>PAX</Th>
              <Th>Adult</Th>
              <Th>Child</Th>
              <Th>Room No</Th>
              <Th>Floor No</Th>
              <Th>Category</Th>
              <Th>Ac</Th>
              <Th>Passport No</Th>
              <Th>Issue Date</Th>
              <Th>Date of Arrival</Th>
              <Th>Place of Issue</Th>
              <Th>Duration of stay in India</Th>
              <Th>Visa No</Th>
              <Th>Issue Date of Visa</Th>
              <Th>Expiry Date</Th>
              <Th>Edit</Th>
              <Th>Delete</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.map((item, index) => (
              <Tr key={index}>
                <Td>{item.name}</Td>
                <Td>{item.registration}</Td>
                <Td>{item.nationality}</Td>
                <Td>{item.purpose}</Td>
                <Td>{item.date}</Td>
                <Td>{item.address}</Td>
                <Td>{item.phoneNo}</Td>
                <Td>{item.arrivedFrom}</Td>
                <Td>{item.nextDestination}</Td>
                <Td>{item.adhar}</Td>
                <Td>{item.checkIn}</Td>
                <Td>{item.checkOut}</Td>
                <Td>{item.pax}</Td>
                <Td>{item.adult}</Td>
                <Td>{item.child}</Td>
                <Td>{item.roomNo}</Td>
                <Td>{item.floorNo}</Td>
                <Td>{item.category}</Td>
                <Td>{item.ac}</Td>
                <Td>{item.passport}</Td>
                <Td>{item.issue}</Td>
                <Td>{item.arrival}</Td>
                <Td>{item.placeOfIssue}</Td>
                <Td>{item.stay}</Td>
                <Td>{item.visa}</Td>
                <Td>{item.issueVisa}</Td>
                <Td>{item.expiry}</Td>
                <Td><Button colorScheme='green' onClick={()=>edit(item._id,item.registration)}><FaEdit/></Button></Td>
                <Td><Button colorScheme='red' onClick={()=>DeleteModal(item._id)}><FaTrash/></Button></Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <AlertDialog isOpen={isOpen1} leastDestructiveRef={cancelRef} onClose={onClose1}>
      <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Deletion
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure you want to delete this item?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose1}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};

export default RoomBookData;
