import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Select,
    Box,
    Flex,
    Text,
    Button,
    Card,
    CardBody,
    SimpleGrid,
    Textarea,
    CardHeader,
    CardFooter,
    Image
  } from "@chakra-ui/react";
  import { toast } from "react-hot-toast";
  import image from '../../assets/img/carousel-1.jpg';
  import image1 from '../../assets/img/room-1.jpg';
  import room1 from '../../assets/img/room-1.jpg'
  import room2 from '../../assets/img/room-2.jpg'
  import room3 from '../../assets/img/room-3.jpg'
  import room4 from '../../assets/img/room-1.jpg'
  import room5 from '../../assets/img/room-2.jpg'
  import room6 from '../../assets/img/room-3.jpg'
  import ShowTodo from "../../modules/billing/AddFood";
  import TextField from "../../modules/billing/TextField";
  import "react-calendar/dist/Calendar.css";
  import Calendar from "react-calendar";
  import { useReactToPrint } from "react-to-print";
  import React, { useState, useEffect, useRef } from "react";
  import axios from "axios";
  import { useSelector } from "react-redux";
import { checkboxClasses } from "@mui/material";
import { useLocation } from "react-router-dom";
  
  const RoomBilling = () => {
    const location = useLocation();
  const { roomNo, registration } = location.state || {};
    const componentRef = useRef();
    const [data,setData]=useState([]);
    const [printData,setPrintData]=useState([])
    const [roomBook,setRoomBook]=useState([]);
   // const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); // State for selected date
    
    const customerId=localStorage.getItem('customerId')
    console.log(customerId)
    const fetchData=async()=>{
        const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getRoomdata/${customerId}`)
        setData(response.data)
    }
    const fetchRoomData=async()=>{
      const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getRoomBookdata/${customerId}`)
      setRoomBook(response.data)
  }
  const fetchData3=async()=>{
    const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getPrintData/${customerId}`)
    setPrintData(response.data)
    const rooms = response.data;
    const updatedRooms = await Promise.all(
      rooms.map(async (room) => {
        const imageResponse = await axios.get('https://api.hotel.tdrsoftware.in/image', {
          params: { customerId, key: room.image }
        });
        room.imageUrl = imageResponse.data;
        return room;
      })
    );
    console.log(updatedRooms, 'updatedRooms');
    setPrintData(updatedRooms);
}
   
   
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    console.log(data);
  
    const array = [
      { roomNo: 100, floorNo: 1, category: 'Double', type: 'Deluxe', rate: 1000, sgst: 5, cgst: 5,src:room1 },
      { roomNo: 101, floorNo: 1, category: 'Single', type: 'Non-Deluxe', rate: 500, sgst: 5, cgst: 5,src:room2 },
      { roomNo: 102, floorNo: 1, category: 'Double', type: 'Deluxe', rate: 1500, sgst: 5, cgst: 5,src:room3 },
      { roomNo: 200, floorNo: 2, category: 'Double', type: 'Deluxe', rate: 2000, sgst: 5, cgst: 5,src:room4 },
      { roomNo: 201, floorNo: 2, category: 'Single', type: 'Non-Deluxe', rate: 1000, sgst: 5, cgst: 5,src:room5 },
      { roomNo: 202, floorNo: 2, category: 'Double', type: 'Deluxe', rate: 2000, sgst: 5, cgst: 5 ,src:room6 },
      { roomNo: 300, floorNo: 3, category: 'Double', type: 'Deluxe', rate: 3000, sgst: 5, cgst: 5 ,src:room1 },
      
      { roomNo: 301, floorNo: 3, category: 'Single', type: 'Non-Deluxe', rate: 2000, sgst: 5, cgst: 5 ,src:room2 },
      { roomNo: 302, floorNo: 3, category: 'Double', type: 'Deluxe', rate: 3000, sgst: 5, cgst: 5 ,src:room3 },
    ];
    const calculateNumberOfDays = (checkIn, checkOut) => {
      const startDate = new Date(checkIn);
      const endDate = new Date(checkOut);
      const differenceInTime = endDate.getTime() - startDate.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);
      return differenceInDays;
    };
    const [filteredRooms, setFilteredRooms] = useState([...array]);
    const [formData, setFormData] = useState({
      name:'',
      registration:registration ? registration : '',
      date:'',
      address:'',
      phoneNo:'',
      gstNo:0,
      checkIn:'',
      checkInTime:"",
      checkOut:'',
      checkOutTime:"",
       roomSelect:"",
      roomNo: '',
      
      rate: 0,
      sgst: 0,
      cgst: 0,
      total:0,
      
      
    });
    const [submittedData, setSubmittedData] = useState([]);
    // const handleIdentityChange = (e) => {
    //   const selected = data.find(item => item.name === e.target.value);
    //   if (selected) {
    //     setFormData({
    //       ...formData,
    //       name:selected.name,
    //   registartion:selected.registartion,
    //   date:selected.date,
    //   address:selected.address,
    //   phoneNo:selected.phoneNo,
    //   gstNo:selected.gstNo,
    //   checkIn:selected.checkIn,
    //   checkInTime:selected.checkInTime,
    //   checkOut:selected.checkOut,
    //   checkOutTime:selected.checkOutTime,

    //   roomNo: selected.roomNo,
          

    //     });
    //   }
    // }
    console.log(roomBook,"roomBook")
    const validateForm = () => {
      const requiredFields = [
        "name",
        "registration",
        "date",
        "address",
        "phoneNo",
        
        "checkIn",
        
        "checkOut",
        
    
        
       
        
      ];
  
      for (const field of requiredFields) {
        if (!formData[field]) {
          return false;
        }
      }
      return true;
    };
    // const filterData = () => {
    //   // Assuming roomBook is an array and formData is an object
    //   const filteredRoom = roomBook.find((room) => room.registration === formData.registration);
    //   console.log(filteredRoom,"filteredRoom")
    //   if (filteredRoom) {
    //     setFormData({
    //       ...formData,
    //       registration:filteredRoom.registration,
    //       name: filteredRoom.name,
         
    //   address:filteredRoom.address,
    //   phoneNo:filteredRoom.phoneNo,
    //   gstNo:filteredRoom.gstNo,
    //   checkIn:filteredRoom.checkIn,
      
    //   checkOut:filteredRoom.checkOut,
    //   roomSelect:filteredRoom.roomNo

      

    //     });
    //   }
    // };
    const filterData = () => {
      // Assuming roomBook is an array and formData is an object
      const filteredRooms = roomBook.filter((room) => room.registration === formData.registration);
      console.log(filteredRooms, "filteredRooms");
    
      if (filteredRooms.length > 0) {
        setFilteredRooms(filteredRooms);
        const roomNumbers = filteredRooms.map((room) => room.roomNo).join(", "); 
        let rooms = filteredRooms.map((room) => room.roomNo);
        let totalRate = 0;
        let totalCgst = 0;
        let totalSgst = 0;
    
        for (let room of rooms) {
          let selected = data.find((item) => item.roomNo === room);
          if (selected) {
            totalRate += selected.rate; // Assuming 'rate' is the property for the room rate
            totalCgst += selected.cgst; // Assuming 'rate' is the property for the room rate
            totalSgst += selected.sgst; // Assuming 'rate' is the property for the room rate
            console.log(selected, "selected");
          }
        }
       
       // console.log(roomRates, "roomRates");
        // Set the filtered rooms in a state variable
        console.log(filteredRooms, "filteredRooms[0]");
        // Optionally, you can set the form data with the first matched room's details
        setFormData({
          ...formData,
          registration: filteredRooms[0].registration,
          name: filteredRooms[0].name,
          address: filteredRooms[0].address,
          phoneNo: filteredRooms[0].phoneNo,
          gstNo: filteredRooms[0].gstNo,
          checkIn: filteredRooms[0].checkIn,
          checkOut: filteredRooms[0].checkOut,
         
          roomSelect: roomNumbers,
          rate: totalRate,
          sgst: totalSgst,
          cgst: totalCgst,
          roomNo:roomNumbers,
          
        });
      } else {
        // Handle the case where no matching rooms are found
        console.log("No matching rooms found");
      }
    };
    
    const handleRoomChange = (e) => {
      const selected = data.find(item => item.roomNo === e.target.value);
      if (selected) {
        setFormData({
          ...formData,
          roomNo: selected.roomNo,
          
          rate: selected.rate,
          sgst: selected.sgst,
          cgst: selected.cgst,
          image: selected.image,
          

        });
      }
      console.log(formData);
    };
  console.log(data,"data")
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
    };
    // useEffect(()=>{
    //   fetchData()
    //   if(formData !== submittedData){
    //     handleSubmit();
    //   }
    // },[formData,submittedData])



    const handleSubmit = (e) => {
      if (!validateForm()) {
        toast.error("Please fill in all required fields.");
        return;
      }
      e.preventDefault();
      const numberOfDays = calculateNumberOfDays(formData.checkIn, formData.checkOut);
      console.log("Number of days:", numberOfDays);
      // Process formData here, such as sending it to a server
      console.log("Form submitted with data:", formData);
      // Reset form after submission
    //   setFormData({
    //     roomNo: '',
    //     floorNo: '',
    //     category: '',
    //     type: '',
    //     rate: '',
    //     sgst: '',
    //     cgst: ''
    //   });
    const totalSum=numberOfDays*(formData.rate+formData.cgst+formData.sgst)
    
    setFormData({
      name:formData.name,
      registration:formData.registration,
      date:formData.date,
      address:formData.address,
      phoneNo:formData.phoneNo,
      gstNo:formData.gstNo,
      checkIn:formData.checkIn,
      checkInTime:formData.checkInTime,
      checkOut:formData.checkOut,
      checkOutTime:formData.checkInTime,
      
        roomNo: formData.roomNo,
        
        rate:numberOfDays* formData.rate,
        sgst:numberOfDays* formData.sgst,
        cgst: numberOfDays* formData.cgst,
        
        total:totalSum,
        
       
      });
      setSubmittedData(formData);
      toast.success("Bill Generated Successfully");
      return formData;
    };
    useEffect(() => {
      fetchData();
      fetchRoomData();
      fetchData3()
      
    }, [customerId]);
    let arr=Object.entries(formData);
  console.log("arr"+arr)
  const save=async(e)=>{
    if (!validateForm()) {
      toast.error("Please fill in all required fields.");
      return;
    }
    e.preventDefault();
    try{
      const response=await axios.post("https://api.hotel.tdrsoftware.in/api/roomBill",{
        customerId:customerId,data:formData
      });
      console.log('Data Submitted: ',response.data);
      toast.success(response.data.message);
  }catch(error){
    if (error.response && error.response.status === 400) {
      // Handle specific error case: Room booking already exists
      toast.error("Room billing already exists for this registration number.");
    } else {
      console.error("Error:", error);
      toast.error("Failed to bill room. Please try again later.");
    }
  }
}
 
    return (
      <Box bg='#EAE5DF' columns={{ sm: 2, md: 4 }} spacing='8' p='10' textAlign='center' color='black' width='100%' height='fit-content'>
        <FormControl isRequired onSubmit={handleSubmit}>
          <Box display="grid" gridGap={1}>
          <Box width="full" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4} justifyContent='center' gap='5px' mb='6'>
                <FormLabel>RegistrationNo</FormLabel>
                <Input w='38%' bg='#FAFAFA' placeholder="Enter Registration" name='registration' value={formData.registration} onChange={handleInputChange} />
                <Button  variant='solid' mr={4} color='#0F172B' bg='#FEA116' _hover={{color:'white'}}   onClick={filterData}>Search</Button>
              </Box>
              <Text size='2xl'> RoomNo-{formData.roomSelect}</Text>
          <Box display="flex" gap="10px" alignItems="center" justifyContent="space-evenly">
          <Box width="40%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4}>
                <FormLabel>Guest Name</FormLabel>
                <Input w='70%' bg='#FAFAFA' placeholder="Enter Guest Name" name='name' value={formData.name} onChange={handleInputChange} />
              </Box>
              <Box width="40%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4}>
                <FormLabel>Date</FormLabel>
                <Input w='80%' bg='#FAFAFA' type='date' placeholder="Enter Date" name='date' value={formData.date} onChange={handleInputChange} />
              </Box>
            </Box>
            <Box display="flex" gap="10px" alignItems="center" justifyContent="space-evenly">
            <Box width="40%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4}>
                <FormLabel>Address</FormLabel>
                <Input w='70%' bg='#FAFAFA' placeholder="Enter Address" name='address' value={formData.address} onChange={handleInputChange} />
              </Box>
              <Box width="40%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4}>
                <FormLabel>Phone No</FormLabel>
                <Input w='70%' bg='#FAFAFA' placeholder="Enter Phone no" name='phoneNo' value={formData.phoneNo} onChange={handleInputChange} />
              </Box>
            </Box>
            <Box display="flex" gap="10px" alignItems="center" justifyContent="space-evenly">
            <Box width="40%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4}>
                <FormLabel>Company Gst No</FormLabel>
                <Input w='70%' bg='#FAFAFA' placeholder="Enter Company GstNo" name='gstNo' value={formData.gstNo} onChange={handleInputChange} />
              </Box>
              </Box>
            <Box display="flex" gap="10px" alignItems="center" justifyContent="space-evenly">
            {/* <Box width="40%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4}>
                <FormLabel>Company Gst No</FormLabel>
                <Input bg='#FAFAFA' placeholder="Enter Company GstNo" name='gstNo' value={formData.gstNo} onChange={handleInputChange} />
              </Box> */}
              <Box width="40%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={3}>
                <FormLabel>ChechkIn</FormLabel>
                <Input w='35%' bg='#FAFAFA' type='date' placeholder="Enter CheckIn" name='checkIn' value={formData.checkIn} onChange={handleInputChange} />
                <Input w='35%' bg='#FAFAFA' type='time' placeholder="Enter CheckIn" name='checkInTime' value={formData.checkInTime} onChange={handleInputChange} />
              </Box>
              <Box width="40%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={3}>
                <FormLabel>ChechkOut</FormLabel>
                <Input w='35%' bg='#FAFAFA' type='date' placeholder="Enter Type" name='checkOut' value={formData.checkOut} onChange={handleInputChange} />
                <Input w='35%' bg='#FAFAFA' type='time' placeholder="Enter CheckIn" name='checkOutTime' value={formData.checkOutTime} onChange={handleInputChange} />
              </Box>
            </Box>
           
            <Flex display="flex" gap="10px" alignItems="center" justifyContent="space-evenly">
              
              
            </Flex>
            <Box display="flex" gap="10px" alignItems="center" justifyContent="space-evenly">
            <Box width="40%" display="flex" flexDirection='row' alignItems="center" boxShadow='xl' borderRadius="5px" p={4}>
                <FormLabel>Room No</FormLabel>
                <Input w='70%' bg='#FAFAFA' placeholder="Enter Room No" name='roomSelect' value={formData.roomSelect} onChange={handleRoomChange}/>
                  
                
              </Box>
                {/* <FormLabel>Category</FormLabel>
                <Select bg='#FAFAFA' placeholder="Category" name='category' value={formData.category} onChange={handleInputChange}>
                  <option value="Single">Single</option>
                  <option value="Double">Double</option>
                  <option value="Triple">Triple</option>
                </Select> */}
                <Box width="40%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4}>
                <FormLabel>Rate</FormLabel>
                <Input w='70%' bg='#FAFAFA' placeholder="Enter rate" name='rate' value={formData.rate} onChange={handleInputChange} />
              </Box>
              
              {/* <Box width="40%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4}>
                <FormLabel>Type</FormLabel>
                <Input w='70%' bg='#FAFAFA' placeholder="Enter Type" name='ac' value={formData.ac} onChange={handleInputChange} />
              </Box> */}
            </Box>
            <Box display="flex" gap="10px" alignItems="center" justifyContent="space-evenly">
              {/* <Box width="30%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4}>
                <FormLabel>rate</FormLabel>
                <Input bg='#FAFAFA' placeholder="Enter rate" name='rate' value={formData.rate} onChange={handleInputChange} />
              </Box> */}
              <Box width="40%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4}>
                <FormLabel>CGST</FormLabel>
                <Input w='70%' bg='#FAFAFA' placeholder="Enter CGST rate" name='cgst' value={formData.cgst} onChange={handleInputChange} />
              </Box>
              <Box width="40%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4}>
                <FormLabel>SGST</FormLabel>
                <Input w='70%' bg='#FAFAFA' placeholder="Enter SGST rate" name='sgst' value={formData.sgst} onChange={handleInputChange} />
              </Box>
            </Box>
            <Box  display="flex" gap="10px" alignItems="center" justifyContent="space-evenly">
                <Image src={formData.image} w='200px' h='120px' />
            </Box>
          </Box>
          <Button type="submit" variant='solid' mr={4} color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5}  onClick={handleSubmit}>Generate Gst Bill</Button>
          <Button type="submit" variant='solid' color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={save}>Save</Button>
        </FormControl>
        {arr[0] ? <Card mt={10} bg={image1} textAlign='center' boxShadow='dark-lg' ref={componentRef}>
      <Text color='purple' fontSize='18px' fontFamily='sans-serif' mt={5}>Room Invoice</Text>
        <CardHeader display='flex' justifyContent='center' alignItems='center' gap={5} p={4} boxShadow='md'>
       
        <Box>
          {printData.map((item)=>(
          <Image src={item.imageUrl} w='200px' h='120px' />
         ))}
        </Box>
       
          <Box color='purple' fontSize='18px' fontFamily='sans-serif' bg={image1} textAlign='left' >
          {printData.map((item)=>(<> 
          <Text >{item.name}</Text>
          <Text >{item.adress}</Text>
          <Text>{item.phoneNo}</Text>
          <Text>{item.website}</Text>
          <Text>Email id:{item.email}</Text>
          <Text>Gst No:{item.gstNo}</Text>
          </> 
        ))}
          </Box>
        </CardHeader>
          <Box   p={4} textAlign='left'>
         
  <SimpleGrid columns={3} spacing={4}  p={4}>
          {arr.map(([key,value]) => (

            <CardBody   borderRadius="5px" p={4}>
            
            <Text color='purple' fontSize='15px' fontFamily='sans-serif' >{ key[0].toUpperCase() +
              key.slice(1)}--{value}</Text>
             
            
            
              
             
            
            </CardBody>
          ))}
          </SimpleGrid>
          </Box>
        <CardFooter display='flex' justifyContent='space-between' p={25} textAlign='left' >
            <Box color='purple' fontSize='15px' fontFamily='sans-serif'>
            {printData.map((item)=>(
                <>
            <Text>A/C NO - {item.acNo}</Text>
            <Text>IFSC CODE - {item.ifsc}</Text>
            <Text>BRANCH - {item.branch}</Text>
            <Text>CONTACT NO - {item.phoneNo}</Text>
            
            
            <Text>Booking Party Signature</Text>
            <Text>Date-</Text>
            </>
          ))}
            </Box>
            <Box mr={20} color='purple' fontSize='15px' fontFamily='sans-serif'>
            <Text>Authorized Signature</Text>
            <Text>Date-</Text>
            </Box>

        </CardFooter>
        
      </Card>:''}
     {arr[0] ? <Button type="submit" variant='solid' color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={handlePrint}>Print</Button> : ""}
      </Box>
    );
  };
  
  export default RoomBilling;
  