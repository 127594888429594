import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Box, VStack, Button, SimpleGrid, Stack, Text, Card, CardBody, Image } from "@chakra-ui/react";
import { FaRupeeSign } from "react-icons/fa";

const FoodData = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [typeFilter, setTypeFilter] = useState('All');
  const [nameFilter, setNameFilter] = useState('All');
  const [uniqueNames, setUniqueNames] = useState([]);

  const customerId = localStorage.getItem('customerId');
  console.log(customerId);

  const fetchData = async () => {
    const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getFooddata/${customerId}`);
    const rooms = response.data;
    const updatedRooms = await Promise.all(
      rooms.map(async (room) => {
        const imageResponse = await axios.get('https://api.hotel.tdrsoftware.in/image', {
          params: { customerId, key: room.image }
        });
        room.imageUrl = imageResponse.data;
        return room;
      })
    );
    console.log(updatedRooms, 'updatedRooms');
    setData(updatedRooms);
    setFilteredData(updatedRooms);
    setUniqueNames(['All', ...new Set(updatedRooms.map(item => item.name))]);
  }

  const handleTypeFilterChange = (filter) => {
    setTypeFilter(filter);
    filterData(filter, nameFilter);
  }

  const handleNameFilterChange = (filter) => {
    setNameFilter(filter);
    filterData(typeFilter, filter);
  }

  const filterData = (type, name) => {
    let filtered = data;
    if (type !== 'All') {
      filtered = filtered.filter(item => item.type === type);
    }
    if (name !== 'All') {
      filtered = filtered.filter(item => item.name === name);
    }
    setFilteredData(filtered);
  }

  useEffect(() => {
    fetchData();
  }, [customerId]);

  return (
    <Box>
      <Box display="flex" justifyContent="center" marginBottom="20px" gap="10px" mt={10}>
        <Button onClick={() => handleTypeFilterChange('All')} colorScheme={typeFilter === 'All' ? 'green' : 'gray'}>All</Button>
        <Button onClick={() => handleTypeFilterChange('Veg')} colorScheme={typeFilter === 'Veg' ? 'green' : 'gray'}>Veg</Button>
        <Button onClick={() => handleTypeFilterChange('Non-Veg')} colorScheme={typeFilter === 'Non-Veg' ? 'green' : 'gray'}>Non-Veg</Button>
      </Box>
      <Box display="flex" justifyContent="center" marginBottom="20px" gap="10px">
        {uniqueNames.map((name, index) => (
          <Button key={index} onClick={() => handleNameFilterChange(name)} colorScheme={nameFilter === name ? 'green' : 'gray'}>
            {name}
          </Button>
        ))}
      </Box>
      <SimpleGrid minChildWidth='280px' placeItems='center' height='fit-content' gap='20px' p={10}>
        {filteredData.map((item, index) => (
          <Card maxW='sm' key={index} boxShadow='dark-lg'>
            <CardBody>
              <Image
                src={item.imageUrl}
                alt={item.roomNo}
                borderRadius='lg'
                height='300px'
                width='full'
              />
              <Stack mt='6' spacing='3'>
                <Box display='flex' flexDirection='column' justifyContent='space-between' alignItems='left' p={2} >
                  <Box display='flex' gap={4} justifyContent='space-between'>
                    <Text size='md'><b>Name</b> : {item.name}</Text>
                    <Text size='md'><b>Category</b> : {item.category}</Text>
                  </Box>
                  <Box display='flex' mt={10} gap={4} justifyContent='space-between'>
                    <Text size='md'><b>Type</b> : {item.type}</Text>
                    <Text><b>Description</b> {item.description}</Text>
                  </Box>
                </Box>
                <Text color='blue.600' fontSize='2xl' display='flex' justifyContent='center' alignItems='center'>
                  <FaRupeeSign /> {item.rate}
                </Text>
              </Stack>
            </CardBody>
          </Card>
        ))}
      </SimpleGrid>
    </Box>
  )
}

export default FoodData;
