import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Text, Table, TableContainer, Thead, Tbody, Tr, Td, Th,useDisclosure } from "@chakra-ui/react";
import toast from 'react-hot-toast';
import AdvanceModal from './advanceRoomModal';

const AdvanceRoomBook = () => {
    const [data, setData] = useState([]);
    const [checkInDate, setCheckInDate] = useState('');
    const [checkOutDate, setCheckOutDate] = useState('');
    const [advance, setAdvanceData] = useState([]);
    const [roomNo, setRoomNo] = useState('');
    const [category, setCategory] = useState('');
    const [ac, setAc] = useState('');
    const [selectingCheckOut, setSelectingCheckOut] = useState(false);
    const customerId = localStorage.getItem('customerId');

    const fetchData = async () => {
        try {
            const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getRoomData/${customerId}`);
            setData(response.data);
        } catch (error) {
            toast.error(error.response?.data || "An error occurred");
        }
    };
    const { isOpen, onOpen, onClose } = useDisclosure({})

    const advanceData = async (rooms) => {
        try {
            const allAdvanceData = [];
            for (let room of rooms) {
                const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getRoomDataForNextMonth/${customerId}/${room}`);
                allAdvanceData.push({
                    roomNo: room,
                    category: data.filter(item => parseInt(item.roomNo) === room)[0].category,
                    ac: data.filter(item => parseInt(item.roomNo) === room)[0].ac,
                    availabilityStatus: response.data.availabilityStatus,
                });
            }
            setAdvanceData(allAdvanceData); // Set the accumulated data to the state
        } catch (error) {
            toast.error("Failed to fetch advance data");
        }
    };

    useEffect(() => {
        fetchData();
    }, [customerId]);

    useEffect(() => {
        if (data.length > 0) {
            const rooms = data.map((item) => parseInt(item.roomNo));
            advanceData(rooms);
        }
    }, [data]); // Run advanceData only when data changes

    const handleDateClick = (roomNo,available,category,ac ,date) => {
        setAc(ac);
        setCategory(category);
        setRoomNo(roomNo);
        if(!available){
            toast.error(`Room is not available for this ${date}`)
        }
        else{
        if (!selectingCheckOut) {
            setCheckInDate(date);
            setSelectingCheckOut(true);
            toast.success(`Check-in date set for Room ${roomNo}: ${date}`);
        } else {
            setCheckOutDate(date);
            setSelectingCheckOut(false);
            toast.success(`Check-out date set for Room ${roomNo}: ${date}`);
            onOpen()
        }
    }
    };

    console.log(advance,'ac');

    return (
        <Box overflowX='scroll' mt={10} p={4}>
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' mb={10}>
                <Text fontSize="2xl" fontWeight="bold" mb={4}>Next 30 Day's Advance Room Book</Text>
                <Box display='flex' flexDirection='row' gap={8}>
                    <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' gap='4px'>
                        <Text fontWeight='bold'>Booked</Text>
                        <Box bg='red' width='15px' height='15px' borderRadius='6px'></Box>
                    </Box>
                    <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' gap='4px'>
                        <Text fontWeight='bold'>Not Booked</Text>
                        <Box bg='green' width='15px' height='15px' borderRadius='6px'></Box>
                    </Box>
                </Box>
            </Box>
            <AdvanceModal isOpen={isOpen} onClose={onClose} checkInDate={checkInDate} checkOutDate={checkOutDate} roomNo={roomNo} category={category} ac={ac} customerId={customerId} />
            <TableContainer>
                <Table variant="simple">
                    <Thead bg='brown'>
                        <Tr>
                            <Th color='white' border='1px solid brown'>Room No</Th>
                            <Th color='white' border='1px solid brown'>Category</Th>
                            {advance.length > 0 && advance[0].availabilityStatus?.map((room, idx) => (
                                <Th color='white' border='1px solid brown' key={idx}>{room.date}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {advance.map((roomData, index) => (
                            <Tr key={index}>
                                <Td border='1px solid brown' fontWeight='bold'>{roomData.roomNo}</Td>
                                <Td border='1px solid brown' fontWeight='bold'>{roomData.category}</Td>
                                {roomData.availabilityStatus?.map((room, idx) => (
                                    <Td
                                        border='1px solid brown'
                                        key={idx}
                                        onClick={() => handleDateClick(roomData.roomNo,room.available,roomData.category,roomData.ac, room.date)}
                                        cursor="pointer"
                                        display='row'
                                        justifyContent='center'
                                        alignItems='center'
                                    >
                                        <Box bg={room.available ? room.checkInDate || room.checkOutDate?"blue": "green" : "red"} w='30px' height='30px' borderRadius='6px'></Box>
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default AdvanceRoomBook;
