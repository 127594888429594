import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Select,
  Box,
  Flex,Image,
  Text,Button,Card,CardBody,SimpleGrid,
  Textarea
} from "@chakra-ui/react";
import axios from 'axios';
import {useSelector} from 'react-redux'
import room1 from '../../assets/img/room-10.jpeg'
import room2 from '../../assets/img/room-9.jpeg'
import room3 from '../../assets/img/room-8.jpeg'
import room4 from '../../assets/img/room-7.jpeg'
import room5 from '../../assets/img/room-10.jpeg'
import room6 from '../../assets/img/room-9.jpeg'
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import React, { useState,useEffect } from "react";
import {s3Client} from '../../utils/s3client'
import { Upload } from "@aws-sdk/lib-storage";
import { PutObjectCommand } from "@aws-sdk/client-s3";

 
  
import toast from "react-hot-toast";
  const array = [
    { src: room1, label: 'Room 1' },
    { src: room2, label: 'Room 2' },
    { src: room3, label: 'Room 3' },
    { src: room4, label: 'Room 4' },
    { src: room5, label: 'Room 5' },
    { src: room6, label: 'Room 6' },
  ];
  
 
  

  const RoomRegister = () => {
    const customerId=localStorage.getItem('customerId')
  console.log(customerId);
  const [roomData,setRoomData]=useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const[imageURL,setImageURL]=useState(null);
  const [uploading, setUploading] = useState(false);
  const [printData,setPrintData]=useState([])
  const [images,setImages]=useState([])
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };


  // const handleUpload = async () => {
  //   if (!selectedFile) return;

  //   setUploading(true);
  //   const params = {
  //     Bucket: 'tdr-hotel',
  //     Key: selectedFile.name,
  //     Body: selectedFile,
  //     ContentType: selectedFile.type,
  //   };

  //   try {
  //     const upload = new Upload({
  //       client: s3Client,
  //       params: params,
  //     });

  //     upload.on("httpUploadProgress", (progress) => {
  //       console.log(progress);
  //     });

  //     await upload.done();
  //     console.log("Upload successful!");

  //     const response = await axios.post("https://api.hotel.tdrsoftware.in/get-signed-url", { key: selectedFile.name });
  //     const signedUrl = response.data.url;
      
  //     setImageURL(signedUrl);
  //   } catch (err) {
  //     console.error("Upload failed:", err);
  //   } finally {
  //     setUploading(false);
  //   }
  // };
  console.log(selectedFile?.name,'selectedFile')
  const fetchImages = async (key) => {
    setUploading(true);

    try {
      const response = await axios.get('https://api.hotel.tdrsoftware.in/image', {
        params: {
          customerId: customerId,
          key: key // Optional key parameter
        }
      });
      setImages(response.data);
    } catch (err) {
      console.log(err.message);
    } finally {
      setUploading(false);
    }
  };
  const fetchData3=async()=>{
    const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getPrintData/${customerId}`)
    setPrintData(response.data)
    const rooms = response.data;
    const updatedRooms = await Promise.all(
      rooms.map(async (room) => {
        const imageResponse = await axios.get('https://api.hotel.tdrsoftware.in/image', {
          params: { customerId, key: room.image }
        });
        room.imageUrl = imageResponse.data;
        return room;
      })
    );
  }
  const handlePost = async () => {
    try {
      setUploading(true);
      const formData = new FormData();
      formData.append('customerId', customerId);
      formData.append('image', selectedFile);  // Ensure the field name is 'image' here
  
      const response = await axios.post("https://api.hotel.tdrsoftware.in/images", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const data = response.data;
      setImageURL(Object.values(data).join());
      toast.success("Image uploaded successfully");
      console.log(data,'data')
    } catch (err) {
      toast.error(err);
    } finally {
      setUploading(false);
    }
    
  };
  console.log(typeof(imageURL),'imageURL')

   
    useEffect(()=>{
    
    // const fetchImages = async () => {
    //   setUploading(true);
      
    //   try {
    //     const response = await axios.get('https://api.hotel.tdrsoftware.in/images', {
    //       headers: {
    //         'Content-Type': 'application/json'
    //       },
    //       params: {
    //         customerId: customerId
    //       }
    //     });
    //     setImages(response.data);
    //     console.log(response.data,'images');
    //   } catch (err) {
    //     console.log(err.message);
    //   } finally {
    //     setUploading(false);
    //   }
    // };

    // fetchImages();
    fetchData3()
    },[customerId])
    let room=printData.map((item)=>item.roomType);
    const roomTypes = room[0]?.split(',')?.map(item => item.trim());
    // let rooms=room.map((item)=>item)
    // console.log(rooms)
    //console.log(roomData)
    const [dueAmount, setDueAmount] = useState(0); 
    const [formData, setFormData] = useState({
      roomNo: "",
      description: "",
      category:"",
     ac:"",
      rate: 0,
      sgst: 0,
      cgst: 0,
      image: "",
      pax:'',
      adult:'',
      child:''
      
      
    });
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
    const validateForm = () => {
      const requiredFields = [
        "roomNo",
        "description",
        "category",
       "ac",
        "rate",
       
        
        
      ];
  
      for (const field of requiredFields) {
        if (!formData[field]) {
          return false;
        }
      }
      return true;
    };
  
    const handleSubmit =async (e) => {
      e.preventDefault();
      if (!validateForm()) {
        toast.error("Please fill in all required fields.");
        return;
      }
      // Here you can send formData to your backend server or process it further
     
      // Reset form after submission
      
      setFormData({
        roomNo: formData.roomNo,
      description: formData.description,                        
      category:formData.category,
     ac:formData.ac,
      rate: formData.rate,
      sgst: formData.rate*0.06,
      cgst: formData.rate*0.06,
      image: imageURL,
      pax:formData.pax,
      adult:formData.adult,
      child:formData.child
      
      
       
      });
   
      
    };

  const handleImageChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0]
    });
  };
  const handleDueFocus = () => {
    setFormData({
      ...formData,
      child: formData.pax - formData.adult,
    });
  };

    const save=async(e)=>{
      if (!validateForm()) {
        toast.error("Please fill in all required fields.");
        return;
      }
      e.preventDefault()
       // Prepare form data for sending to backend
   
      try{
        const response=await axios.post("https://api.hotel.tdrsoftware.in/api/roomRegister",{
          customerId:customerId,data:formData
        });
        console.log('Data Submitted: ',response.data);
        toast.success(response.data.message);
        setFormData({
          roomNo: " ",
        description: "",                        
        category:"",
       ac:"",
        rate: "",
        sgst: "",
        cgst: "",
        image: "",
        adult:'',
        pax:'',
        child:' '
        
      })
         
    }catch(error){
      if (error.response && error.response.status === 400) {
        // Handle specific error case: Room booking already exists
        toast.error("Room registred with same room number.");
      } else {
        console.error("Error:", error);
        toast.error("Failed to bill room. Please try again later.");
      }
    }
  }
    // useEffect(() => {
    //   // Calculate due amount whenever relevant form fields change
    //   // const calculateDue = () => {
    //   //   const { totalAgreement, advance } = formData;
    //   //   const due = parseInt(totalAgreement) - parseInt(advance);
    //   //   setDueAmount(isNaN(due) ? 0 : due);
    //   // };
    //   // calculateDue();
    // }, [formData.totalAgreement, formData.advance]);
    
    // due=totalAgreement-advance
    return (
      <Box   bg='#EAE5DF '
      columns={{ sm: 2, md: 4 }}
      spacing='8'
      p='10'
      textAlign='center'
      
      color='black' width='100%' height='fit-content'   >
        
        <FormControl isRequired onSubmit={handleSubmit}>
          <Box display="grid"  gridGap={1} >
            <Flex
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
             
            >
              <Box width="40%"  display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              
              p={4}>
                <FormLabel  >Room No</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Enter room no"  name='roomNo' value={formData.roomNo} onChange={handleInputChange} />
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Description</FormLabel>
                <Textarea h='60%' w='60%'  bg='#FAFAFA ' ac="text" placeholder="Enter Description" name='description' value={formData.description} onChange={handleInputChange} />
              </Box>
            </Flex>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Category</FormLabel>
                <Select w='70%' bg='#FAFAFA ' placeholder="Enter Category" name='category' value={formData.category} onChange={handleInputChange}>
                {roomTypes?.map((item,index)=>(
                  <option key={index} value={item}>{item}</option>
                ))}
                 
                </Select>
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Ac/Non-Ac</FormLabel>
                <Select w='70%' bg='#FAFAFA ' placeholder="Ac/Non-Ac" name='ac' value={formData.ac} onChange={handleInputChange}>
                  <option>AC</option>
                  <option>Non-AC</option>
                  
                </Select>
              </Box>
            </Box>
            {/* <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>PAX</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Enter Capacity" name='pax' value={formData.pax} onChange={handleInputChange}/>
                  
                 
                
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Adult</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Adult Capacity" name='adult' value={formData.adult} onChange={handleInputChange}/>
                  
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Child</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Child Capacity" name='child' value={formData.child} onFocus={handleDueFocus} onChange={handleInputChange}/> 
                  
                  
               
              </Box>
            </Box> */}
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Rate</FormLabel>
                <Input w='80%' bg='#FAFAFA ' placeholder="Enter Room Rate" name='rate' value={formData.rate} onChange={handleInputChange}/>
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>SGST</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Enter SGST" name='sgst' value={formData.sgst} onChange={handleInputChange} />
              </Box>
            </Box>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>CGST</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Enter SGST" name='cgst' value={formData.cgst} onChange={handleInputChange} />
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Image</FormLabel>
                <Input w='70%' type="file" bg='#FAFAFA ' onChange={handleFileChange} />
                <Button variant='solid' onClick={handlePost} disabled={uploading}>
        {uploading ? "Uploading..." : "Upload"}
      </Button>
     
                
              </Box>
              
             
            </Box>
            
            
           
              
             
           
           
           
            
            
            
          <hr/>
          <Box display='flex' flexDirection='column' rowGap='10px' mt={4}>
         
              </Box>

          </Box>
         
          <Button ac="submit" variant='solid' mr={4} color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={handleSubmit}>Generate Gst</Button>
          <Button ac="submit" variant='solid' color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={save}>Save</Button>
        </FormControl>
        
      </Box>
    );

  

  }





export default RoomRegister