
import React,{useState,useEffect} from 'react';
import Chart from 'react-apexcharts'
import axios from 'axios'

const  Donut =()=> {
  const [roomBill,setRoomBill]=useState([]);
  const [foodBill,setFoodBill]=useState([]);
  const [customerBill,setCustomerBill]=useState([]);
  const customerId=localStorage.getItem('customerId')
  const roomBillData = async () => {
    const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getRoomBilldata/${customerId}`);
    setRoomBill(response.data);
  };
  const foodBillData = async () => {
    const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getFoodBilldata/${customerId}`);
    setFoodBill(response.data);
  };
  //getCustomerdata
  const customerBillData = async () => {
    const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getCustomerdata/${customerId}`);
    setCustomerBill(response.data);
  };
  let totalRoomBill=roomBill.reduce((a,b)=>
  Number(a)+Number(b.total),0);
  console.log(totalRoomBill+"room")
  let totalFoodBill=foodBill.reduce((a,b)=>
    Number(a)+Number(b.totalBilling),0);
    console.log(totalFoodBill+"food")

    let totalCustomerBill=customerBill.reduce((a,b)=>
      Number(a)+Number(b.total),0);
      console.log(totalCustomerBill+"customer")

  useEffect(()=>{
roomBillData()
foodBillData()
customerBillData()
  },[customerId])

 const options={
      series: [totalCustomerBill, totalRoomBill, totalFoodBill],
      labels: ['CustomerBill', 'RoomBill', 'FoodBill']
    }
  

  return (

    
      <div className="donut">
        <Chart options={options} series={options.series} labels={options.labels} type="donut" width="380" height={250} />
      </div>
    
  )
}

export default Donut;