import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Input, Table, TableCaption, Thead, Tbody, Tr, Th, Td, TableContainer } from "@chakra-ui/react";

const KotBillData = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const customerId = localStorage.getItem('customerId');

  const fetchData = async () => {
    const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getKotbilldata/${customerId}`);
    setData(response.data);
    setFilteredData(response.data);
  };

  useEffect(() => {
    fetchData();
  }, [customerId]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterData(event.target.value, startDate, endDate);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    filterData(searchTerm, event.target.value, endDate);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
    filterData(searchTerm, startDate, event.target.value);
  };

  const filterData = (term, start, end) => {
    let filtered = data.filter(order => {
      const orderDate = new Date(order.createdAt).getTime();
      const startFilter = start ? new Date(start).getTime() : null;
      const endFilter = end ? new Date(end).getTime() : null;

      if (term && !order.roomNo.includes(term)) {
        return false;
      }

      if (startFilter && endFilter) {
        return orderDate >= startFilter && orderDate <= endFilter;
      }

      if (startFilter) {
        return orderDate >= startFilter;
      }

      if (endFilter) {
        return orderDate <= endFilter;
      }

      return true;
    });

    setFilteredData(filtered);
  };

  return (
    <Box>
      <Input
        w='40%'
        type="text"
        placeholder="Search by Room Number"
        value={searchTerm}
        onChange={handleSearchChange}
        mb={4} // Adds margin bottom for spacing
      />
      <Input
        w='20%'
        type="date"
        placeholder="Start Date"
        value={startDate}
        onChange={handleStartDateChange}
        mr={2}
      />
      <Input
        w='20%'
        type="date"
        placeholder="End Date"
        value={endDate}
        onChange={handleEndDateChange}
        mr={2}
      />
      <TableContainer width='full'>
        <Table size='sm' variant='striped' colorScheme='green'>
          <TableCaption>Food Data</TableCaption>
          <Thead>
            <Tr>
              <Th>Food Item</Th>
              <Th>RoomNo</Th>
              <Th>KotNo</Th>
              <Th>Total Bill</Th>
              <Th>Created At</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.map((order, index) => (
              <Tr key={index}>
                <Td>{order.foodItem.map(item => item.name).join(', ')}</Td>
                <Td>{order.roomNo}</Td>
                <Td>{order.kotNo}</Td>
                <Td>{order.totalBilling}</Td>
                <Td>{new Date(order.createdAt).toLocaleString('en-GB')}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default KotBillData;
