import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Box,
  VStack,
  Button,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Link as ChakraLink,
} from "@chakra-ui/react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import BookingInfoModal from "./bookingInfoModal";
import UpdateModal from "./updateGuestDetails";
import CustomerStatusModal from "./customerStatus";

const RoomStatus = () => {
  const [data, setData] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const customerId = localStorage.getItem("customerId");
  const [filter, setFilter] = useState({
    category: "",
    ac: "",
    availability: "",
    clean: "",
  });
  const [booking, setBookingData] = useState({});
  const [toggle, setToggle] = useState(false);
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: async () => {
      try {
        const response = await axios.get(
          `https://api.hotel.tdrsoftware.in/api/roomBookByDate/${customerId}/${selectedRoom.room.roomNo}`
        );
        setBookingData(response.data.bookings);
      } catch (error) {
        toast.error("Failed to fetch booking data");
      }
    },
  });
  const handleCheckIn = async (roomNo) => {
    try {
      const response = await axios.put(
        `https://api.hotel.tdrsoftware.in/api/updateRoomAvailability/${customerId}/${roomNo}`
      );
      toast.success(response.data.message);
      //setToggle(!toggle);
    } catch (error) {
      toast.error(error.message);
    }
  };
  //console.log(selectedRoom.room.roomNo,'roomNo')
  const cleanRoom=async(roomNo)=>{
    try {
      const response=await axios.put(`https://api.hotel.tdrsoftware.in/api/updateRoomClean/${customerId}/${roomNo}`)
      toast.success(response.data.message)
    } catch (error) {
      toast.error(error.message)
    }
    

  }

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api.hotel.tdrsoftware.in/api/currentGuestsInAllRooms/${customerId}`
      );
      const rooms = response.data;

      // const updatedRooms = await Promise.all(
      //   rooms.map(async (room) => {
      //     const bookingData = await fetchRoomBookingData(room.roomNo);

      //     return { ...room, bookingData }; // Add booking data to the room
      //   })
      // );

      setData(rooms);
    } catch (error) {
      toast.error("Failed to fetch room data");
    }
  };
  console.log(data, "data");
  // const fetchRoomBookingData = async (roomNo) => {
  //   try {
  //     const response = await axios.get(
  //       `https://api.hotel.tdrsoftware.in/api/roomBookByDate/${customerId}/${roomNo}`
  //     );

  //     return response.data.bookings; // Return the booking data for the room
  //   } catch (error) {
  //     //   toast.error(`Failed to fetch booking data for room ${roomNo}`);
  //     return null;
  //   }
  // };
  const fetchRoomBookByRoomNo = async (roomNo) => {
    try {
      const response = await axios.get(
        `https://api.hotel.tdrsoftware.in/api/roomBookByDate/${customerId}/${roomNo}`
      );
      setBookingData(response.data.bookings);
      setToggle(!toggle);
    } catch (error) {
      toast.error(error.message);
    }
    setToggle(!toggle);
    // navigate('/booking-info')
  };
  const handleUpdate = () => {
    setToggle1(!toggle1);
  };
  const handleCustomer = () => {
    setToggle2(!toggle2);
  };
  console.log(booking, "bookingData");

  const navigate = useNavigate();

  const handleRoomAvailability = async (id) => {
    try {
      const response = await axios.put(
        `https://api.hotel.tdrsoftware.in/api/updateAvailability/${customerId}/${id}`
      );
      alert(response.data.message);
      window.location.reload("/roomData");
    } catch (error) {
      toast.error(error);
    }
  };
  console.log(selectedRoom);

  useEffect(() => {
    fetchData();
  }, [customerId]);

  const filteredData = data.filter((item) => {
    return (
      (filter.category === "" || item.room.category === filter.category) &&
      (filter.ac === "" || item.room.ac === filter.ac) &&
      (filter.availability === "" ||
        item.room.availability === filter.availability) &&
        (filter.clean === "" ||
          item.room.clean === filter.clean) 
        
    );
  });

  const handleCategoryFilter = (category) => {
    setFilter({ ...filter, category });
  };
  const handleAvailabilityFilter = (availability) => {
    setFilter({ ...filter, availability });
  };
  const handleAcFilter = (ac) => {
    setFilter({ ...filter, ac });
  };
  const handleCleanFilter = (clean) => {
    setFilter({ ...filter, clean });
  };

  const handleRoomClick = async (room) => {
    setSelectedRoom(room);

    // Fetch booking data as soon as the room is selected
    try {
      const response = await axios.get(
        `https://api.hotel.tdrsoftware.in/api/roomBookByDate/${customerId}/${room.roomNo}`
      );
      setBookingData(response.data.bookings);
    } catch (error) {
      toast.error("Failed to fetch booking data");
    }

    onOpen();
  };
  console.log(booking, "selectReg");

  return (
    <Box p={10}>
      <Box
        mb={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <Button
          fontSize="sm"
          colorScheme={filter.availability === "" ? "blue" : "gray"}
          onClick={() => handleAvailabilityFilter("")}
        >
          All
        </Button>
        <Button
          fontSize="sm"
          colorScheme="red"
          onClick={() => handleAvailabilityFilter(false)}
        >
          Booked
        </Button>
        <Button
          fontSize="sm"
          colorScheme="green"
          onClick={() => handleAvailabilityFilter(true)}
        >
          Not Booked
        </Button>
        <Button
          fontSize="sm"
          colorScheme="yellow"
          onClick={() => handleCleanFilter(false)}
        >
          Dirty
        </Button>
      </Box>
      <SimpleGrid columns={4} spacing={4} mb={4}>
        {/* Category Filters */}
        <Button
          fontSize="sm"
          colorScheme={filter.category === "" ? "blue" : "gray"}
          onClick={() => handleCategoryFilter("")}
        >
          All Categories
        </Button>
        {[...new Set(data.map((item) => item.room.category))].map(
          (category) => (
            <Button
              fontSize="sm"
              key={category}
              colorScheme={filter.category === category ? "blue" : "gray"}
              onClick={() => handleCategoryFilter(category)}
            >
              {category}
            </Button>
          )
        )}
      </SimpleGrid>
      <SimpleGrid columns={3} spacing={4} mb={4}>
        {/* AC Filters */}
        <Button
          fontSize="sm"
          colorScheme={filter.ac === "" ? "green" : "gray"}
          onClick={() => handleAcFilter("")}
        >
          All AC/Non-AC
        </Button>
        <Button
          fontSize="sm"
          colorScheme={filter.ac === "AC" ? "green" : "gray"}
          onClick={() => handleAcFilter("AC")}
        >
          AC
        </Button>
        <Button
          fontSize="sm"
          colorScheme={filter.ac === "Non-AC" ? "green" : "gray"}
          onClick={() => handleAcFilter("Non-AC")}
        >
          Non-AC
        </Button>
      </SimpleGrid>
      {toggle ? (
        <BookingInfoModal
          isOpen={isOpen}
          onClose={onClose}
          bookingData={booking}
          toggle={toggle}
          setBookingData={setBookingData}
          setToggle={setToggle}
        />
      ) : (
        ""
      )}
      {toggle1 ? (
        <UpdateModal
          isOpen={isOpen}
          onClose={onClose}
          toggle1={toggle1}
          setToggle1={setToggle1}
          registration={booking[0].registration}
          id={booking[0]._id}
          customerId={customerId}
        />
      ) : (
        ""
      )}
      {toggle2 ? (
        <CustomerStatusModal
          isOpen={isOpen}
          onClose={onClose}
          toggle2={toggle2}
          setToggle2={setToggle2}
          registration={booking[0].registration}
          id={booking[0]._id}
          customerId={customerId}
        />
      ) : (
        ""
      )}
      <SimpleGrid
        columns={4}
        placeItems="center"
        height="fit-content"
        gap="8px"
        mt={10}
      >
        {filteredData.map((item, index) => (
          <Box
            display="flex"
            flexDirection="column"
            p={10}
            key={index}
            bg={
              item.room.availability
                ? item.room.clean
                  ? "green"
                  : "#CD7F32"
                : "red"
            }
            width="full"
            color="white"
            borderRadius="6px"
            cursor="pointer"
            onClick={() => handleRoomClick(item)}
          >
            <Box>RoomNo--{item.room.roomNo}</Box>
            <Box>
              Name--
              {Array.isArray(item.currentGuests)
                ? item.currentGuests.map((item) => item.name)
                : ""}
            </Box>
            <Box>Category--{item.room.category}</Box>
            <Box>Rate--{item.room.rate}</Box>
          </Box>
        ))}
      </SimpleGrid>

      {/* Modal for Room Details */}
      {selectedRoom && (
        <Modal isOpen={isOpen} onClose={onClose} width="full">
          <ModalOverlay backdropFilter="blur(2px)" />
          <ModalContent bg="#ffa723" color="#0F172B">
            <ModalHeader>Room Services-{selectedRoom.room.roomNo}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <SimpleGrid columns={2} placeItems="center" p={10} gap={5}>
                <Button
                  variant="ghost"
                  color="#ffa723"
                  bg="#0F172B"
                  _hover={{
                    bg: "#ffa723",
                    color: "#0F172B",
                    border: "1px solid #0F172B",
                  }}
                  onClick={() =>
                    fetchRoomBookByRoomNo(selectedRoom.room.roomNo)
                  }
                >
                  Booking Info
                </Button>
                <Button
                  variant="ghost"
                  color="#ffa723"
                  bg="#0F172B"
                  _hover={{
                    bg: "#ffa723",
                    color: "#0F172B",
                    border: "1px solid #0F172B",
                  }}
                  onClick={handleUpdate}
                >
                  Change Guest Details
                </Button>
                <Button
                  variant="ghost"
                  color={!selectedRoom.room.roomNo.availability ? "white" : "#ffa723"}
                  _hover={{
                    bg: "#ffa723",
                    color: "#0F172B",
                    border: "1px solid #0F172B",
                  }}
                  disabled={!selectedRoom.availability}
                  bg={!selectedRoom.room.roomNo.availability ? "red" : "#0F172B"}
                  onClick={()=>{
                    handleCheckIn(selectedRoom.room.roomNo)
                  }
                  }
                >
                  CheckIn
                </Button>
                <Button
                  variant="ghost"
                  color={selectedRoom.availability ? "white" : "#ffa723"}
                  _hover={{
                    bg: "#ffa723",
                    color: "#0F172B",
                    border: "1px solid #0F172B",
                  }}
                  disabled={selectedRoom.availability}
                  bg={selectedRoom.availability ? "red" : "#0F172B"}
                >
                  CheckOut
                </Button>
                <Button
                  variant="ghost"
                  color="#ffa723"
                  bg="#0F172B"
                  onClick={() => {
                    navigate("/foodBill", {
                      state: {
                        roomNo: selectedRoom.room.roomNo,
                        registration: booking[0].registration,
                      },
                    });
                  }}
                  _hover={{
                    bg: "#ffa723",
                    color: "#0F172B",
                    border: "1px solid #0F172B",
                  }}
                >
                  Restaurant
                </Button>
                <Button
                  variant="ghost"
                  color="#ffa723"
                  bg="#0F172B"
                  _hover={{
                    bg: "#ffa723",
                    color: "#0F172B",
                    border: "1px solid #0F172B",
                  }}
                  onClick={() => {
                    navigate("/cashRecipt", {
                      state: {
                        roomNo: selectedRoom.room.roomNo,
                        registration: booking[0].registration,
                      },
                    });
                  }}
                >
                  Payment
                </Button>
                <Button
                  variant="ghost"
                  color="#ffa723"
                  bg="#0F172B"
                  _hover={{
                    bg: "#ffa723",
                    color: "#0F172B",
                    border: "1px solid #0F172B",
                  }}
                  onClick={() => {
                    navigate("/roomBilling", {
                      state: {
                        roomNo: selectedRoom.room.roomNo,
                        registration: booking[0].registration,
                      },
                    });
                  }}
                >
                  Room Bill
                </Button>
                <Button
                  variant="ghost"
                  color="#ffa723"
                  bg="#0F172B"
                  _hover={{
                    bg: "#ffa723",
                    color: "#0F172B",
                    border: "1px solid #0F172B",
                  }}
                  onClick={() => {
                    navigate("/getMergeBill", {
                      state: {
                        roomNo: selectedRoom.room.roomNo,
                        registration: booking[0].registration,
                      },
                    });
                  }}
                >
                  MergeBill
                </Button>
                <Button
                  variant="ghost"
                  color="#ffa723"
                  bg="#0F172B"
                  _hover={{
                    bg: "#ffa723",
                    color: "#0F172B",
                    border: "1px solid #0F172B",
                  }}
                  onClick={handleCustomer}
                >
                  Customer Status
                </Button>
                <Button
                  variant="ghost"
                  color="#ffa723"
                  bg="#0F172B"
                  _hover={{
                    bg: "#ffa723",
                    color: "#0F172B",
                    border: "1px solid #0F172B",
                  }}
                  onClick={()=>cleanRoom(selectedRoom.room.roomNo)}
                >
                  {selectedRoom.room.clean ? "Clean" : "Dirty"}
                </Button>
              </SimpleGrid>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="red" mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default RoomStatus;
