import { createSlice } from "@reduxjs/toolkit";
const initialState = [];
const foodReducer = createSlice({
  name: "food",
  initialState,
  reducers: {
    addFood: (state, action) => {
      return  [...state, action.payload];
    },
    deleteFood: (state, action) => {
      return state.filter((food) => food.id !== action.payload);
    },
    updateFood: (state, action) => {
      return state.map((todo) => {
        const item = todo.item;
        if (todo.id === action.payload.id) {
          return {
            ...todo,
            [item]: action.payload.text,
          };
        }
        return todo;
      });
    },
  },
});
export const { addFood, deleteFood, updateFood } = foodReducer.actions;
export default foodReducer.reducer;
