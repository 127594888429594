import { useState,useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addFood } from '../../redux/reducers/foodItemReducer';
import { Box, Input, Select } from '@chakra-ui/react';
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from 'react-redux';
import axios from 'axios'


function TextField() {
  const dispatch = useDispatch();
  const [text, setText] = useState('');
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  const [total, setTotal] = useState(0);
  const [data,setData]=useState([])
  const customerId=localStorage.getItem('customerId')
  const fetchData=async()=>{
    const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getFooddata/${customerId}`)
    setData(response.data)
}
   useEffect(()=>{
    fetchData()
   },[customerId])

  // const food = [
  //   {
  //   name:"Fried Rice",
  //   price:150
  //   },
  //   {
  //     name: "Biriyani",
  //     price: 100,
  //   },
  //   {
  //     name: 'Chicken Curry',
  //     price: 150,
  //   },
  //   {
  //     name: 'Polao',
  //     price: 120,
  //   },
  //   {
  //     name: 'Veg Biryani',
  //     price: 180,
  //   }
  // ];
  console.log(data)

  const handleFoodChange = (e) => {
    const selectedFood = data.find(item => item.name === e.target.value);
    if (selectedFood) {
      setText(selectedFood.name);
      setPrice(selectedFood.rate);
    }
  };
console.log(data+'data');
  const add = () => {
    if (text === '') {
      alert('Input must not be empty');
    } else {
      dispatch(
        addFood({
          id: Math.floor(Math.random() * 100),
          item: text,
          price: price,
          quantity: quantity,
          total: price * quantity,
        })
      );
      setText('');
      setPrice(0);
      setQuantity(0);
      setTotal(0);
    }
  };

  return (
    <Box className='textField' display='flex' alignItems='center'>
      <Select bg='#FAFAFA '
        onChange={handleFoodChange}
        placeholder='Enter Food Item'
        value={text}
        mr={2}
      >
        {data.map((item, index) => (
          <option key={index} value={item.name}>{item.name}</option>
        ))}
      </Select>
      <Input bg='#FAFAFA '
        onChange={(e) => setQuantity(e.target.value)}
        type='number'
        placeholder='Enter Quantity'
        value={quantity}
        mr={2}
      />
      <Input bg='#FAFAFA '
        onChange={(e) => setPrice(Number(e.target.value))}
        type='number'
        placeholder='Enter Price'
        value={price}
        mr={2}
      />
      <IoIosAddCircleOutline onClick={add} fontSize='6rem' cursor='pointer' color='green' />
    </Box>
  );
}

export default TextField;
