import React, { useEffect, useState } from 'react';
import { Box, VStack, Button, SimpleGrid, Stack, Text, Card, CardBody, Image, Input,CardHeader,CardFooter,Select,Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,Flex, 
  GridItem,Grid,
  OrderedList,ListItem} from "@chakra-ui/react";
  import { MdArrowDropDown } from "react-icons/md";
import { FaRupeeSign, FaTrash } from "react-icons/fa";
import axios from 'axios';
import image from '../../assets/img/carousel-1.jpg'
import image1 from '../../assets/img/carousel-1.jpg'
import { useReactToPrint } from "react-to-print";
import { useRef } from 'react';

import { useBillItems } from '../context/BillItemsContext';
import toast from 'react-hot-toast';
import UseKotPrint from './kot';
import { numberToWords } from '../../utils/numberToWords';
import { useLocation } from 'react-router-dom';

const RestaurentData = () => {
  const location = useLocation();
  const { roomNo, registration } = location.state || {};
  console.log(registration,roomNo,'a')
  const { billItems, addToBill, updateQuantity, deleteItem, clearBill } = useBillItems();
  // Other state variables and functions remain the same
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [select,setSelect]=useState('gst')
  const [typeFilter, setTypeFilter] = useState('All');
  const [nameFilter, setNameFilter] = useState('All');
  const [kotNumber,setKotNumber]=useState('');
  const [uniqueNames, setUniqueNames] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [printData,setPrintData]=useState([])
  const [quickBill,setQuickBill]=useState({
    foodItem:[],
    phoneNo:'',
    name:'',
    foodRate:0,
    totalBilling:0
  });
  const handleQuickDataChange = (e) => {
    const { name, value } = e.target;
    setQuickBill({
      ...quickBill,
      [name]: value,
    });
  };
   // Initialize useHistory hook
 // const [billItems, setBillItems] = useState([]);
  
  
  const [kot,setKot]=useState('Kot');
  const [kotData, setKotData] = useState({
    foodItem: [],
    roomNo:roomNo ? roomNo :'',
    kotNo:'',
    foodRate: 0,
    
    totalBilling: 0,
    
  });
  

  const [formData, setFormData] = useState({
    foodItem: [],
    registration:registration ? registration :'',
    roomNo:roomNo ? roomNo :'',
    foodRate: 0,
    sgst: 0,
    cgst: 0,
   
    totalBilling: 0,
    
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleKotDataChange = (e) => {
    const { name, value } = e.target;
    setKotData({
      ...kotData,
      [name]: value,
    });
  };
  const listRef = useRef(null); // Ref for the <OrderedList>
  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown' && filteredData.length > 0) {
      e.preventDefault();
      const nextIndex = (focusedIndex + 1) % filteredData.length;
      setFocusedIndex(nextIndex);
      scrollIntoView(nextIndex);
    }
    else if (e.key === 'ArrowUp' && filteredData.length > 0) {
      e.preventDefault();
      const nextIndex = (focusedIndex - 1) % filteredData.length;
      setFocusedIndex(nextIndex);
      scrollIntoView(nextIndex);
    }else if (e.key === 'Enter' && focusedIndex !== -1) {
      e.preventDefault();
      handleAddToBill(filteredData[focusedIndex]);
    }
   
    
  };

  const scrollIntoView = (index) => {
    if (listRef.current && listRef.current.childNodes[index]) {
      listRef.current.childNodes[index].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  };
  const handleSelect=(e)=>{
   setSelect(e.target.value)
  }

  const componentRef = useRef();
const handlePrint = useReactToPrint({
  content: () => componentRef.current,
});

  const customerId = localStorage.getItem('customerId');
  console.log(customerId);

  const fetchData = async () => {
    const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getFooddata/${customerId}`);
    const rooms = response.data;
    const updatedRooms = await Promise.all(
      rooms.map(async (room) => {
        const imageResponse = await axios.get('https://api.hotel.tdrsoftware.in/image', {
          params: { customerId, key: room.image }
        });
        room.imageUrl = imageResponse.data;
        return room;
      })
    );
    console.log(updatedRooms, 'updatedRooms');
    setData(updatedRooms);
    setFilteredData(updatedRooms);
    setUniqueNames(['All', ...new Set(updatedRooms.map(item => item.name))]);
  }
  const fetchData3=async()=>{
    const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getPrintData/${customerId}`)
    setPrintData(response.data)
    const rooms = response.data;
    const updatedRooms = await Promise.all(
      rooms.map(async (room) => {
        const imageResponse = await axios.get('https://api.hotel.tdrsoftware.in/image', {
          params: { customerId, key: room.image }
        });
        room.imageUrl = imageResponse.data;
        return room;
      })
    );
    console.log(updatedRooms, 'updatedRooms');
    setPrintData(updatedRooms);
}
  const handleTypeFilterChange = (filter) => {
    setTypeFilter(filter);
    filterData(filter, nameFilter);
  }

  const handleNameFilterChange = (filter) => {
    setNameFilter(filter);
    filterData(typeFilter, filter);
  }
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterData1(event.target.value);
  };

  const filterData = (type, name) => {
    let filtered = data;
    if (type !== 'All') {
      filtered = filtered.filter(item => item.type === type);
    }
    if (name !== 'All') {
      filtered = filtered.filter(item => item.name === name);
    }
    setFilteredData(filtered);
  }
  const filterData1 = (term) => {
    if (!term) {
      setFilteredData(data); // Reset filteredData to all data if search term is empty
    } else {
      const lowerCaseTerm = term.toLowerCase();
      const filtered = data.filter(item =>
        item.name.toLowerCase().includes(term) || item.code.includes(term)
      );
      setFilteredData(filtered);
    }
  };


  // Load billItems from localStorage on component mount
  useEffect(() => {
    fetchData()
    fetchData3()
    
  }, []);

  // Save billItems to localStorage whenever it changes
  

  // Save billItems to localStorage whenever it changes
  
 

  const handleAddToBill = (item) => {
    // Check if the item already exists in the bill
   addToBill(item)
  };

  const handleQuantityChange = (index, quantity) => {
    updateQuantity(index,quantity)
  }

  const handleDeleteItem = (index) => {
   deleteItem(index)
  }
  
  const totalAmount = billItems.reduce((acc, item) => acc + item.price, 0);
  let totalSum;

  let arr1 = billItems.map(item => {
    return {
      name: item.name,
      quantity: item.quantity,
      price: item.price
    };
  });
  
  const handleKotChange=(e)=>{
    setKot(e.target.value)
  }
  let rcgst;
  let rsgst;
  if(select == 'gst'){ 
    rcgst=totalAmount*0.025;
    
    rsgst=totalAmount*0.025;
    totalSum=totalAmount+rcgst+rsgst
  }else{
    rcgst=0;
    
    rsgst=0;
    totalSum=totalAmount+rcgst+rsgst
  }
  const kotPrint = UseKotPrint(arr1, kotData.roomNo, totalAmount); 
 
  const handleSaveAndPrint = async () => {
    // Prepare data to send to backend
 
   

    console.log(billItems)
    
      setFormData({
      foodItem: arr1,
      roomNo:kotData.roomNo,
      foodRate: totalAmount,
      cgst:rcgst,
      sgst:rsgst,
      registration: registration ? registration : formData.registration,
      totalBilling: totalSum,
  })
   // Update submitted data here
   // setFormData(updatedData)
  
  
    
    console.log(formData)
   
  }
  const quickSave=(e)=>{
    setQuickBill({
      foodItem:arr1,
      name:quickBill.name,
      phoneNo:quickBill.phoneNo,
      foodRate:totalAmount,
      cgst:rcgst,
      sgst:rsgst,
      totalBilling:totalSum
    })

  }
  console.log(quickBill,'quickBill')
  
  console.log(formData,'formData')
  const save=async()=>{
    try {
      // Assuming you have a backend endpoint to send data to
      const response = await axios.post('https://api.hotel.tdrsoftware.in/api/foodBillRegister', {
        customerId:customerId,data:formData}
      );
      console.log('Bill saved:', response.data);
      toast.success(response.data.message)
  
    //  Optionally, you can reset the form data or perform any other actions upon successful save
      setFormData({
        foodItem: [],
        registration:'',
        foodRate: 0,
        roomNo:'',
        
       
        totalBilling: 0,
      });
      //setBillItems([]);
      clearBill()
  
      // Add any further actions upon successful save here
  
    } catch (error) {
      console.error('Error saving bill:', error);
      toast.error("Error in food billing")
      // Handle error scenarios
    }
  }
  
  const qSave=async()=>{
    try {
      // Assuming you have a backend endpoint to send data to
      const response = await axios.post('https://api.hotel.tdrsoftware.in/api/quickBillRegister', {
        customerId:customerId,data:quickBill}
      );
      console.log('Bill saved:', response.data);
      toast.success(response.data.message)
  
    //  Optionally, you can reset the form data or perform any other actions upon successful save
     setQuickBill({
      foodItem:[],
      name:'',
      phoneNo:'',
      foodRate:0,
      totalBilling:0
     })
      //setBillItems([]);
      clearBill()
  
      // Add any further actions upon successful save here
  
    } catch (error) {
      console.error('Error saving bill:', error);
      toast.error("Error in quick billing")
      // Handle error scenarios
    }
  }
  let arr=Object.entries(formData)
 console.log(filteredData,'filteredData')

  return (

    <Box display='flex' flexDirection='column'>
      <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'  >
      
      <Input w='40%'
        type="text"
        variant='ghost'
        bg='#F8FAF1 '
        mt={5}
       
        border='1px solid #E8E8E6'
        placeholder="Search by Name  or Code"
        value={searchTerm}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown} 
        
         // Adds margin bottom for spacing
      />
      
        
      <OrderedList  width='60%'  display='flex' flexDirection='column' justifyContent='center' alignItems='center'  height='fit-content' ref={listRef} tabIndex={-1}    >
        
      {searchTerm !== "" ? filteredData.map((item,index)=>(
           <ListItem width='69%' as='i' bg={index === focusedIndex ? 'red' : 'transparent'} 
           color={index === focusedIndex ? 'white' : 'black'}  p={2} mb={1} borderRadius='6px' key={item._id} onClick={() => handleAddToBill(item)} >{item.name}</ListItem>
      )) : (<></>)}
      </OrderedList>
      
    
      </Box>
    <Box display='flex' flexDirection='row' p={10} >
      <Box>
        <Box display="flex" flexDirection='column' justifyContent="center" marginBottom="20px" gap="10px" >
          <Button onClick={() => handleTypeFilterChange('All')} colorScheme={typeFilter === 'All' ? 'green' : 'gray'}>All</Button>
          <Button onClick={() => handleTypeFilterChange('Veg')} colorScheme={typeFilter === 'Veg' ? 'green' : 'gray'}>Veg</Button>
          <Button onClick={() => handleTypeFilterChange('Non-Veg')} colorScheme={typeFilter === 'Non-Veg' ? 'green' : 'gray'}>Non-Veg</Button>
        </Box>
        <Grid Grid templateColumns='repeat(1, 1fr)' marginBottom="20px" gap="10px">
          {uniqueNames.map((name, index) => (
            <GridItem  p={1} cursor='pointer' borderRadius='6px' key={index} onClick={() => handleNameFilterChange(name)} as='i' bg={nameFilter === name ?  '#389C2B' : '#E2ECE1'} color={nameFilter === name ? 'white' : 'black'}>
              {name}
            </GridItem>
          ))}
        </Grid>
      </Box>
      <Box display='flex' width='60%'>
        <SimpleGrid minChildWidth='170px' placeItems='center' height='fit-content' gap='10px'  p={2} width='full'>
          {filteredData.map((item, index) => (
            <Card boxShadow='dark-lg' maxW='sm' key={index} height='180px'  onClick={() => handleAddToBill(item)}>
             <Image
                  src={item.imageUrl}
                  alt={item.roomNo}
                  borderRadius='lg'
                  height='full'
                  width='170px'
                />
                <Text bg='red' p={0.5} opacity={0.8} textAlign='center' position='absolute' top='10%' left='73%' transform='translate(-50%, -50%)' color='white' fontSize='sm' as='b'>Code:{item.code}</Text>
              <CardBody textAlign='center' position='absolute' top='50%' left='50%' transform='translate(-50%, -50%)' bg='red' opacity='0.8' width='full' p={2}>
               <Box display='flex' flexDirection='row' justifyContent='space-between'>
                <Text fontSize='sm' as='b' color='white'>{item.name}</Text>
                <Text fontSize='sm' as='b' color='white' display='flex' alignItems='center'><FaRupeeSign/>{item.rate}</Text>
                </Box>
                
              </CardBody>
            </Card>
          ))}
        </SimpleGrid>
      </Box>
      <Box display='flex' bg='#F8FAF1 ' borderRadius='6px' flexDirection='column' width='38%' height='fit-content' boxShadow='dark-lg' >
        
        <Box>
        <Flex flexDirection="row" alignItems="center" gap={4} >  <Select bg='#FAFAFA' p={4} icon={<MdArrowDropDown />} variant='flushed' value={kot} name='kot' onChange={handleKotChange}>
          <option bg='#FAFAFA' p={2} value={'Kot'} name='kot' onChange={handleKotChange}>Order/KOT</option>
          <option bg='#FAFAFA' p={2} value={'bill'}>Bill</option>
          <option bg='#FAFAFA' p={2} value={'quick'}>Quick Bill</option>
          </Select>
          </Flex></Box>
          {kot === 'Kot' ?
          <>
          <Box bg='#F74C31' height='100px' display='flex' p={10} borderRadius='4px' justifyContent='space-between' overflowY='hidden' color='white' alignItems='center'>
          <Text fontSize='small'>KOT No-</Text>
          
         <Input width='150px' bg='white' placeholder='Enter roomNo' value={kotData.roomNo} color='black' name='roomNo' onChange={handleKotDataChange}/>
        </Box>
        <Box display='flex' flexDirection='column' p={2} justifyContent='space-evenly' minHeight='250px' overflowY='scroll'>
          {billItems.map((item, index) => (
            <Box key={index} display='flex' justifyContent='space-between' alignItems='center' p={2}>
              <Text fontSize='sm'  name='name'>{item.name}</Text>
              <Input
                type='number'
                value={item.quantity}
                onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                placeholder='Quantity'
                width='50px'
                
              />
             
              <Text display='flex' alignItems='center' fontSize='sm'><FaRupeeSign /> {item.price}</Text>
              <Button  colorScheme='red' onClick={() => handleDeleteItem(index)}><FaTrash /></Button>
            </Box>
          ))}
          </Box>
          <Box bg='#F74C31' height='100px' display='flex' flexDirection='row' p={5} borderRadius='4px' justifyContent='center' gap={4} overflowY='hidden' color='white' alignItems='center'>
        
        
       
        <Button as='b' colorScheme='blue'  onClick={kotPrint}>Print And Save</Button>
        <Button as='b' colorScheme='blue'  onClick={clearBill}>Clear</Button>
        
      </Box>
        </> : kot == 'bill' ? <>
        <Box bg='#F74C31' height='100px' display='flex' p={10} borderRadius='4px' justifyContent='space-between' overflowY='hidden' color='white' alignItems='center'>
          
          <Select width='150px' value={select} bg='white' color='black' onChange={handleSelect} mr={2}>
            <option value={"gst"}>Gst</option>
            <option value={'non-gst'}>Non-Gst</option>
          </Select>
         <Input width='150px' bg='white' placeholder='Enter registration' value={formData.registration} name='registration' color='black' onChange={handleInputChange}/>
        </Box>
        <Box display='flex' flexDirection='column' p={2} justifyContent='space-evenly' minHeight='250px' overflowY='scroll'>
       
          <Box  display='flex' justifyContent='space-between' alignItems='center' p={2}>
            
           < TableContainer>
  <Table variant='simple'>
    <TableCaption>Bill Data</TableCaption>
    <Thead>
      <Tr>
        <Th>Item</Th>
        <Th>Quantity</Th>
        <Th >Price</Th>
      </Tr>
    </Thead>
    <Tbody>
    {billItems.map((item) => (
      <Tr key={item._id}>
        <Td>{item.name}</Td>
        <Td>{item.quantity}</Td>
        <Td>{item.price}</Td>
      </Tr>
    ))}
    </Tbody>
    
  </Table>
</TableContainer>
            
          </Box>
          </Box>
          <Box bg='#F74C31' height='fit-content' display='flex' flexDirection='row' p={5} borderRadius='4px' justifyContent='space-between' overflowY='hidden' color='white' alignItems='center'>
        
          <Text display='flex' alignItems='center' as='b'>Total- <FaRupeeSign mt={2} /> {totalSum}</Text>
         
          <Button as='b' colorScheme='blue' onClick={handleSaveAndPrint}>Submit</Button>
          <Button as='b' colorScheme='blue' onClick={save}>Save</Button>
          
        </Box>
       
      
      </>
       :<>
       <Box bg='#F74C31' height='100px' display='flex' p={10} borderRadius='4px' justifyContent='space-between' overflowY='hidden' color='white' alignItems='center'>
         
         <Select width='40%' value={select} bg='white' color='black' onChange={handleSelect} mr={2}>
           <option value={"gst"}>Gst</option>
           <option value={'non-gst'}>Non-Gst</option>
         </Select>
        {/* <Input width='60%' bg='white' placeholder='Enter registration' value={formData.registration} name='registration' color='black' onChange={handleInputChange}/> */}
       </Box>
       <Box display='flex' flexDirection='column' p={2} justifyContent='space-evenly' minHeight='250px' overflowY='scroll'>
      
         <Box  display='flex' justifyContent='space-between' alignItems='center' p={2}>
           
          < TableContainer>
 <Table variant='simple'>
   <TableCaption>Bill Data</TableCaption>
   <Thead>
     <Tr>
       <Th>Item</Th>
       <Th>Quantity</Th>
       <Th >Price</Th>
     </Tr>
   </Thead>
   <Tbody>
   {billItems.map((item) => (
     <Tr key={item._id}>
       <Td>{item.name}</Td>
       <Td>{item.quantity}</Td>
       <Td>{item.price}</Td>
     </Tr>
   ))}
   </Tbody>
   
 </Table>
</TableContainer>
           
         </Box>
         </Box>
         <Grid bg='#F74C31' height='fit-content' templateColumns='repeat(2, 1fr)' gap={6}  p={5} borderRadius='4px' placeItems='center' overflowY='hidden' color='white' alignItems='center' >
       
         <Input width='80%' bg='white' placeholder='Name' value={quickBill.name} name='name' color='black' onChange={handleQuickDataChange}/>
         <Input width='100%' bg='white' placeholder=' Phone' value={quickBill.phoneNo} name='phoneNo' color='black' onChange={handleQuickDataChange}/>
         
       </Grid>
         <Box bg='#F74C31' height='fit-content' display='flex' flexDirection='row' p={5} borderRadius='4px' justifyContent='space-between' overflowY='hidden' color='white' alignItems='center'>
       
         <Text display='flex' alignItems='center' as='b'>Total- <FaRupeeSign mt={2} /> {totalSum}</Text>
        
         <Button as='b' colorScheme='blue' onClick={quickSave}>Submit</Button>
         <Button as='b' colorScheme='blue' onClick={qSave}>Save</Button>
         
       </Box>
      
     
     </> }
        
      </Box>
      
        </Box>
        <Box>
          
      {arr[0] ? <Card mt={10} bg={image1} textAlign='center' boxShadow='dark-lg' ref={componentRef}>
    <Text color='purple' fontSize='18px' fontFamily='sans-serif' mt={5}>Food Invoice</Text>
      <CardHeader display='flex' justifyContent='center' alignItems='center' gap={5} p={4} boxShadow='md'>
     
      <Box>
          {printData.map((item)=>(
          <Image src={item.imageUrl} w='200px' h='120px' />
         ))}
        </Box>
     
        <Box color='purple' fontSize='18px' fontFamily='sans-serif' bg={image1} textAlign='left' >
        {printData.map((item)=>(<> 
          <Text fontSize='2xl' as='b' >{item.name}</Text>
          <Text fontSize='sm' >{item.adress}</Text>
          <Text fontSize='sm'>{item.phoneNo}</Text>
          <Text fontSize='sm'>{item.website}</Text>
          
          <Text fontSize='sm'>Email id:{item.email}</Text>
          <Text fontSize='sm'>Gst No:{item.gstNo}</Text>
          </> 
        ))}
        </Box>
      </CardHeader>
        <Box   p={4} textAlign='left'>
        {arr1.map((item, index) => (
  <SimpleGrid columns={5} spacing={1} placeItems='center' key={index} p={4}>
  <Text color='purple' fontSize='15px' fontFamily='sans-serif'> Food Item {index+1}</Text>
    <Text color='purple' fontSize='15px' fontFamily='sans-serif'>{item.name}</Text>
    <Text color='purple' fontSize='15px' fontFamily='sans-serif'>{item.quantity}</Text>
    <Text color='purple' fontSize='15px' fontFamily='sans-serif'>{item.price}</Text>
    <Text color='purple' fontSize='15px' fontFamily='sans-serif'>{item.total}</Text>
  </SimpleGrid>
))}

        

         
          <SimpleGrid columns={3} spacing={4} p={4} textAlign='left'>
          {arr.slice(1).map(([key,value]) => (

            <CardBody   borderRadius="5px" p={4}>
            
            <Text color='purple' fontSize='15px' fontFamily='sans-serif' >{ key[0].toUpperCase() +
              key.slice(1)}--{value}</Text>
            
            
              
             
            
            </CardBody>
          ))}
          </SimpleGrid>
          <Box display='flex' justifyContent='space-between' alignItems='center' color='purple' >
          <Box>
            <Text fontSize='md'>Bank Details</Text>
        {printData.map((item)=>(
                <>
            <Text fontSize='sm'>A/C NO - {item.acNo}</Text>
            <Text fontSize='sm'>IFSC CODE - {item.ifsc}</Text>
            <Text fontSize='sm'>BRANCH - {item.branch}</Text>
            {/* <Text>CONTACT NO - {item.phoneNo}</Text> */}
            <Text as='b' display='flex' justifyContent='end' alignItems='center' >
            Total Amount(INR - In Words): {totalSum ? numberToWords(totalSum) : 'Zero'} 
        </Text>
            
            
            </>
          ))}
          </Box>
          <Box display='flex' flexDirection='row'   p={2}>
          <Box display='flex' flexDirection='column' border='1px solid black' >
            <Text  borderBottom='1px solid black' bg='gray.200' p={1}>Summary</Text>
            <Text p={0.5}>Total Rate</Text>
            <Text p={0.5}>Taxable Rate</Text>
            <Text p={0.5}>CGST Amount</Text>
            <Text p={0.5}>SGST Amount</Text>
            <Text p={0.5}>Grand Total</Text>
          </Box>
          <Box display='flex' flexDirection='column' borderTop="1px solid black"
      borderRight="1px solid black"
      borderBottom="1px solid black"
      borderLeft="0" >
         <Text p={1} borderBottom='1px solid black' bg='gray.200'>Amount</Text>
          <Text p={0.5}>{totalAmount}</Text>
            <Text p={0.5}>{totalAmount}</Text>
            <Text p={0.5}>{rcgst}</Text>
            <Text p={0.5}>{rsgst}</Text>
            <Text p={0.5} borderTop='1px solid black'>{totalSum}</Text>
          </Box>
          </Box>
        </Box>
        
        </Box>
      <CardFooter display='flex' justifyContent='space-between' p={25} textAlign='left' >
          <Box color='purple' fontSize='15px' fontFamily='sans-serif'>
          {printData.map((item)=>(
                <>
            {/* <Text>A/C NO - {item.acNo}</Text>
            <Text>IFSC CODE - {item.ifsc}</Text>
            <Text>BRANCH - {item.branch}</Text>
            <Text>CONTACT NO - {item.phoneNo}</Text> */}
            
            
            <Text>Booking Party Signature</Text>
            <Text>Date-</Text>
            </>
          ))}
          </Box>

      </CardFooter>
      
    </Card>:''} 
   {arr[0] ? <Button type="submit" variant='solid' color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={handlePrint}>Print</Button> : ""} 
   </Box>
      
    </Box>
  );
}

export default RestaurentData;
