import React from 'react'
import { Card, Image, Stack, CardBody, Heading, Text, CardFooter, Button, Box ,FormControl,FormLabel,Input} from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const Login = () => {
  return (
    <Box display='flex' alignItems="center" justifyContent="center" height="100vh" bg='#FFEFD5' >
      
      <Card
        direction={{ base: 'column', sm: 'row' }}
        overflow='hidden'
        variant='outline'
        alignItems="center" justifyContent="center"
        bg='#FEA116'
        width='50%'
        p={3}
        
        
      >
        <Image
          objectFit='cover'
          maxW={{ base: '100%', sm: '200px' }}
          display={{lg:'block',md:'none',sm:'none'}}
          height='70vh'
          borderRadius='12px'
          src='https://images.unsplash.com/photo-1667489022797-ab608913feeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw5fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60'
          alt='Caffe Latte'
        />

        <Stack>
          <CardBody>
            <FormControl isRequired>
            <Heading size='md'>Signup</Heading>
            
            <Box width="100%"  display="flex" alignItems="center"  boxShadow='md'
            borderRadius="5px"
            
            p={4}>
              <FormLabel color='black'  >Name</FormLabel>
              <Input  bg='#FAFAFA ' placeholder="Enter your name"   />
            </Box>
            <Box width="100%"  display="flex" alignItems="center"  boxShadow='md'
            borderRadius="5px"
            
            p={4}>
              <FormLabel color='black'  >Email</FormLabel>
              <Input  bg='#FAFAFA ' placeholder="Enter your email"   />
            </Box>
            <Box mb={4} width="100%"  display="flex" alignItems="center"  boxShadow='md'
            borderRadius="5px"
            
            p={4}>
              <FormLabel color='black'  >Password</FormLabel>
              <Input type='password' bg='#FAFAFA ' placeholder="Enter your password"   />
            </Box>
            <Button  variant='solid' color='#FEA116' bg='#0F172B' _hover={{color:'white'}}>
              Signup
            </Button>
            </FormControl>
            
          </CardBody>
         

          <CardFooter alignItems="center" justifyContent="center">
          <Link to='/login'><b>Already Logged in?</b></Link>
          </CardFooter>
        </Stack>
      </Card>
      </Box>
    
  )
}

export default Login
