import React from "react";
import { Box, VStack, Button } from "@chakra-ui/react";
import { Form } from "../modules/customer/index";
import Navbar from "../components/Navbar/Navbar";
import Sidebar from "../components/Sidebar";
import { Route, Routes } from "react-router-dom";
import { route } from "../route";
import Login from '../modules/auth/Login/Login.js'
import Signup from '../modules/auth/Signup/Signup.js'

import RoomRegister from "../modules/room/Room_registration.js";
import Billing from "../modules/billing/Billing.js";
import FoodRegister from "../modules/food/foodItem_Registration.js";
import RoomBilling from "../modules/roombilling/RoomBilling.js";
import CustomerData from "../modules/customer/customerData.js";
import RoomData from "../modules/room/RoomData.js";
import FoodData from "../modules/food/foodData.js";
import FoodBillData from "../modules/food/foodBillData.js";
import NonGstBilling from "../modules/billing/Non-gst-Billing.js";
import NonGstRoomBilling from "../modules/roombilling/Non-gst-RoomBilling.js";
import RoomBillData from "../modules/roombilling/getRoomBillData.js";
import CashRegister from "../modules/cash/cashRegister.js";
import CashData from "../modules/cash/getCashData.js";
import RoomBook from "../modules/roomBook/roomBook.js";
import Dashboard from "../modules/dashboard/index.js";
import RoomBookData from "../modules/roomBook/getRoomData.js";
import GetMergeBill from "../modules/mergeBill/getMergeBill.js";
import RestaurentData from "../modules/restaurant/bill.js";
import KotBillData from "../modules/food/kotBillData.js";
import {BillItemsProvider} from "../modules/context/BillItemsContext.js";
import QuickBillData from "../modules/food/quickBillData.js";
import MergeBillData from "../modules/mergeBill/mergeBillData.js";
import PrintData from "../modules/Print/printData.js";
import Report from "../modules/report/report.js";
import Estimate from "../modules/customer/estimate.js";
import EstimateData from "../modules/customer/estimateData.js";
import RoomStatus from "../modules/room/roomStatus.js";
import BookingInfoModal from "../modules/room/bookingInfoModal.js";
import UpdateModal from "../modules/room/updateGuestDetails.js";
import CustomerStatusModal from "../modules/room/customerStatus.js";
import SupplyRegister from "../modules/inventory/supplyRegister.js";
import ItemRegister from "../modules/inventory/itemRegister.js";
import StoreRegister from "../modules/inventory/storeRegister.js";
import StaffRegister from "../modules/inventory/staffRegister.js";
import PurchaseRegister from "../modules/inventory/purchaseRegister.js";
import StoreData from "../modules/inventory/storeData.js";
import StockTransfer from "../modules/inventory/stockTransfer.js";
import CashUpdateModal from "../modules/cash/CashEditModal.js";
import AdvanceRoomBook from "../modules/room/advanceRoomBook.js";
import PurchaseReport from "../modules/inventory/purchaseReport.js";

const Layout = () => {
  let auth = 0;
  
 let  customerId=localStorage.getItem("customerId")
  console.log("navbar customerId"+customerId)
  
  return (
    <Box width='full' h='full' >
      <Box display="flex">
        {auth ===0  ? <Sidebar /> : ""}
        <Box display='flex' flexDirection='column' width='100%'>
          <Box >
           {auth ==0 ? <Navbar />:""}
           
          </Box>
          <Box overflowX='auto'>
            <BillItemsProvider  >
            <Routes>
              <Route path='/' element={<Form/>}/>
              <Route path='/room' element={<RoomRegister/>}/>
              <Route path='/food' element={<FoodRegister/>}/>
              <Route path='/billing' element={<Billing/>}/>
              <Route path='/nonGstBilling' element={<NonGstBilling/>}/>
              <Route path='/roomBilling' element={<RoomBilling/>}/>
              <Route path='/nonGstRoomBilling' element={<NonGstRoomBilling/>}/>
              <Route path="/login" element={<Login/>}/>
              <Route path="/signup" element={<Signup/>}/>
              <Route path="/customer" element={<CustomerData/>}/>
              <Route path="/roomData" element={<RoomData/>}/>
              <Route path="/foodData" element={<FoodData/>}/>
              <Route path="/foodBillData" element={<FoodBillData/>}/>
              <Route path="/roomBillData" element={<RoomBillData/>}/>
              <Route path="/cashRecipt" element={<CashRegister/>}/>
              <Route path="/cashData" element={<CashData/>}/>
              <Route path="/dashboard" element={<Dashboard/>}/>
              <Route path="/roomBook" element={<RoomBook/>}/>
              <Route path="/getRoomBook" element={<RoomBookData/>}/>
              <Route path="/getMergeBill" element={<GetMergeBill/>}/>
              <Route path="/foodBill" element={<RestaurentData/>}/>
              <Route path="/kotBillData" element={<KotBillData/>}/>
              <Route path="/quickBillData" element={<QuickBillData/>}/>
              <Route path="/mergeBill" element={<MergeBillData/>}/>
              <Route path="/hotel_info" element={<PrintData/>}/>
              <Route path="/report" element={<Report/>}/>
              <Route path="/estimate" element={<Estimate/>}/>
              <Route path="/estimateData" element={<EstimateData/>}/>
              <Route path="/roomStatus" element={<RoomStatus/>}/>
              <Route path="/booking-info" element={<BookingInfoModal/>}/>
              <Route path="/update-booking" element={<UpdateModal/>}/>
              <Route path="/customer-status" element={<CustomerStatusModal/>}/>
              <Route path="/supply-register" element={<SupplyRegister/>}/>
              <Route path="/item-register" element={<ItemRegister/>}/>
              <Route path="/store-register" element={<StoreRegister/>}/>
              <Route path="/staff-register" element={<StaffRegister/>}/>
              <Route path="/purchase-register" element={<PurchaseRegister/>}/>
              <Route path="/store" element={<StoreData/>}/>
              <Route path="/stock-transfer" element={<StockTransfer/>}/>
              <Route path="/cash-update" element={<CashUpdateModal/>}/>
              <Route path="/advanceRoomBokk" element={<AdvanceRoomBook/>}/>
              <Route path="/purchase-report" element={<PurchaseReport/>}/>
            </Routes>
            </BillItemsProvider>
          </Box>
        </Box>
      </Box>
    </Box> 
  );
};

export default Layout;
