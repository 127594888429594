import React, { useEffect, useState } from 'react';
import { Table, Input, TableCaption, Thead, Tbody, Tr, Th, Td, Image, TableContainer, FormControl, FormLabel,Box ,Button} from "@chakra-ui/react";
import axios from 'axios';
import * as XLSX from 'xlsx'

const RoomBillData = () => {
  
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const customerId = localStorage.getItem('customerId');

  const fetchData = async () => {
    const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getRoomBilldata/${customerId}`);
    setData(response.data);
    setFilteredData(response.data);
  };

  useEffect(() => {
    fetchData();
  }, [customerId]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterData(event.target.value, startDate, endDate);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate') {
      setStartDate(value);
    } else if (name === 'endDate') {
      setEndDate(value);
    }
    filterData(searchTerm, name === 'startDate' ? value : startDate, name === 'endDate' ? value : endDate);
  };

  const filterData = (term, startDate, endDate) => {
    let filtered = data;

    // Filter by search term (phone number or registration)
    if (term) {
      filtered = filtered.filter(item =>
        item.phoneNo.includes(term) || item.registration.includes(term) || item.name.toLowerCase().includes(term.toLowerCase())
      );
    }

    // Filter by date range
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      filtered = filtered.filter(item => {
        const itemDate = new Date(item.date);
        return itemDate >= start && itemDate <= end;
      });
    }

    setFilteredData(filtered);
  };

  const formatTimeToAMPM = (time) => {
    const formattedTime = new Date(`6000-01-01T${time}`);
    return formattedTime.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  };
  const exportToExcel = () => {
    // Filter out _id and createdAt fields from the data
    const cleanedData = filteredData.map(({ _id, createdAt, ...rest }) => rest);
  
    // Create a worksheet from the cleaned data
    const worksheet = XLSX.utils.json_to_sheet(cleanedData);
  
    // Capitalize the first letter of each header
    const headers = Object.keys(cleanedData[0] || {});
    headers.forEach((header, index) => {
      const cellAddress = XLSX.utils.encode_cell({ c: index, r: 0 });
      worksheet[cellAddress].v = header.charAt(0).toUpperCase() + header.slice(1);
    });
  
    // Calculate totals for cash and online columns
    let totalCash = 0;
    let totalOnline = 0;
    
    cleanedData.forEach((row) => {
      totalCash += parseInt(row.cash) || 0;
      totalOnline += parseInt(row.online) || 0;
    });
  
    // Add the totals row
    const totalRow = {
      cash: totalCash,
      online: totalOnline,
      // Add any other columns you want to include in the totals row
    };
    
    // Append the total row to the worksheet
    XLSX.utils.sheet_add_json(worksheet, [totalRow], { skipHeader: true, origin: -1 });
  
    // Add borders to all cells in the worksheet
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = { c: C, r: R };
        const cellRef = XLSX.utils.encode_cell(cellAddress);
  
        if (!worksheet[cellRef]) continue;
        if (!worksheet[cellRef].s) worksheet[cellRef].s = {};
        
        worksheet[cellRef].s.border = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }
  
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Cash Data');
  
    // Write the workbook to an Excel file
    XLSX.writeFile(workbook, 'RoomBillData.xlsx');
  };

  return (
    <div>
      <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' mt={4} mb={4}>
      <FormControl >
        <Input
          w='80%'
          type="text"
          placeholder="Search by Phone No or Registration and name"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </FormControl>
      <FormControl  display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
        <FormLabel htmlFor="startDate">Start Date</FormLabel>
        <Input
          id="startDate"
          width='40%'
          name="startDate"
          type="date"
          value={startDate}
          onChange={handleDateChange}
        />
      </FormControl>
      <FormControl  display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
        <FormLabel htmlFor="endDate">End Date</FormLabel>
        <Input
          width='40%'
          id="endDate"
          name="endDate"
          type="date"
          value={endDate}
          onChange={handleDateChange}
        />
      </FormControl>
      </Box>
      <Button colorScheme="blue" onClick={exportToExcel} p={2} mb={5}>
       Export to Excel File
      </Button>
      <TableContainer width='full'>
        <Table size='sm' variant='striped' colorScheme='teal'>
          <TableCaption>Room Data</TableCaption>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Registration</Th>
              <Th>Date</Th>
              <Th>Address</Th>
              <Th>Phone No</Th>
              <Th>Company GST No</Th>
              <Th>Check-In</Th>
              <Th>Check-In Time</Th>
              <Th>Check-Out</Th>
              <Th>Check-Out Time</Th>
              <Th>Room No</Th>
              <Th>Image</Th>
              <Th>Floor No</Th>
              <Th>Category</Th>
              <Th>Type</Th>
              <Th>Rate</Th>
              <Th>CGST</Th>
              <Th>SGST</Th>
              <Th>Total</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.map((item, index) => (
              <Tr key={index}>
                <Td>{item.name}</Td>
                <Td>{item.registration}</Td>
                <Td>{item.date}</Td>
                <Td>{item.address}</Td>
                <Td>{item.phoneNo}</Td>
                <Td>{item.gstNo}</Td>
                <Td>{item.checkIn}</Td>
                <Td>{formatTimeToAMPM(item.checkInTime)}</Td>
                <Td>{item.checkOut}</Td>
                <Td>{formatTimeToAMPM(item.checkOutTime)}</Td>
                <Td>{item.roomNo}</Td>
                <Td><Image w='60' src={item.image} /></Td>
                <Td>{item.floorNo}</Td>
                <Td>{item.category}</Td>
                <Td>{item.ac}</Td>
                <Td>{item.rate}</Td>
                <Td>{item.cgst}</Td>
                <Td>{item.sgst}</Td>
                <Td>{item.total}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default RoomBillData;
