// theme.js
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    darkBg: '#1a202c', // Dark background color
    darkText: '#cbd5e0', // Dark text color
  },
  components: {
    Table: {
      variants: {
        dark: {
          bg: 'darkBg',
          color: 'darkText',
          borderRadius: 'md',
          borderWidth: '1px',
          borderColor: 'gray.700',
        },
      },
    },
    Td: {
      baseStyle: {
        borderColor: 'gray.700',
        borderWidth: '1px',
      },
    },
    Th: {
      baseStyle: {
        borderColor: 'gray.700',
        borderWidth: '1px',
      },
    },
  },
});

export default theme;
