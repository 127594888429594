import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Image,
  Select,
  Box,
  Flex,
  Text,
  Button,
  SimpleGrid,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import image from "../../../assets/img/carousel-1.jpg";
import image1 from "../../../assets/img/room-2.jpg";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import Calendar from "react-calendar";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { numberToWords } from "../../../utils/numberToWords";
const array = [
  "Malda",
  "Uttar Dinajpur",
  "Dakshin Dinajpur",
  "Murshidabad",
  "Birbhum",
  "Hoogly",
  "Paschim Bardhaman",
  "Purba Bardhaman",
  "Alipurduar",
  "Cooch Behar",
  "Darjeeling",
  "Jalipaiguri",
  "Kalingpong",
  "Howrah",
  "Kolkata",
  "Nadia",
  "North 24 Pargana",
  "South 24 Pargana",
  "Bankura",
  "Jhargram",
  "Purulia",
  "Purba Medinipur",
  "Paschim Medinipur",
];

let fiterArray = array.sort((a, b) => {
  return a.localeCompare(b);
});

//console.log(fiterArray);
const Form = () => {
  const componentRef = useRef();
  const [printData, setPrintData] = useState([]);

  const navigate = useNavigate();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const location = useLocation();
  const { updateId } = location.state || {};
  console.log(updateId, "updateId");
  const customerId = localStorage.getItem("customerId");
  const fetchData3 = async () => {
    const response = await axios.get(
      `https://api.hotel.tdrsoftware.in/api/getPrintData/${customerId}`
    );
    setPrintData(response.data);
    const rooms = response.data;
    const updatedRooms = await Promise.all(
      rooms.map(async (room) => {
        const imageResponse = await axios.get(
          "https://api.hotel.tdrsoftware.in/image",
          {
            params: { customerId, key: room.image },
          }
        );
        room.imageUrl = imageResponse.data;
        return room;
      })
    );
    console.log(updatedRooms, "updatedRooms");
    setPrintData(updatedRooms);
  };
  // const [data1, setData1] = useState(new Date());
  // console.log(data1.toDateString());
  // const [data2, setData2] = useState(new Date());
  // console.log(data2.toDateString());
  // const [total, setTotal] = useState(0);
  // const [advance, setAdvance] = useState(0);
  //let dueAmount = 0;
  //const [dueAmount, setDueAmount] = useState(0);
  const calculateNumberOfDays = (checkIn, checkOut) => {
    const startDate = new Date(checkIn);
    const endDate = new Date(checkOut);
    const differenceInTime = endDate.getTime() - startDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return differenceInDays;
  };
  const UpdateData = async () => {
    try {
      const response = await axios.get(
        `https://api.hotel.tdrsoftware.in/api/getCustomerById/${customerId}/${updateId}`
      );
      setFormData(response.data);
      console.log(response.data, "response.data");
    } catch (error) {
      toast.error(error.message);
    }
  };
  const token = localStorage.getItem("token");
  const [submittedData, setSubmittedData] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    registration: "2024-",
    date: "",
    phoneNo: "",
    adhar: "",
    village: "",
    post: "",
    police: "",
    district: "",
    celebration: "",
    checkIn: "",
    checkInTime: "",

    checkOut: "",
    checkOutTime: "",

    total: 0,
    advance: 0,
    groundHall: "",
    firstFloor: "",
    stall: "",
    electricity: "",
    coffee: "",
    generator: "",
    groundHallAc: "",
    firstFloorAc: "",
    fridge: "",
    projector: "",
    video: "",
    rooms: "",
    roomsAc: "",
    roomsAcPrice: null,
    pax: 0,

    // cgst:0,
    // sgst:0,
    due: 0,
  });
  const validateForm = () => {
    const requiredFields = [
      "name",
      "date",
      "phoneNo",
      "adhar",
      "village",
      "post",
      "police",
      "district",
      "celebration",
      "checkIn",

      "checkOut",

      "total",
      "advance",
      "groundHall",
      "firstFloor",
      "stall",
      "electricity",
      "coffee",
      "generator",
    ];

    for (const field of requiredFields) {
      if (!formData[field]) {
        return false;
      }
    }
    return true;
  };
  const generateRandomRegistration = () => {
    const year = new Date().getFullYear(); // Get current year
    const randomDigits = Math.floor(10000 + Math.random() * 90000); // Generate random 5-digit number
    return `${year}-${randomDigits}`;
  };
  useEffect(() => {
    fetchData3();
  }, [customerId]);
  useEffect(() => {
    if (updateId) {
      UpdateData();
    }
  }, [updateId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  console.log(customerId, token);
  // const handleRoomChange = (e) => {
  //   const selected = data.find(item => item.roomNo === e.target.value);
  //   if (selected) {
  //     setFormData({
  //       ...formData,
  //       roomNo: selected.roomNo,
  //       floorNo: selected.floorNo,
  //       category: selected.category,
  //       ac: selected.ac,
  //       rate: selected.rate,
  //       sgst: selected.sgst,
  //       cgst: selected.cgst,
  //       image: selected.image,

  //     });
  //   }
  //   console.log(formData);
  // };
  const handleDueFocus = () => {
    setFormData({
      ...formData,
      due: formData.total - formData.advance,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fill in all required fields.");
      return;
    }

    // Generate or use the existing registration number
    const registrationNumber = updateId
      ? formData.registration
      : generateRandomRegistration();

    // Update the form data with the new or existing registration number
    const updatedFormData = {
      ...formData,
      registration: registrationNumber,
    };

    // Now set the updated form data
    setFormData(updatedFormData);
    setSubmittedData(updatedFormData);
  };
  const save = async (e) => {
    if (!validateForm()) {
      toast.error("Please fill in all required fields.");
      return;
    }
    try {
      if (updateId) {
        const response = await axios.put(
          `https://api.hotel.tdrsoftware.in/api/updateRegister/${customerId}/${updateId}`,
          { data: formData }
        );
        console.log(response.data);
        toast.success(response.data.message);
        setFormData({
          name: "",
          date: "",
          phoneNo: "",
          adhar: "",
          village: "",
          post: "",
          police: "",
          district: "",
          celebration: "",
          checkIn: "",
          checkInTime: "",
          checkOut: "",
          checkOutTime: "",
          total: 0,
          advance: 0,
          groundHall: "",
          firstFloor: "",
          stall: "",
          electricity: "",
          coffee: "",
          generator: "",
          groundHallAc: "",
          firstFloorAc: "",
          fridge: "",
          projector: "",
          video: "",
          due: 0,
          pax: 0,
          rooms: "",
          roomsAc: "",
          roomsAcPrice: 0,
        });
        navigate(location.pathname, { replace: true });
      } else {
        const response = await axios.post(
          "https://api.hotel.tdrsoftware.in/api/customer",
          {
            customerId: customerId,
            data: formData,
          }
        );

        console.log("Data Submitted: ", response.data);
        toast.success(response.data.message);
        setFormData({
          name: "",
          registration: "2024- ",
          date: "",
          phoneNo: "",
          adhar: "",
          village: "",
          post: "",
          police: "",
          district: "",
          celebration: "",
          checkIn: "",

          checkOut: "",

          total: 0,
          advance: 0,
          groundHall: "",
          firstFloor: "",
          stall: "",
          electricity: "",
          coffee: "",
          generator: "",
          groundHallAc: "",
          firstFloorAc: "",
          fridge: "",
          projector: "",
          video: "",
          due: 0,
          rooms: "",
          roomsAc: "",
          roomsAcPrice: 0,
          pax: 0,
        });
      }
    } catch (error) {
      toast.error(error);
    }
  };
  console.log(formData);
  let arr = Object.entries(submittedData);
  console.log(arr);

  //console.log(newObj);

  // due=total-advance
  return (
    <Box
      bg="#EAE5DF "
      columns={{ sm: 2, md: 4 }}
      spacing="8"
      p="10"
      textAlign="center"
      color="black"
      width="100%"
      height="fit-content"
    >
      <FormControl isRequired onSubmit={handleSubmit}>
        <Box display="grid" gridGap={1}>
          <Flex
            display="flex"
            gap="10px"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Box
              width="30%"
              display="flex"
              alignItems="center"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel>Name</FormLabel>
              <Input
                w="80%"
                bg="#FAFAFA "
                placeholder="First name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </Box>
            <Box
              width="30%"
              display="flex"
              alignItems="center"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel>Registration</FormLabel>
              <Input
                w="70%"
                bg="#FAFAFA "
                placeholder="Registration No"
                name="registration"
                value={formData.registration}
                onChange={handleInputChange}
              />
            </Box>
            <Box
              width="30%"
              display="flex"
              alignItems="center"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel>Date</FormLabel>
              <Input
                required
                w="80%"
                bg="#FAFAFA "
                type="date"
                placeholder="Date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
              />
            </Box>
          </Flex>
          <Box
            display="flex"
            gap="10px"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Box
              width="40%"
              display="flex"
              alignItems="center"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel>PhoneNo</FormLabel>
              <Input
                required
                w="70%"
                bg="#FAFAFA "
                placeholder="Enter your mobile Number"
                name="phoneNo"
                value={formData.phoneNo}
                onChange={handleInputChange}
              />
            </Box>
            <Box
              width="40%"
              display="flex"
              alignItems="center"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel>Adhar</FormLabel>
              <Input
                w="80%"
                bg="#FAFAFA "
                placeholder="Enter your adhar no"
                name="adhar"
                value={formData.adhar}
                onChange={handleInputChange}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            gap="10px"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Box
              width="40%"
              display="flex"
              alignItems="center"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel>Village</FormLabel>
              <Input
                w="80%"
                bg="#FAFAFA "
                placeholder="Enter your village name"
                name="village"
                value={formData.village}
                onChange={handleInputChange}
              />
            </Box>
            <Box
              width="40%"
              display="flex"
              alignItems="center"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel>Post</FormLabel>
              <Input
                w="80%"
                bg="#FAFAFA "
                placeholder="Enter your post office"
                name="post"
                value={formData.post}
                onChange={handleInputChange}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            gap="10px"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Box
              width="40%"
              display="flex"
              alignItems="center"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel>Police Station</FormLabel>
              <Input
                w="60%"
                bg="#FAFAFA "
                placeholder="Enter your police station"
                name="police"
                value={formData.police}
                onChange={handleInputChange}
              />
            </Box>
            <Box
              width="40%"
              display="flex"
              alignItems="center"
              overflowY="srcollable"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel>District</FormLabel>
              <Select
                w="80%"
                bg="#FAFAFA "
                placeholder="Select District"
                name="district"
                value={formData.district}
                onChange={handleInputChange}
              >
                {fiterArray.map((item, index) => (
                  <option key={index}>{item}</option>
                ))}
              </Select>
            </Box>
          </Box>
          <Box
            display="flex"
            gap="5px"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Box
              width="50%"
              display="flex"
              alignItems="center"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <Select
                w="80%"
                bg="#FAFAFA "
                placeholder="Select Celebration"
                name="celebration"
                value={formData.celebration}
                onChange={handleInputChange}
              >
                <option>Meeting</option>
                <option>Marriage</option>
                <option>Reception</option>
              </Select>
            </Box>
            <Box
              width="20%"
              display="flex"
              alignItems="center"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel>Pax</FormLabel>
              <Input
                w="80%"
                bg="#FAFAFA "
                width="50%"
                placeholder="Enter Pax No"
                name="pax"
                value={formData.pax}
                onChange={handleInputChange}
              />
            </Box>
          </Box>

          <Box
            display="flex"
            gap="5px"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Box
              width="40%"
              display="flex"
              alignItems="center"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel fontSize="15px">Ground Floor Hall Room</FormLabel>
              <Select
                bg="#FAFAFA "
                placeholder="Yes/No"
                width="40%"
                name="groundHall"
                value={formData.groundHall}
                onChange={handleInputChange}
              >
                <option>Yes</option>
                <option>No</option>
              </Select>
            </Box>
            <Box
              width="40%"
              display="flex"
              alignItems="center"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel fontSize="15px">Ground Floor Ac/Non-Ac</FormLabel>
              <Select
                bg="#FAFAFA "
                placeholder="Yes/No"
                width="40%"
                name="groundHallAc"
                value={formData.groundHallAc}
                onChange={handleInputChange}
              >
                <option>Yes</option>
                <option>No</option>
              </Select>
            </Box>
          </Box>
          <Box
            display="flex"
            gap="5px"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Box
              width="40%"
              display="flex"
              alignItems="center"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel fontSize="15px">First Floor Hall Room</FormLabel>
              <Select
                w="80%"
                bg="#FAFAFA "
                placeholder="Yes/No"
                width="50%"
                name="firstFloor"
                value={formData.firstFloor}
                onChange={handleInputChange}
              >
                <option>Yes</option>
                <option>No</option>
              </Select>
            </Box>
            <Box
              width="40%"
              display="flex"
              alignItems="center"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel fontSize="15px">First Floor Ac/Non-Ac</FormLabel>
              <Select
                w="80%"
                bg="#FAFAFA "
                placeholder="Yes/No"
                width="50%"
                name="firstFloorAc"
                value={formData.firstFloorAc}
                onChange={handleInputChange}
              >
                <option>Yes</option>
                <option>No</option>
              </Select>
            </Box>
          </Box>
          <Box
            display="flex"
            columnGap="20px"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Box
              width="20%"
              display="flex"
              alignItems="center"
              overflowY="srcollable"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel>Rooms</FormLabel>
              <Input
                w="40%"
                bg="#FAFAFA "
                width="50%"
                placeholder="Enter rooms no"
                name="rooms"
                value={formData.rooms}
                onChange={handleInputChange}
              />
            </Box>
            <Box
              width="20%"
              display="flex"
              alignItems="center"
              overflowY="srcollable"
              boxShadow="xl"
              borderRadius="5px"
              p={2}
            >
              <FormLabel>Rooms Type</FormLabel>
              <Select
                w="50%"
                bg="#FAFAFA "
                width="50%"
                placeholder="Rooms Type"
                name="roomsAc"
                value={formData.roomsAc}
                onChange={handleInputChange}
              >
                <option>Ac</option>
                <option>Non-Ac</option>
              </Select>
            </Box>
            <Box
              width="20%"
              display="flex"
              alignItems="center"
              overflowY="srcollable"
              boxShadow="xl"
              borderRadius="5px"
              p={2}
            >
              <FormLabel>Rooms Price</FormLabel>
              <Input
                w="50%"
                bg="#FAFAFA "
                width="50%"
                placeholder="Rooms Price"
                name="roomsAcPrice"
                value={formData.roomsAcPrice}
                onChange={handleInputChange}
              />
            </Box>
          </Box>

          <Box
            display="flex"
            gap="10px"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Box
              width="50%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              boxShadow="xl"
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="15px">CheckIn</FormLabel>

              <Input
                w="40%"
                bg="#FAFAFA "
                type="date"
                name="checkIn"
                value={formData.checkIn}
                onChange={handleInputChange}
              />
              <Input w='40%' bg='#FAFAFA ' type="time" name="checkInTime"  value={formData.checkInTime} onChange={handleInputChange}/>
            </Box>
            <Box
              width="50%"
              display="flex"
              alignItems="center"
              overflowY="srcollable"
              boxShadow="xl"
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="15px">CheckOut</FormLabel>
              <Input
                w="40%"
                bg="#FAFAFA "
                type="date"
                name="checkOut"
                value={formData.checkOut}
                onChange={handleInputChange}
              />
              <Input w='40%' bg='#FAFAFA ' type="time" name="checkOutTime"  value={formData.checkOutTime} onChange={handleInputChange}/>
            </Box>
          </Box>
          <Box
            display="flex"
            columnGap="22px"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Box
              width="30%"
              display="flex"
              alignItems="center"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel fontSize="15px">Total Agreement</FormLabel>
              <Input
                w="50%"
                bg="#FAFAFA "
                placeholder="Enter Total"
                name="total"
                value={formData.total}
                onChange={handleInputChange}
              />
            </Box>
            <Box
              width="30%"
              display="flex"
              alignItems="center"
              overflowY="srcollable"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel fontSize="15px">Advance</FormLabel>
              <Input
                w="50%"
                bg="#FAFAFA "
                placeholder="Enter Advance"
                name="advance"
                value={formData.advance}
                onChange={handleInputChange}
              />
            </Box>
            <Box
              width="30%"
              display="flex"
              alignItems="center"
              overflowY="srcollable"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel>Due</FormLabel>
              <Input
                w="80%"
                bg="#FAFAFA "
                placeholder="Enter Due"
                name="due"
                value={formData.due}
                onChange={handleInputChange}
                onFocus={handleDueFocus}
              />
            </Box>
          </Box>
          {/* <Box
            display="flex"
            rowGap="10px"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
            borderRadius="5px"
            p={4}>
              <FormLabel>Ground Floor Hall Room</FormLabel>
              <Select width="50%"  name='groundHall' value={formData.groundHall} onChange={handleInputChange}>
                <option>Yes</option>
                <option>No</option>
              </Select>
            </Box>
            <Box
              width="40%"
              display="flex"
              alignItems="center"
              overflowY="srcollable"
              boxShadow='xl'
              borderRadius="5px"
              p={4}
            >
              <FormLabel>First Floor Hall Room</FormLabel>
              <Select width="50%" name='firstFloor' value={formData.firstFloor} onChange={handleInputChange} >
                <option>A.C</option>
                <option>Non-A.C</option>
              </Select>
            </Box>
          </Box> */}
          <Box
            display="flex"
            columnGap="20px"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Box
              width="20%"
              display="flex"
              alignItems="center"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel>Stall</FormLabel>
              <Select
                w="80%"
                bg="#FAFAFA "
                placeholder="Yes/No"
                width="50%"
                name="stall"
                value={formData.stall}
                onChange={handleInputChange}
              >
                <option>Yes</option>
                <option>No</option>
              </Select>
            </Box>
            <Box
              width="20%"
              display="flex"
              alignItems="center"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel>Extra Electricity</FormLabel>
              <Select
                w="80%"
                bg="#FAFAFA "
                width="50%"
                placeholder="Yes/No"
                name="electricity"
                value={formData.electricity}
                onChange={handleInputChange}
              >
                <option>Yes</option>
                <option>No</option>
              </Select>
            </Box>
            <Box
              width="30%"
              display="flex"
              alignItems="center"
              overflowY="srcollable"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel>Coffe Machine</FormLabel>
              <Select
                w="40%"
                bg="#FAFAFA "
                width="50%"
                placeholder="Yes/No"
                name="coffee"
                value={formData.coffee}
                onChange={handleInputChange}
              >
                <option>Yes</option>
                <option>No</option>
              </Select>
            </Box>
            <Box
              width="20%"
              display="flex"
              alignItems="center"
              overflowY="srcollable"
              boxShadow="xl"
              borderRadius="5px"
              p={2}
            >
              <FormLabel>Generator</FormLabel>
              <Select
                w="50%"
                bg="#FAFAFA "
                width="50%"
                placeholder="Yes/No"
                name="generator"
                value={formData.generator}
                onChange={handleInputChange}
              >
                <option>Yes</option>
                <option>No</option>
              </Select>
            </Box>
          </Box>
          <Box
            display="flex"
            columnGap="20px"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Box
              width="30%"
              display="flex"
              alignItems="center"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel>Projector</FormLabel>
              <Select
                w="80%"
                bg="#FAFAFA "
                width="50%"
                placeholder="Yes/No"
                name="projector"
                value={formData.projector}
                onChange={handleInputChange}
              >
                <option>Yes</option>
                <option>No</option>
              </Select>
            </Box>
            <Box
              width="30%"
              display="flex"
              alignItems="center"
              overflowY="srcollable"
              boxShadow="xl"
              borderRadius="5px"
              p={4}
            >
              <FormLabel>Fridge</FormLabel>
              <Select
                w="40%"
                bg="#FAFAFA "
                width="50%"
                placeholder="Yes/No"
                name="fridge"
                value={formData.fridge}
                onChange={handleInputChange}
              >
                <option>Yes</option>
                <option>No</option>
              </Select>
            </Box>
            <Box
              width="30%"
              display="flex"
              alignItems="center"
              overflowY="srcollable"
              boxShadow="xl"
              borderRadius="5px"
              p={2}
            >
              <FormLabel>Video Camera</FormLabel>
              <Select
                w="50%"
                bg="#FAFAFA "
                width="50%"
                placeholder="Yes/No"
                name="video"
                value={formData.video}
                onChange={handleInputChange}
              >
                <option>Yes</option>
                <option>No</option>
              </Select>
            </Box>
          </Box>
        </Box>

        <Button
          type="submit"
          variant="solid"
          color="#0F172B"
          mr={3}
          bg="#FEA116"
          _hover={{ color: "white" }}
          mt={5}
          onClick={handleSubmit}
        >
          Generate Print Bill
        </Button>
        <Button
          type="submit"
          variant="solid"
          color="#0F172B"
          bg="#FEA116"
          _hover={{ color: "white" }}
          mt={5}
          onClick={save}
        >
          Save
        </Button>
      </FormControl>
      {arr[0] ? (
        <Card
          bg={image1}
          mt={10}
          textAlign="center"
          ref={componentRef}
          p={2}
          border="1px solid black"
          ml={2}
          mr={2}
        >
          <Text color="purple" fontSize="18px" fontFamily="sans-serif" mt={5}>
            ChuktiPatra
          </Text>
          <CardHeader
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={5}
            p={4}
            boxShadow="md"
          >
            <Box>
              {printData.map((item) => (
                <Image
                  src={item.imageUrl}
                  w="180px"
                  h="120px"
                  p={2}
                  bg="#FAFAFA"
                  borderRadius="12px"
                />
              ))}
            </Box>

            <Box
              color="purple"
              fontSize="18px"
              fontFamily="sans-serif"
              bg={image1}
              textAlign="left"
            >
              {printData.map((item) => (
                <>
                  <Text fontSize="2xl" as="b">
                    {item.name}
                  </Text>
                  <Text fontSize="sm">{item.adress}</Text>
                  <Text fontSize="sm">{item.phoneNo}</Text>
                  <Text fontSize="sm">{item.website}</Text>

                  <Text fontSize="sm">Email id:{item.email}</Text>
                  <Text fontSize="sm">Gst No:{item.gstNo}</Text>
                </>
              ))}
            </Box>
          </CardHeader>
          <SimpleGrid
            columns={4}
            spacing={2}
            p={8}
            textAlign="left"
            border="1px solid gray"
          >
            {arr
              .filter(([key]) => key !== "_id" && key !== "__v") // Filter out _id and __v
              .map(([key, value]) => (
                <CardBody borderRadius="5px" p={3} key={key}>
                  <Text color="purple" fontSize="sm" fontFamily="sans-serif">
                    {key[0].toUpperCase() + key.slice(1)} -- {value}
                  </Text>
                </CardBody>
              ))}
          </SimpleGrid>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            color="purple"
          >
            <Box p={4} textAlign="left">
              <Text fontSize="md">Bank Details</Text>
              {printData.map((item) => (
                <>
                  <Text fontSize="sm">A/C NO - {item.acNo}</Text>
                  <Text fontSize="sm">IFSC CODE - {item.ifsc}</Text>
                  <Text fontSize="sm">BRANCH - {item.branch}</Text>
                  <Text
                    as="b"
                    display="flex"
                    fontSize="md"
                    justifyContent="end"
                    alignItems="center"
                    mt={4}
                  >
                    Due Amount(INR - In Words):{" "}
                    {numberToWords(parseInt(formData.due))}
                  </Text>
                  {/* <Text>CONTACT NO - {item.phoneNo}</Text> */}
                </>
              ))}
            </Box>
            <Box display="flex" flexDirection="row" mr={5} p={5}>
              <Box
                display="flex"
                flexDirection="column"
                border="1px solid black"
              >
                <Text borderBottom="1px solid black" bg="gray.200" p={1}>
                  Summary
                </Text>
                <Text p={0.5}>Total Agreement</Text>
                <Text p={0.5}>Advance</Text>
                <Text p={0.5}>Due</Text>
                {/* <Text p={0.5}>SGST Amount</Text>
            <Text p={0.5}>Grand Total</Text> */}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                borderTop="1px solid black"
                borderRight="1px solid black"
                borderBottom="1px solid black"
                borderLeft="0"
              >
                <Text p={1} borderBottom="1px solid black" bg="gray.200">
                  Amount
                </Text>
                <Text p={0.5}>{formData.total}</Text>
                <Text p={0.5}>{formData.advance}</Text>
                <Text p={0.5}>{formData.due}</Text>
                {/* <Text p={0.5}>{totalSgst}</Text>
            <Text p={0.5} borderTop='1px solid black'>{total}</Text> */}
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            fontSize="sm"
          >
            {/* <Text as='b' display='flex' justifyContent='end' alignItems='center' p={5}> Remarks--{formData.remarks}</Text> */}
          </Box>

          <CardFooter
            display="flex"
            justifyContent="space-between"
            p={25}
            textAlign="left"
          >
            <Box color="purple" fontSize="15px" fontFamily="sans-serif">
              {printData.map((item) => (
                <>
                  {/* <Text>A/C NO - {item.acNo}</Text>
            <Text>IFSC CODE - {item.ifsc}</Text>
            <Text>BRANCH - {item.branch}</Text>
            <Text>CONTACT NO - {item.phoneNo}</Text> */}

                  <Text>Booking Party Signature</Text>
                  <Text>Date-</Text>
                </>
              ))}
            </Box>
            <Box mr={20} color="purple" fontSize="15px" fontFamily="sans-serif">
              <Text>Authorized Signature</Text>
              <Text>Date-</Text>
            </Box>
          </CardFooter>
        </Card>
      ) : (
        ""
      )}
      {arr[0] ? (
        <Button
          type="submit"
          variant="solid"
          color="#0F172B"
          bg="#FEA116"
          _hover={{ color: "white" }}
          mt={5}
          onClick={handlePrint}
        >
          Print
        </Button>
      ) : (
        ""
      )}
    </Box>
  );
};

export default Form;
