import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Image,
    Select,
    Box,
    Flex,
    Text,Button,SimpleGrid,
    Textarea,
    TableContainer,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast
  } from "@chakra-ui/react";
  import { useSelector } from "react-redux";
  import image from '../../assets/img/carousel-1.jpg'
  import image1 from '../../assets/img/room-2.jpg'
  import { Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react'
  import { useRef } from "react";
  import { useReactToPrint } from "react-to-print";
  import "react-calendar/dist/Calendar.css";
  import axios from 'axios'
  import Calendar from "react-calendar";
  import React, { useState,useEffect } from "react";
  import toast from "react-hot-toast";
import { numberToWords } from "../../utils/numberToWords";
import { useLocation } from "react-router-dom";
  const array = [
    "Malda",
    "Uttar Dinajpur",
    "Dakshin Dinajpur",
    "Murshidabad",
    "Birbhum",
    "Hoogly",
    "Paschim Bardhaman",
    "Purba Bardhaman",
    "Alipurduar",
    "Cooch Behar",
    "Darjeeling",
    "Jalipaiguri",
    "Kalingpong",
    "Howrah",
    "Kolkata",
    "Nadia",
    "North 24 Pargana",
    "South 24 Pargana",
    "Bankura",
    "Jhargram",
    "Purulia",
    "Purba Medinipur",
    "Paschim Medinipur",
  ];
  
  let fiterArray = array.sort((a, b) => {
    return a.localeCompare(b);
  });
  
  //console.log(fiterArray);
  const StoreRegister = () => {
  
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    const customerId=localStorage.getItem('customerId')

    const token=localStorage.getItem('token');
    const [data,setData]=useState([])
    const [isEditing, setIsEditing] = useState(false);
    const [editItemId, setEditItemId] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const cancelRef = useRef();
    const [formData, setFormData] = useState({
      name: "",
    category:"",
    indexNo:0,
    
      
    });

 

  //const customerId=localStorage.getItem('customerId');



  const fetchData=async()=>{
    try {
      const response=await axios.get(`https://api.hotel.tdrsoftware.in/api/store/${customerId}`)
      setData(response.data)
    } catch (error) {
      toast.error(error.message)
    }
  }
const edit=(item)=>{
  setIsEditing(true)
  setEditItemId(item._id)
  setFormData({
    name:item.name,
    category:item.category,
    indexNo:item.indexNo
  })
}
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };

useEffect(()=>{
  fetchData()
},[customerId])

  
    const validateForm = () => {
      const requiredFields = [
        "name",
        "category",
        "indexNo",
        
        
     
        
      ];
  
      for (const field of requiredFields) {
        if (!formData[field]) {
          return false;
        }
      }
      return true;
    };
//  const handleSubmit=async(e)=>{
//     e.preventDefault();
//     if(!validateForm()) {
//       toast.error("Please fill in all required fields.");
//       return;
//     }
//     setFormData({
//         name:formData.name,
//         address:formData.address,
//         phoneNo:formData.phoneNo,
//         gstIn:formData.gstIn,
//         openingBalance:formData.openingBalance,
//     })

//  }

    const save=async(e)=>{
        e.preventDefault();
        if(!validateForm()) {
          toast.error("Please fill in all required fields.");
          return;
        }
        try{
       if(isEditing){
        
          const response=await axios.put(`https://api.hotel.tdrsoftware.in/api/storeUpdate/${customerId}/${editItemId}`,{data:formData})
          toast.success(response.data.message)
          setIsEditing(false)
          setEditItemId(null)
          
        
       }else{
        const response=await axios.post("https://api.hotel.tdrsoftware.in/api/storeRegister",{
          customerId:customerId,data:formData
        });
       // console.log('Data Submitted: ',response.data);
        
        toast.success(response.data.message)
      }
        setFormData({
          name: "",
          category:"",
          indexNo:0,
        })
        fetchData()

      }catch(error){
        toast.error(error.meesage)
      }
    }
  //  console.log(formData,'formdata');
    
   // console.log(arr)
   
    //console.log(newObj);
   
    const openModal = (id) => {
      setDeleteItemId(id);
      setIsOpen(true);
    };
  
    const onClose = () => {
      setIsOpen(false);
      setDeleteItemId(null);
    };
    const handleDelete=async()=>{
     
      try {
        const response=await axios.delete(`https://api.hotel.tdrsoftware.in/api/storeDelete/${customerId}/${deleteItemId}`)
        toast.success(response.data.message)
        setIsOpen(false)
        setDeleteItemId(null)
        fetchData()
      } catch (error) {
        toast.error(error.message)
      }
    }
    
    // due=total-advance
    return (
      <Box   bg='#EAE5DF '
      columns={{ sm: 2, md: 4 }}
      spacing='8'
      p='10'
      textAlign='center'
      
      
      color='black' width='100%' height='500px'   >
      <Text fontSize='2xl' fontWeight='bold' textAlign='center' color='black' mb={10}>Store Register</Text>
        
        <FormControl isRequired onSubmit={save}>
          <Box display="grid"  gridGap={1} >
        
            
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel fontSize='sm'>Name</FormLabel>
                <Input size='sm' w='74%'  bg='#FAFAFA ' placeholder="Enter Store Name" name='name' value={formData.name} onChange={handleInputChange}/>
              </Box>
             
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel fontSize='sm'>Category</FormLabel>
                <Input size='sm' w='71%' bg='#FAFAFA ' placeholder="Enter Category" name='category' value={formData.category} onChange={handleInputChange}/>
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel fontSize='sm'>IndexNo</FormLabel>
                <Input size='sm' w='71%' bg='#FAFAFA ' placeholder="Enter Index No" name='indexNo' value={formData.indexNo} onChange={handleInputChange}/>
              </Box>
              </Box>
              
             
            
            
            
            
            
              
             
           
          
          </Box>
         
          {/* <Button type="submit" variant='solid' color='#0F172B' mr={3} bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={handleSubmit}>Cash Bill</Button> */}
          <Button type="submit" variant='solid' color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={save}>{isEditing ? "Update" : "Save"}</Button>
         
        </FormControl>
        <TableContainer width='full' mt={10} border='1px solid black' p={4} bg='white' borderRadius='10px' fontSize='sm'>
                <Table size='sm' variant='striped' colorScheme='teal'>
                    <TableCaption>Item Data</TableCaption>
                    <Thead>
                        <Tr >
                            <Th >Name</Th>
                            <Th>Category</Th>
                            <Th>Index No</Th>
                            
                            
                            <Th>Edit</Th>
                            <Th>Delete</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data.map((item, index) => (
                            <Tr key={index}>
                                <Td>{item.name}</Td>
                                <Td>{item.category}</Td>
                                <Td>{item.indexNo}</Td>
                               
                                
                                
                                <Td><Button colorScheme="green" size="sm" onClick={() => edit(item)}>Edit</Button></Td>
                                <Td><Button colorScheme="red" size="sm" onClick={() => openModal(item._id)}>Delete</Button></Td>

                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Deletion
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure you want to delete this item?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      
      
        
      </Box>
    );
  };
  
  export default StoreRegister;
  