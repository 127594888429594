import React, { useEffect, useState } from 'react';
import { Table, Input, TableCaption, Thead, Tbody, Tr, Th, Td, Button, TableContainer, Box, FormControl, FormLabel } from "@chakra-ui/react";
import axios from 'axios';
import { numberToWords } from '../../utils/numberToWords';
import * as XLSX from 'xlsx'

const MergeBillData = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [printData, setPrintData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const customerId = localStorage.getItem('customerId');

  const fetchData = async () => {
    const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getMergeBill/${customerId}`);
    setData(response.data);
    setFilteredData(response.data);
  };

  const fetchData3 = async () => {
    const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getPrintData/${customerId}`);
    setPrintData(response.data);
    const rooms = response.data;
    const updatedRooms = await Promise.all(
      rooms.map(async (room) => {
        const imageResponse = await axios.get('https://api.hotel.tdrsoftware.in/image', {
          params: { customerId, key: room.image }
        });
        room.imageUrl = imageResponse.data;
        return room;
      })
    );
    setPrintData(updatedRooms);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterData(event.target.value, startDate, endDate);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate') {
      setStartDate(value);
    } else if (name === 'endDate') {
      setEndDate(value);
    }
    filterData(searchTerm, name === 'startDate' ? value : startDate, name === 'endDate' ? value : endDate);
  };

  const filterData = (term, startDate, endDate) => {
    let filtered = data;

    // Filter by search term
    if (term) {
      filtered = filtered.filter(item =>
        item.phoneNo.includes(term) || item.registration.includes(term)
      );
    }

    // Filter by date range
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      filtered = filtered.filter(item => {
        const checkInDate = new Date(item.checkIn);
        const checkOutDate = new Date(item.checkOut);
        return (checkInDate >= start && checkInDate <= end) || (checkOutDate >= start && checkOutDate <= end) || (checkInDate <= start && checkOutDate >= end);
      });
    }

    setFilteredData(filtered);
  };
  const exportToExcel = () => {
    // Filter out _id and createdAt fields from the data
    const cleanedData = filteredData.map(({ _id, createdAt, ...rest }) => rest);
  
    // Create a worksheet from the cleaned data
    const worksheet = XLSX.utils.json_to_sheet(cleanedData);
  
    // Capitalize the first letter of each header
    const headers = Object.keys(cleanedData[0] || {});
    headers.forEach((header, index) => {
      const cellAddress = XLSX.utils.encode_cell({ c: index, r: 0 });
      worksheet[cellAddress].v = header.charAt(0).toUpperCase() + header.slice(1);
    });
  
    // Calculate totals for cash and online columns
    let totalCash = 0;
    let totalOnline = 0;
    
    cleanedData.forEach((row) => {
      totalCash += parseInt(row.cash) || 0;
      totalOnline += parseInt(row.online) || 0;
    });
  
    // Add the totals row
    const totalRow = {
      cash: totalCash,
      online: totalOnline,
      // Add any other columns you want to include in the totals row
    };
    
    // Append the total row to the worksheet
    XLSX.utils.sheet_add_json(worksheet, [totalRow], { skipHeader: true, origin: -1 });
  
    // Add borders to all cells in the worksheet
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = { c: C, r: R };
        const cellRef = XLSX.utils.encode_cell(cellAddress);
  
        if (!worksheet[cellRef]) continue;
        if (!worksheet[cellRef].s) worksheet[cellRef].s = {};
        
        worksheet[cellRef].s.border = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }
  
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Cash Data');
  
    // Write the workbook to an Excel file
    XLSX.writeFile(workbook, 'MergeBillData.xlsx');
  };


  const handlePrint = (item) => {
    const content = `
      <html>
        <head>
          <style>
            body { font-family: Arial, sans-serif; color: purple; margin: 10px; }
            .grid-container { display: grid; grid-template-columns: repeat(3, 1fr); gap: 8px; margin-top: 10px; }
            .container { display: flex; justify-content: space-between; align-items: center; gap: 8px; margin-top: 20px; }
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #dddddd; text-align: left; padding: 8px; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body>
          <h2 style="text-align: center; color: purple; padding: 3px">Final Bill</h2>
          <div style="display: flex; justify-content: center; align-items: center; gap: 18px; color: purple">
            ${printData.map((item) => `
              <img src="${item.imageUrl}" style="width: 200px; height: 200px; border-radius: 12px; margin-right: 10px" />
              <div>
                <h2>${item.name}</h2>
                <h5>${item.adress}</h5>
                <h5>Phone: ${item.phoneNo}</h5>
                <h5>Website: ${item.website}</h5>
                <h5>Email id: ${item.email}</h5>
                <h5>Gst No: ${item.gstNo}</h5>
              </div>
            `).join('')}
          </div>
          <div class="grid-container" style="padding: 14px">
            <p>Guest Name: ${item.name}</p>
            <p>Registration: ${item.registration}</p>
            <p>Phone No: ${item.phoneNo}</p>
            <p>Room No: ${item.roomNo}</p>
            <p>CheckIn: ${item.checkIn}</p>
            <p>CheckOut: ${item.checkOut}</p>
            <p>FoodBill: ${item.totalBilling}</p>
            <p>RoomBill: ${item.mergeBill - item.totalBilling - item.price}</p>
            <p>Services: ${item.serviceName}</p>
            <p>Services Price: ${item.price}</p>
          </div>
          <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 2px; color: purple">
            ${printData.map((print) => `
              <div style="margin-left: 10px">
                <h3>Bank Details</h3>
                <h4>A/C No: ${print.acNo}</h4>
                <h5>IFSC: ${print.ifsc}</h5>
                <h5>Branch: ${print.branch}</h5>
              </div>
              <div style="margin-right: 10px">
                <h3>Summary</h3>
                <h4>RoomBill: ${item.total}</h4>
                <h4>FoodBill: ${item.totalBilling}</h4>
                <h4>${item.serviceName}: ${item.price}</h4>
                <h4>Total Amount: ${item.mergeBill}</h4>
              </div>
            `).join('')}
          </div>
          <h3 style="color: purple; margin-left: 10px">Total (INR-In Words): ${numberToWords(parseInt(item.mergeBill))}</h3>
          <div class='container' style="color: purple; margin: 15px">
            ${printData.map((item) => `
              <div>
                <h5>Booking Party Signature- </h5>
                <h5>Date- </h5>
              </div>
              <div>
                <h5>Authorized Signature- </h5>
                <h5>Date- </h5>
              </div>
            `).join('')}
          </div>
          <script>
            window.onload = () => window.print();
          </script>
        </body>
      </html>
    `;

    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.open();
    printWindow.document.write(content);
    printWindow.document.close();
  };

  useEffect(() => {
    fetchData();
    fetchData3();
  }, [customerId]);

  return (
    <div>
      <Box display='flex' flexDirection='row' justifyContent='space-evenly' alignItems='center' mt={2} mb={5}>
      <Input
      ml={10}
        w='80%'
        type="text"
        placeholder="Search by Phone No or Registration"
        value={searchTerm}
        onChange={handleSearchChange}
         // Adds margin bottom for spacing
      />
      
        <FormControl display='flex' justifyContent='center' alignItems='center'>
          <FormLabel htmlFor="startDate">Start Date</FormLabel>
          <Input
           width='40%'
            id="startDate"
            name="startDate"
            type="date"
            value={startDate}
            onChange={handleDateChange}
          />
        </FormControl>
        <FormControl display='flex' justifyContent='center' alignItems='center'>
          <FormLabel htmlFor="endDate">End Date</FormLabel>
          <Input
          width='40%'
            id="endDate"
            name="endDate"
            type="date"
            value={endDate}
            onChange={handleDateChange}
          />
        </FormControl>
      
      </Box>
      <Button colorScheme="blue" onClick={exportToExcel} p={2} mb={5}>
       Export to Excel File
      </Button>
      <TableContainer width='full'>
        <Table size='sm' variant='striped' colorScheme='teal'>
          <TableCaption>Merge Bill Data</TableCaption>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Registration</Th>
              <Th>Phone No</Th>
              <Th>CheckIn</Th>
              <Th>CheckOut</Th>
              <Th>Room No</Th>
              <Th>FoodBill</Th>
              <Th>RoomBill</Th>
              <Th>Services</Th>
              <Th>Services Price</Th>
              <Th>Total</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.map((item, index) => (
              <Tr key={index}>
                <Td>{item.name}</Td>
                <Td>{item.registration}</Td>
                <Td>{item.phoneNo}</Td>
                <Td>{item.checkIn}</Td>
                <Td>{item.checkOut}</Td>
                <Td>{item.roomNo}</Td>
                <Td>{item.totalBilling}</Td>
                <Td>{item.mergeBill - item.totalBilling - item.price}</Td>
                <Td>{item.serviceName}</Td>
                <Td>{item.price}</Td>
                <Td>{item.mergeBill}</Td>
                <Td>
                  <Button colorScheme="blue" size="sm" onClick={() => handlePrint(item)}>Print</Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MergeBillData;
