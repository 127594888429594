import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Select,
    Box,
    Flex,
    Text,Button,Card,CardBody,SimpleGrid,
    Textarea,CardHeader,CardFooter,Image
  } from "@chakra-ui/react";
  import image from '../../assets/img/carousel-1.jpg'
  import image1 from '../../assets/img/room-1.jpg'
  import ShowTodo from "../../modules/billing/AddFood";
  import  TextField  from "../../modules/billing/TextField";
  import "react-calendar/dist/Calendar.css";
  import Calendar from "react-calendar";
  import { useReactToPrint } from "react-to-print";
  import React, { useState,useEffect,useRef } from "react";
  import { useSelector } from "react-redux";
  import axios from 'axios'
import toast from "react-hot-toast";
  
  const NonGstBilling = () => {
    const [showInput, setShowInput] = useState(false);
    const [printData,setPrintData]=useState([])
    // const [data1, setData1] = useState(new Date());
    // console.log(data1.toDateString());
    // const [data2, setData2] = useState(new Date());
    // console.log(data2.toDateString());
    // const [total, setTotal] = useState(0);
    // const [advance, setAdvance] = useState(0);
    //let dueAmount = 0;
    const fetchData3=async()=>{
      const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getPrintData/${customerId}`)
      setPrintData(response.data)
      const rooms = response.data;
      const updatedRooms = await Promise.all(
        rooms.map(async (room) => {
          const imageResponse = await axios.get('https://api.hotel.tdrsoftware.in/image', {
            params: { customerId, key: room.image }
          });
          room.imageUrl = imageResponse.data;
          return room;
        })
      );
      console.log(updatedRooms, 'updatedRooms');
      setPrintData(updatedRooms);
  }
    const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
    const [dueAmount, setDueAmount] = useState(0); 
    const [formData, setFormData] = useState({
      foodItem: [],
      
      foodCategory: "",
      foodType:'',
      foodRate: 0,
      sgst: 0,
      cgst: 0,
      registration: '',
      totalBilling: 0,
      
    });
   
    const [submittedData,setSubmittedData] = useState([]);
    const list=useSelector((state)=>state.food);
    const customerId=localStorage.getItem('customerId')
    console.log(customerId)
  
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  // Calculate total sum of 'total' values
  const totalSum = formData.foodItem.reduce((accumulator, currentValue) => {
  return accumulator + currentValue.total;
  }, 0);
  let rcgst=totalSum*0.025;
  let rsgst=totalSum*0.025;
  
  let subTotal=totalSum+rcgst+rsgst;
  console.log(subTotal+"s");
  
  console.log('Total sum:', totalSum,rcgst,rsgst); // Output: 550
  
    
  
    
    
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Here you can send formData to your backend server or process it further
      console.log("Form submitted with data:", formData);
      
      // Reset form after submission
      setFormData({
        foodItem: list,
      foodType:formData.foodType,
      foodCategory: formData.foodCategory,
      foodRate: totalSum,
      sgst: 0,  
      cgst: 0,
      registration: formData.registration,
      totalBilling: totalSum,
       
      });
      setSubmittedData( formData);
      
    };
    
    // const handleSubmit1 = (e) => {
    //   e.preventDefault();
    //   // Here you can send formData to your backend server or process it further
    //   console.log("Form submitted non-gst with data:", formData);
      
    //   // Reset form after submission
    //   setFormData({
    //     foodItem: list,
      
    //   foodCategory: formData.foodCategory,
    //   foodRate: totalSum,
    //   sgst: 0,  
    //   cgst: 0,
    //   registration: formData.registration,
    //   totalBilling: totalSum,
       
    //   });
    //   setSubmittedData( formData);
      
    // };
  let arr=Object.entries(formData);
  console.log(arr)
   // console.log(Object.entries(submittedData[0]));
    // useEffect(() => {
    //   // Calculate due amount whenever relevant form fields change
    //   // const calculateDue = () => {
    //   //   const { totalAgreement, advance } = formData;
    //   //   const due = parseInt(totalAgreement) - parseInt(advance);
    //   //   setDueAmount(isNaN(due) ? 0 : due);
    //   // };
    //   // calculateDue();
    // }, [formData.totalAgreement, formData.advance]);
    
    // due=totalAgreement-advance
    useEffect(()=>{
      fetchData3()
    },[customerId])
    const save=async(e)=>{
      e.preventDefault();
      try{
        const response=await axios.post("https://api.hotel.tdrsoftware.in/api/foodBillRegister",{
          customerId:customerId,data:formData
        });
        console.log('Data Submitted: ',response.data);
        toast(response.data.message)
    }catch(error){
  console.log(error)
    }
  }
    return (
      <Box   bg='#EAE5DF '
      columns={{ sm: 2, md: 4 }}
      spacing='8'
      p='10'
      textAlign='center'
      
      color='black' width='100%' height='fit-content'   >
        
        <FormControl isRequired onSubmit={handleSubmit}>
          <Box display="grid"  gridGap={1} >
            <Flex
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
             
            >
              <Box width="90%"  display="flex" flexDirection='column' alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              
              p={4}>
                <FormLabel  >Name</FormLabel>
                {/* <Input  bg='#FAFAFA ' placeholder="Enter Food Item"  name='foodItem' value={formData.foodItem} onChange={handleInputChange} /> */}
                {showInput && <TextField bg='#FAFAFA ' name='foodItem' value={formData.foodItem} onChange={handleInputChange} />}
        <ShowTodo />
        <Button onClick={() => setShowInput(!showInput)}>
          {showInput ? 'Done' : 'Add Food'}
        </Button>
              </Box>
              {/* <Box width="20%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Description</FormLabel>
                <Textarea  bg='#FAFAFA ' type="text" placeholder="Enter Food Description" name='description' value={formData.description} onChange={handleInputChange} />
              </Box> */}
            </Flex>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Category</FormLabel>
                <Select w='55%' bg='#FAFAFA ' placeholder="Food Category" name='foodCategory' value={formData.foodCategory} onChange={handleInputChange}>
                  <option>Chinese</option>
                  <option>Bengali</option>
                  <option>Italian</option>
                </Select>
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>FoodType</FormLabel>
                <Select w='55%' bg='#FAFAFA ' placeholder="Food Type" name='foodType' value={formData.foodType} onChange={handleInputChange}>
                  <option>Breakfast</option>
                  <option>Tiffin</option>
                  <option>Lunch</option>
                  <option>Dinner</option>
                  <option>Water</option>
                  <option>Others</option>
                </Select>
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Price</FormLabel>
                <Input w='60%'  bg='#FAFAFA ' placeholder="Enter Food Price" name='foodRate' value={formData.foodRate} onChange={handleInputChange}/>
              </Box>
            </Box>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>SGST</FormLabel>
                <Input w='80%' bg='#FAFAFA ' placeholder="Enter SGST rate" name='sgst' value={formData.sgst} onChange={handleInputChange}/>
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>CGST</FormLabel>
                <Input w='80%' bg='#FAFAFA ' placeholder="Enter CGST rate" name='cgst' value={formData.cgst} onChange={handleInputChange} />
              </Box>
            </Box>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Room Billing</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Enter Registration No" name='registration' value={formData.registration} onChange={handleInputChange} />
              </Box>
              <Box
                width="40%"
                display="flex"
                alignItems="center"
                overflowY="srcollable"
                boxShadow='xl'
                borderRadius="5px"
                p={4}
              >
                <FormLabel>Total Billing</FormLabel>
                <Input w='70%'  bg='#FAFAFA ' placeholder="Enter Total Bill" name='totalbilling' value={formData.totalBilling} onChange={handleInputChange}/>
              </Box>
            </Box>
            
           
          </Box>
         
          <Button type="submit" variant='solid' color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={handleSubmit}>Generate Non-Gst Bill</Button>
          {/* <Button type="submit" variant='solid' color='#0F172B' ml={3} bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={handleSubmit1}>Generate Non-Gst Bill</Button> */}
          <Button type="submit" variant='solid' color='#0F172B' ml={3} bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={save}>Save</Button>
        </FormControl>
        {arr[0] ? <Card mt={10} bg={image1} textAlign='center' boxShadow='dark-lg' ref={componentRef}>
      <Text color='purple' fontSize='18px' fontFamily='sans-serif' mt={5}>Food Invoice</Text>
        <CardHeader display='flex' justifyContent='center' alignItems='center' gap={5} p={4} boxShadow='md'>
       
        <Box>
          {printData.map((item)=>(
          <Image src={item.imageUrl} w='200px' h='120px' />
         ))}
        </Box>
       
          <Box color='purple' fontSize='18px' fontFamily='sans-serif' bg={image1} textAlign='left' >
          {printData.map((item)=>(<> 
          <Text >{item.name}</Text>
          <Text >{item.adress}</Text>
          <Text>{item.phoneNo}</Text>
          <Text>{item.website}</Text>

          <Text>Email id:{item.email}</Text>
          <Text>Gst No:{item.gstNo}</Text>
          </> 
        ))}
          </Box>
        </CardHeader>
          <Box   p={4} textAlign='left'>
          {list.map((item, index) => (
    <SimpleGrid columns={5} spacing={1} placeItems='center' key={index} p={4}>
    <Text color='purple' fontSize='15px' fontFamily='sans-serif'> Food Item {index+1}</Text>
      <Text color='purple' fontSize='15px' fontFamily='sans-serif'>{item.item}</Text>
      <Text color='purple' fontSize='15px' fontFamily='sans-serif'>{item.quantity}</Text>
      <Text color='purple' fontSize='15px' fontFamily='sans-serif'>{item.price}</Text>
      <Text color='purple' fontSize='15px' fontFamily='sans-serif'>{item.total}</Text>
    </SimpleGrid>
  ))}
  
          
  
            {/* <CardBody   borderRadius="5px" p={4}>
            <SimpleGrid columns={2} placeItems='center' spacing={4}  p={4}>
            
            <Text color='purple' fontSize='15px' fontFamily='sans-serif' >FoodBill--{totalSum}</Text>
            <Text color='purple' fontSize='15px' fontFamily='sans-serif' >CGST--{rcgst}</Text>
            <Text color='purple' fontSize='15px' fontFamily='sans-serif' >SGST--{rsgst}</Text>
            <Text color='purple' fontSize='25px' fontFamily='sans-serif' >Total Bill--{subTotal}</Text>
             
            
            
              
            </SimpleGrid>
            
            </CardBody> */}
            <SimpleGrid columns={3} spacing={4} p={4} textAlign='left'>
            {arr.slice(1).map(([key,value]) => (
  
              <CardBody   borderRadius="5px" p={4}>
              
              <Text color='purple' fontSize='15px' fontFamily='sans-serif' >{ key[0].toUpperCase() +
                key.slice(1)}--{value}</Text>
              
              
                
               
              
              </CardBody>
            ))}
            </SimpleGrid>
          
          
          </Box>
        <CardFooter display='flex' justifyContent='space-between' p={25} textAlign='left' >
            <Box color='purple' fontSize='15px' fontFamily='sans-serif'>
            <Text>Booking Party Signature</Text>
            <Text>Date-</Text>
            </Box>
            <Box mr={20} color='purple' fontSize='15px' fontFamily='sans-serif'>
            <Text>Authorized Signature</Text>
            <Text>Date-</Text>
            </Box>
  
        </CardFooter>
        
      </Card>:''}
     {arr[0] ? <Button type="submit" variant='solid' color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={handlePrint}>Print</Button> : ""}
        
      </Box>
    );
  };
  
  export default NonGstBilling;
  