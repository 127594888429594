import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Box, VStack, Button, Table, Thead, Tbody, Tr, Th, Td, TableCaption, Image,useDisclosure, extendTheme, TableContainer, Card, CardBody, CardFooter, Stack, Heading, ButtonGroup, Divider, Text, SimpleGrid, Flex } from "@chakra-ui/react";
import toast from 'react-hot-toast';
import { FaEdit, FaRupeeSign, FaTrash } from "react-icons/fa";
//import { AiFillEdit } from "react-icons/ci";
import { AiFillEdit } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';

const RoomData = () => {
  const [data, setData] = useState([]);
  const [room,setRoom]=useState([]);
  const [id,setId]=useState('')
  const customerId = localStorage.getItem('customerId');
  const [filter, setFilter] = useState({ category: '', ac: '' });

  const fetchData = async () => {
    const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getRoomdata/${customerId}`);
    const rooms = response.data;
//const {isOpen, onOpen, onClose} = useDisclosure({});
    const updatedRooms = await Promise.all(
      rooms.map(async (room) => {
        const imageResponse = await axios.get('https://api.hotel.tdrsoftware.in/image', {
          params: { customerId, key: room.image }
        });
        room.imageUrl = imageResponse.data;
        return room;
      })
    );

    setData(updatedRooms);
  };
  const handleRoomUpdate = async (id) => {
    // setId(id);
    // try {
    //   const response=await axios.get(`https://api.hotel.tdrsoftware.in/api/getRoomdata/${customerId}/${id}`);
    //   setRoom(response.data);
    //   onOpen();
    // } catch (error) {
    //   toast.error(error.meesage);
    // }
  };
const  handleDelete = async (id) => {}
  const navigate = useNavigate();

  const handleRoomAvailability = async (id) => {
    try {
      const response = await axios.put(`https://api.hotel.tdrsoftware.in/api/updateAvailability/${customerId}/${id}`);
      toast.success(response.data.message);
      window.location.reload('/roomData');
    } catch (error) {
      toast.error(error);
    }
  };

  const theme = extendTheme({
    colors: {
      darkBg: '#1a202c',
      darkText: '#cbd5e0',
    },
    components: {
      Table: {
        variants: {
          dark: {
            bg: 'darkBg',
            color: 'darkText',
            borderRadius: 'md',
            borderWidth: '1px',
            borderColor: 'black.700',
          },
        },
      },
      Td: {
        baseStyle: {
          borderColor: 'gray.700',
          borderWidth: '1px',
        },
      },
      Th: {
        baseStyle: {
          borderColor: 'gray.700',
          borderWidth: '1px',
        },
      },
    },
  });

  const getAvailabilityColor = (availability) => {
    return availability ? 'green.500' : 'red.500';
  };

  useEffect(() => {
    fetchData();
  }, [customerId]);

  const filteredData = data.filter(item => {
    return (
      (filter.category === '' || item.category === filter.category) &&
      (filter.ac === '' || item.ac === filter.ac)
    );
  });

  const handleCategoryFilter = (category) => {
    setFilter({ ...filter, category });
  };

  const handleAcFilter = (ac) => {
    setFilter({ ...filter, ac });
  };

  return (
    <Box p={10}>
      <SimpleGrid columns={4} spacing={4} mb={4}>
        {/* Category Filters */}
        <Button fontSize='sm'
          colorScheme={filter.category === '' ? 'blue' : 'gray'}
          onClick={() => handleCategoryFilter('')}
        >
          All Categories
        </Button>
        {[...new Set(data.map(item => item.category))].map(category => (
          <Button fontSize='sm'
            key={category}
            colorScheme={filter.category === category ? 'blue' : 'gray'}
            onClick={() => handleCategoryFilter(category)}
          >
            {category}
          </Button>
        ))}
        </SimpleGrid>
        <SimpleGrid columns={3} spacing={4} mb={4}>
        {/* AC Filters */}
        <Button
        fontSize='sm'
          colorScheme={filter.ac === '' ? 'green' : 'gray'}
          onClick={() => handleAcFilter('')}
        >
          All AC/Non-AC
        </Button>
        <Button fontSize='sm'
          colorScheme={filter.ac === 'AC' ? 'green' : 'gray'}
          onClick={() => handleAcFilter('AC')}
        >
          AC
        </Button>
        <Button fontSize='sm'
          colorScheme={filter.ac === 'Non-AC' ? 'green' : 'gray'}
          onClick={() => handleAcFilter('Non-AC')}
        >
          Non-AC
        </Button>
        </SimpleGrid>
      
      <SimpleGrid minChildWidth='280px' placeItems='center' height='fit-content' gap='20px' mt={10}>
        {filteredData.map((item, index) => (
          <Card maxW='sm' key={index} boxShadow='dark-lg'>
            <CardBody>
              <Image
                
                src={item.imageUrl}
                alt={item.roomNo}
                borderRadius='lg'
                height='230px'
                width='full'
              />
              <Stack mt='6' spacing='3'>
                <Text as='b' color='blue' size='md'>Room No : {item.roomNo}</Text>
                <Flex justifyContent='center' gap={5} textAlign='left'>
                  <Text as='b'>Category: {item.category}</Text>
                  <Text as='b'>Type: {item.ac}</Text>
                </Flex>
                <Flex justifyContent='space-between' gap={5} textAlign='left'>
                <Text color='blue.600' fontSize='2xl' display='flex' justifyContent='center' alignItems='center'>
                  <FaRupeeSign /> {item.rate}
                </Text>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Button variant='ghost' colorScheme='green' size='lg' onClick={() => handleRoomUpdate(item._id)} >
                  <FaEdit/>
                </Button>
                <Button variant='ghost' colorScheme='red' onClick={()=>handleDelete(item._id)} >
                  <FaTrash/>
                </Button>
                </Box>
                  {/* <Text as='b' color='orange'>Adult: {item.adult}</Text>
                  <Text as='b' color='green'>Child: {item.child}</Text> */}
                </Flex>
                
              </Stack>
            </CardBody>
            <Divider />
            <CardFooter>
            {/* <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Button variant='solid' colorScheme='green' onClick={() => handleRoomUpdate(item._id)} >
                  <FaEdit/>
                </Button>
                <Button variant='ghost' colorScheme='red' onClick={()=>handleDelete(item._id)} >
                  <FaTrash/>
                </Button>
              </Box> */}

              <ButtonGroup spacing='2'>
                <Button variant='solid' colorScheme='blue' onClick={() => handleRoomAvailability(item._id)} isDisabled={item.availability === true}>
                  Change Status
                </Button>
                <Button variant='ghost' colorScheme='blue' color={getAvailabilityColor(item.availability)}>
                  {item.availability === true ? "Not Booked" : "Booked"}
                </Button>
              </ButtonGroup>
            </CardFooter>
          </Card>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default RoomData;
