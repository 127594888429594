import React, { useEffect, useState,useRef } from 'react';
import axios from 'axios';
import { Box, VStack, Button, Table, Thead, Tbody, Tr, Th, Td, TableCaption, TableContainer, Input, FormControl, FormLabel,AlertDialogBody,AlertDialog,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay } from "@chakra-ui/react";
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa';
import * as XLSX from 'xlsx';
const EstimateData = () => {
  const cancelRef = useRef();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [copiedRegistration,setCopiedRegistration]=useState(null)
  const customerId = localStorage.getItem('customerId');
  const navigate = useNavigate();

  const fetchData = async () => {
    const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getEstimateData/${customerId}`);
    setData(response.data);
    setFilteredData(response.data);
  };
  const copyRegistrationNumber = (registrationNumber,id) => {
    navigator.clipboard.writeText(registrationNumber);
    setCopiedRegistration(registrationNumber); 
    // alert(`Registration number ${registrationNumber} copied to clipboard!`);
  };

  useEffect(() => {
    fetchData();
  }, [customerId]);

  const handleClick = async (no) => {
    try {
      const response = await axios.put(`https://api.hotel.tdrsoftware.in/api/approvedEstimateBilling/${customerId}/${no}`);
      console.log(response.data);
      toast.success("Bill Approved Successfully");
      fetchData();  // Refresh the data after approval
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data || "An error occurred");
    }
  };
  const openModal = (id) => {
    setDeleteItemId(id);
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
    setDeleteItemId(null);
  };
  const exportToExcel = () => {
    // Filter out _id and createdAt fields from the data
    const cleanedData = filteredData.map(({ _id, createdAt, ...rest }) => rest);
  
    // Create a worksheet from the cleaned data
    const worksheet = XLSX.utils.json_to_sheet(cleanedData);
  
    // Capitalize the first letter of each header
    const headers = Object.keys(cleanedData[0] || {});
    headers.forEach((header, index) => {
      const cellAddress = XLSX.utils.encode_cell({ c: index, r: 0 });
      worksheet[cellAddress].v = header.charAt(0).toUpperCase() + header.slice(1);
    });
  
    // Calculate totals for cash and online columns
    let totalCash = 0;
    let totalOnline = 0;
    
    cleanedData.forEach((row) => {
      totalCash += parseInt(row.cash) || 0;
      totalOnline += parseInt(row.online) || 0;
    });
  
    // Add the totals row
    const totalRow = {
      cash: totalCash,
      online: totalOnline,
      // Add any other columns you want to include in the totals row
    };
    
    // Append the total row to the worksheet
    XLSX.utils.sheet_add_json(worksheet, [totalRow], { skipHeader: true, origin: -1 });
  
    // Add borders to all cells in the worksheet
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = { c: C, r: R };
        const cellRef = XLSX.utils.encode_cell(cellAddress);
  
        if (!worksheet[cellRef]) continue;
        if (!worksheet[cellRef].s) worksheet[cellRef].s = {};
        
        worksheet[cellRef].s.border = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }
  
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Cash Data');
  
    // Write the workbook to an Excel file
    XLSX.writeFile(workbook, 'EstimateData.xlsx');
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterData(event.target.value, startDate, endDate);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate') {
      setStartDate(value);
    } else if (name === 'endDate') {
      setEndDate(value);
    }
    filterData(searchTerm, name === 'startDate' ? value : startDate, name === 'endDate' ? value : endDate);
  };

  const filterData = (term, startDate, endDate) => {
    let filtered = data;

    // Filter by search term (name or phone number)
    if (term) {
      filtered = filtered.filter(item =>
        item.name.toLowerCase().includes(term.toLowerCase()) ||
        item.phoneNo.includes(term) || item.registration.includes(term)
      );
    }

    // Filter by date range
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      filtered = filtered.filter(item => {
        const itemDate = new Date(item.date);
        return itemDate >= start && itemDate <= end;
      });
    }

    setFilteredData(filtered);
  };
  const handleDelete = async () => {
    try {
     const response= await axios.delete(`https://api.hotel.tdrsoftware.in/api/deleteEstimateBill/${customerId}/${deleteItemId}`);
      toast.success(response.data.message);
      fetchData(); // Refresh the data after deletion
      onClose();
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data || "An error occurred");
    }
  };

  return (
    <Box p={4}>
      <VStack spacing={4} mb={4} display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
        <FormControl>
          <Input
            id="searchTerm"
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Enter name or phone number or registration"
          />
        </FormControl>
        <FormControl display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
          <FormLabel htmlFor="startDate">Start Date</FormLabel>
          <Input
            id="startDate"
            width='45%'
            name="startDate"
            type="date"
            value={startDate}
            onChange={handleDateChange}
          />
        </FormControl>
        <FormControl display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
          <FormLabel htmlFor="endDate">End Date</FormLabel>
          <Input
            width='45%'
            id="endDate"
            name="endDate"
            type="date"
            value={endDate}
            onChange={handleDateChange}
          />
        </FormControl>
      </VStack>
      <Button colorScheme="blue" onClick={exportToExcel} p={2} mb={5}>
       Export to Excel File
      </Button>
      <TableContainer>
        <Table variant='striped' colorScheme='orange'>
          <TableCaption>Estimate Data</TableCaption>
          <Thead>
            <Tr>
              <Th>Party Name/Company Name</Th>
              <Th>Registration</Th>
              <Th>Copy</Th>
              <Th>Status</Th>
              <Th>Date</Th>
              <Th>Phone Number</Th>
              <Th>Address</Th>
              <Th>Hall Room</Th>
              <Th>Projector</Th>
              <Th>Sound</Th>
              <Th>Food Total</Th>
              <Th>Quantity</Th>
              <Th>Rooms</Th>
              <Th>Stay</Th>
              <Th>Room Total</Th>
              <Th>Total</Th>
              <Th>Edit</Th>
              <Th>Delete</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.map((item, index) => (
              <Tr key={index}>
                <Td>{item.name}</Td>
                <Td>{item.registration}</Td>
                <Td>     <Button
                          size="sm"
                          onClick={() => copyRegistrationNumber(item.registration)}
                          colorScheme='blue'
                          disabled={copiedRegistration === item.registration ? true : false} // Disable button if already copied
                        >
                          {copiedRegistration === item.registration ? 'Copied' : 'Copy'}
                        </Button></Td>
                <Td><Button colorScheme={item.approved ? 'green' : 'red'} onClick={() => handleClick(item.registration)}>{item.approved ? "Approved" : "Not Approved"}</Button></Td>
                <Td>{item.date}</Td>
                <Td>{item.phoneNo}</Td>
                <Td>{item.address}</Td>
                <Td>{item.hallRoom}</Td>
                <Td>{item.projector}</Td>
                <Td>{item.sound}</Td>
                <Td>{parseInt(item.total) - parseInt(item.roomPrice)}</Td>
                <Td>{item.breakfastQuantity}</Td>
                <Td>{item.room}</Td>
                <Td>{item.stay}</Td>
                <Td>{item.roomPrice}</Td>
                <Td>{item.total}</Td>
                <Td><Button colorScheme='green' onClick={()=>navigate('/estimate',{
                  state:{
                    updateId:item._id
                  }
                })}><FaEdit/></Button></Td>
                <Td><Button colorScheme='red' onClick={()=>openModal(item._id)}><FaTrash/></Button></Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Deletion
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure you want to delete this item?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default EstimateData;
