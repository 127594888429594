import React,{useEffect,useState} from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Box, VStack, Button,Table, Thead, Tbody, Tr, Th, Td, TableCaption,Image,extendTheme, TableContainer, SimpleGrid,Input ,Text,Card,CardHeader,CardBody,CardFooter,FormLabel} from "@chakra-ui/react";
import { useRef } from "react";
import image1 from '../../assets/img/carousel-1.jpg'
import { useReactToPrint } from "react-to-print";
import {toast} from 'react-hot-toast'
import { numberToWords } from '../../utils/numberToWords';
import { useLocation } from 'react-router-dom';
const GetMergeBill=()=>{
  const location = useLocation();
  const { roomNo, registration } = location.state || {};
  console.log(registration,'registation')
    const [data,setData]=useState([]);
    const [registrationNo,setRegisTrationNo]=useState('')
    const [foodData,setFoodData]=useState([]);
    const [mergeData,setMergeData]=useState([]);
    const [filterRoomBill,setFilterRoomBill]=useState([]);
    const [roomBill,setRoomBill]=useState(0);
    const [room,setRoom]=useState([])
    const [foodBill,setFoodBill]=useState(0);
    const [roomCgst,setRoomSgst]=useState(0)
    const [foodCgst,setFoodSgst]=useState(0)
    const [search,setSearch]=useState('');
    const [printData,setPrintData]=useState([]);
    const [formData,setFormData]=useState({
      name:'',
      registration: "",
      checkIn:'',
      checkOut:'',
      phoneNo:'',
      total:'',
     roomNo:'',
      totalBilling:'',
      serviceName:'',
      price:0,
      mergeBill:''

    })
    console.log(filterRoomBill,'filter')
    
    const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
    
    // const handleSearchChange=(e)=>{
    //     setSearch(e.target.value)
    // }
   
    // const handleInputChange = (e) => {
    //   const { name, value } = e.target;
    //   setFormData({
    //     ...formData,
    //     [name]: value,
    //   });
    // };
    const customerId=localStorage.getItem('customerId')
    console.log(customerId)

    const fetchData=async()=>{
        const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getRoomBillData/${customerId}`)
        setData(response.data)
    }
 

    const fetchData2=async()=>{
        const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getFoodbilldata/${customerId}`)
        setFoodData(response.data)
    }
    const unPaidFoodData=foodData.filter((item)=> item.status === true);
    console.log(unPaidFoodData)
    const fetchData3=async()=>{
      const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getPrintData/${customerId}`)
      setPrintData(response.data)
      const rooms = response.data;
      const updatedRooms = await Promise.all(
        rooms.map(async (room) => {
          const imageResponse = await axios.get('https://api.hotel.tdrsoftware.in/image', {
            params: { customerId, key: room.image }
          });
          room.imageUrl = imageResponse.data;
          return room;
        })
      );
      console.log(updatedRooms, 'updatedRooms');
      setPrintData(updatedRooms);
  }
    let mergedData;
    let filteredRoomBills;
    let filteredFoodBills;
   // let totalRoomBill;
   //let totalBill;
   const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  let roomAdd=async()=>{
    const filteredRoomBills = data.filter(item => item.registration === registrationNo)
    let s=filteredRoomBills.map((item)=> item.roomNo.trim());
    console.log(s,'s');
    let d=s.join(",").split(",");
    let formattedArr = d.map(item => item.trim());
    
    
    console.log(d,'m')
   
    try {
      const responses = await Promise.all(
        formattedArr.map(item =>
          axios.get(`https://api.hotel.tdrsoftware.in/api/getRoomDataByNo/${customerId}/${item}`)
        )
      );

      // Step 3: Extract data from responses
      const roomData = responses.map(response => response.data.room);
      console.log(roomData, 'roomData');
      
      // Step 4: Set the room data in the state
      setRoom(roomData);
    } catch (error) {
      console.error('Error fetching room data:', error);
    }
  };
  
    const mergeBills = (e) => {
      
        // Filter room bill data for the current customer
     const filteredRoomBills = data.filter(item => item.registration === registrationNo)
        ;
        setData(filteredRoomBills)
        setFilterRoomBill(filteredRoomBills)
    
        
         

       const  totalRoomBill = filteredRoomBills.reduce((acc, item) =>{ return parseFloat(acc) + parseFloat(item.total)}, 0);
       const  rCgst=filteredRoomBills.reduce((acc, item) =>{ return parseFloat(acc) + parseFloat(item.cgst)}, 0);
       setRoomSgst(rCgst)
      
       setRoomBill(parseFloat(totalRoomBill))
       
        // Filter food bill data for the current customer
       const  filteredFoodBills = unPaidFoodData.filter(item => item.registration === registrationNo);
   setFoodData(filteredFoodBills)
   const  totalFoodBill = filteredFoodBills.reduce((acc, item) =>{ return parseInt(acc) + parseInt(item.totalBilling)}, 0);
   const fCgst = filteredFoodBills.reduce((acc, item) =>{ return parseInt(acc) + parseInt(item.cgst)}, 0);
   setFoodSgst(fCgst)
       setFoodBill(parseFloat(totalFoodBill))
        // You can merge or combine the filtered data as needed
        // For example, if you want to display them together, you can merge them into a single array
        const mergedData = [...filteredRoomBills, ...filteredFoodBills];
         // console.log(mergedData)
       setMergeData(mergedData)
       console.log(filteredRoomBills,'room')
       roomAdd()
     
       
    };
    console.log(room,'romsexy')

     

     
    console.log(filterRoomBill,'room')
    console.log(foodBill,'food')
   
    console.log(mergeData,'merge');
    
   

    
   let serviceBill=parseFloat(formData.price);
   let totalBill;
   console.log(mergeData,'meregData')
  // console.log(roomCgst,'roomcgst')
   let totalCgst=roomCgst+foodCgst
   let totalSgst=roomCgst+foodCgst
    if(serviceBill == 0){ totalBill=parseFloat(roomBill+foodBill)}else{
      totalBill=parseFloat(roomBill+foodBill+serviceBill)
    };
    
    let taxableAmount=totalBill-(roomCgst+foodCgst)*2;
    const handleSubmit=(e)=>{
      e.preventDefault();
      setFormData({
        name:filterRoomBill[0].name,
        registration:filterRoomBill[0].registration,
        roomNo:filterRoomBill[0].roomNo,
        checkIn:filterRoomBill[0].checkIn,
        checkOut:filterRoomBill[0].checkOut,
        phoneNo:filterRoomBill[0].phoneNo,
        total:filterRoomBill[0].total,
        totalBilling:foodBill,
        serviceName:formData.serviceName,
        price:formData.price,
        mergeBill:totalBill

      })
      

    }
    console.log(formData)
    const save=async()=>{
      try{
        const response=await axios.post("https://api.hotel.tdrsoftware.in/api/mergeBillRegister",{
          customerId:customerId,data:formData
        });
        console.log('Data Submitted: ',response.data);
        
        toast.success(response.data.message)
        setFormData({
          advance:"",
          total:"",
          due:""
        })

      }catch(error){
        toast.error(error)
      }
    }
    
    
   console.log(mergeData,'meregdata')
const theme = extendTheme({
  colors: {
    darkBg: '#1a202c', // Dark background color
    darkText: '#cbd5e0', // Dark text color
  },
  components: {
    Table: {
      variants: {
        dark: {
          bg: 'darkBg',
          color: 'darkText',
          borderRadius: 'md',
          borderWidth: '1px',
          borderColor: 'black.700',
        },
      },
    },
    Td: {
      baseStyle: {
        borderColor: 'gray.700',
        borderWidth: '1px',
      },
    },
    Th: {
      baseStyle: {
        borderColor: 'gray.700',
        borderWidth: '1px',
      },
    },
  },
});
// const handleSubmit=(e)=>{
//   e.preventDefault();
//   setFormData({
//     name:"Dibakar"
//   })
      
// }
//console.log(formData,'formData')
    useEffect(()=>{
        fetchData()
        fetchData2()
        fetchData3()
        if(registration){
          setRegisTrationNo(registration)
        }
    },[registrationNo])
    return (
       <Box diplay='flex' flexDirection='column' p={10} >
        <Box display='flex' gap={10}>
        <Input width='50%' placeholder='Enter Registration No' value={registrationNo} onChange={(e)=>setRegisTrationNo(e.target.value)}/>
        <Button width='20%' onClick={mergeBills} bg='#FEA116' _hover={{color:'white'}}>MergeBill</Button>
        <Button width='20%' onClick={handleSubmit} bg='#FEA116' _hover={{color:'white'}}>Submit</Button>
        <Button width='20%' onClick={save} bg='#FEA116' _hover={{color:'white'}}>Save</Button>
        </Box>
        <Box display='flex' gap={2}>
         
          
       <TableContainer width='full'>
        <Table size='sm' variant='striped' colorScheme='yellow'  >
      <TableCaption>Room Data</TableCaption>
      <Thead >
        <Tr>
          <Th >Guest Name</Th>
          
          <Th>RoomNo</Th>
          <Th>Rate</Th>
          <Th>Sgst</Th>
          <Th>Cgst</Th>
          
          
          
          <Th>Total</Th>
          
          
          
          
        </Tr>
      </Thead>
      <Tbody>
        {mergeData.slice(0,1)?.map((item, index) => (
          <Tr key={index}>
            <Td>{item.name}</Td>
            
            <Td>{item.roomNo}</Td>
            <Td>{item.rate}</Td>
            <Td>{item.sgst}</Td>
            <Td>{item.cgst}</Td>
           
           
            
           
            <Td>{item.total}</Td>
            
           
          </Tr>
        ))}
      </Tbody>
    </Table>
    </TableContainer>
    <TableContainer width='full'>
        <Table size='sm' variant='striped' colorScheme='green'  >
      <TableCaption>Food Data</TableCaption>
      <Thead >
        <Tr>
          <Th >Food Item</Th>
          
          <Th>Room No</Th>
          <Th>Rate</Th>
          <Th>Sgst</Th>
          <Th>Cgst</Th>
          
          
          
          <Th>Total</Th>
          
          
          
          
        </Tr>
      </Thead>
      <Tbody>
        {mergeData.slice(1)?.map((order, index) => (
          <Tr key={index}>
            <Td>{order.foodItem.map((item)=>item.name).join(' , ')}</Td>
           
              <Td>{order.roomNo}</Td>
              <Td>{order.foodRate}</Td>
              <Td>{order.sgst}</Td>
              <Td>{order.cgst}</Td>
              
              <Td>{order.totalBilling}</Td>
              
           
          </Tr>
        ))}
      </Tbody>
    </Table>
    
    </TableContainer>
    </Box>
    <Box
            display="flex"
            gap="10px"
            alignItems="center"
            justifyContent="space-evenly"
            
          >
            <Box width="40%" display="flex" alignItems="center"  
            borderRadius="5px"
            p={4}>
              <FormLabel>Service Name</FormLabel>
              <Input required w='70%'  bg='#FAFAFA ' placeholder="Enter Service Name" name='serviceName' value={formData.serviceName} onChange={handleInputChange}/>
            </Box>
            <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
            borderRadius="5px"
            p={4}>
              <FormLabel>Price</FormLabel>
              <Input type="number" w='80%' bg='#FAFAFA ' placeholder="Enter Price" name='price' value={formData.price} onChange={handleInputChange}/>
            </Box>
          </Box>
   
    
    <Box width='800px' display='flex' flexDirection='column' justifyContent='center' alignItems='center'  >
    {mergeData.slice(0,1) ? <Card bg={image1}  textAlign='center'  ref={componentRef} border='1px solid black' ml={2} mr={2}>
    
      <Text color='purple' fontSize='18px' fontFamily='sans-serif' mt={5}>Final Bill</Text>
        <CardHeader display='flex' justifyContent='center' alignItems='center' gap={5} p={4} boxShadow='md'>
       
        <Box>
        {printData.map((item)=>(
          <Image src={item.imageUrl} w='200px' h='120px' p={2} bg='#FAFAFA' borderRadius='12px' />
         ))}
        </Box>
        <Box color='purple' fontSize='18px' fontFamily='sans-serif'  textAlign='left' >
        {printData.map((item)=>(<> 
          <Text fontSize='2xl' as='b' >{item.name}</Text>
          <Text fontSize='sm' >{item.adress}</Text>
          <Text fontSize='sm'>{item.phoneNo}</Text>
          <Text fontSize='sm'>{item.website}</Text>
          
          <Text fontSize='sm'>Email id:{item.email}</Text>
          <Text fontSize='sm'>Gst No:{item.gstNo}</Text>
          </> 
        ))}
        </Box>
      
        </CardHeader>
          <SimpleGrid columns={1} spacing={2} p={2} textAlign='left'>
          <CardBody border='1px solid black'>
            
          {mergeData.slice(0,1).map((item,index)=>(
              <Box   mb={4} as='b' display='flex' justifyContent='space-between' alignItems='center' fontWeight='15px' color='purple'>
                <Box>
              <Text>Name:{item.name}</Text>
              <Text>Address:{item.address}</Text>
              </Box>
              <Box>
              <Text>Phone:{item.phoneNo}</Text>
              {/* <Text>Phone:{item.address}</Text> */}
              </Box>
             
             
              </Box>
            ))}
          <TableContainer width='full' mb={3}>
        <Table size='sm' variant='simple'    >
      {/* <TableCaption>Room Bill</TableCaption> */}
      <Thead >
        <Tr>
          
          <Th border='1px solid black'>NO</Th>
          <Th border='1px solid black'>Room No</Th>
          <Th border='1px solid black'>Rate</Th>
          <Th border='1px solid black'>Cgst(6%)</Th>
          <Th border='1px solid black'>Sgst(6%)</Th>
         
          
          
          
          
        </Tr>
      </Thead>
      <Tbody>
        {room?.map((item, index) => (
          <Tr key={index}>
                                                                                                                                                                                                                                                                                                                                                                                                
            
                                                                                                                                                                                                                                                                                                                                                                                         
            <Td border='1px solid black'>{index+1}</Td>
            <Td border='1px solid black'>{item.roomNo}</Td>
            <Td border='1px solid black'>{item.rate}</Td>
            <Td border='1px solid black'>{item.cgst}</Td>
            <Td border='1px solid black'>{item.sgst}</Td>
           
            
           
          </Tr>
        ))}
      </Tbody>
    </Table>
    </TableContainer>
    <TableContainer width='full'>
        <Table size='sm' variant='unstyled'    >
      {/* <TableCaption>Food Bill</TableCaption> */}
      <Thead >
        <Tr>
          <Th border='1px solid black' >Food Name</Th>
          <Th border='1px solid black' >RoomNo</Th>
          <Th border='1px solid black' >Cgst(2.5%)</Th>
          <Th border='1px solid black' >Sgst(2.5%)</Th>
          <Th border='1px solid black' >Rate</Th>
           
          
          
          
        </Tr>
      </Thead>
      <Tbody>
        {mergeData.slice(1)?.map((order, index) => (
          <Tr key={index}>
           <Td border='1px solid black'><Table variant='simple' size='sm'>
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Quantity</Th>
                  <Th>Price</Th>
                </Tr>
                
                </Thead>
                <Tbody>{order.foodItem.map((item)=>(
                  <Tr>
                    <Td>{item.name}</Td>
                    <Td>{item.quantity}</Td>
                    <Td>{item.price}</Td>
                  </Tr>
                ))}</Tbody>
                </Table></Td>
           <Td border='1px solid black'>{order.roomNo}</Td>
           <Td border='1px solid black'>{order.cgst}</Td>
           <Td border='1px solid black'>{order.sgst}</Td>
           <Td border='1px solid black'>{order.totalBilling -order.cgst*2}</Td>
              
           
          </Tr>
        ))}
      </Tbody>
    </Table>
    </TableContainer>
    <Box display='flex' justifyContent='space-between' alignItems='center' color='purple' fontSize='sm'>
          <Box>
            <Text>Bank Details</Text>
        {printData.map((item)=>(
                <>
            <Text>A/C NO - {item.acNo}</Text>
            <Text>IFSC CODE - {item.ifsc}</Text>
            <Text>BRANCH - {item.branch}</Text>
            {/* <Text>CONTACT NO - {item.phoneNo}</Text> */}
            
            
            
            </>
          ))}
          </Box>
          <Box display='flex' flexDirection='row'   p={2}>
          <Box display='flex' flexDirection='column' border='1px solid black' >
            <Text  borderBottom='1px solid black' bg='gray.200' p={1}>Summary</Text>
            <Text p={0.5}>Total Value</Text>
            <Text p={0.5}>Taxable Value</Text>
            <Text p={0.5}>{formData.serviceName}</Text>
            <Text p={0.5}>CGST Amount</Text>
            <Text p={0.5}>SGST Amount</Text>
            <Text p={0.5}>Grand Total</Text>
          </Box>
          <Box display='flex' flexDirection='column' borderTop="1px solid black"
      borderRight="1px solid black"
      borderBottom="1px solid black"
      borderLeft="0" >
         <Text p={1} borderBottom='1px solid black' bg='gray.200'>Amount</Text>
          <Text p={0.5}>{taxableAmount}</Text>
            <Text p={0.5}>{taxableAmount}</Text>
           {formData.serviceName ? <Text p={0.5}>{formData.price}</Text> : ""}
            <Text p={0.5}>{totalCgst}</Text>
            <Text p={0.5}>{totalSgst}</Text>
            <Text p={0.5} borderTop='1px solid black'>{totalBill}</Text>
          </Box>
          </Box>
        </Box>
    <Box display='flex' justifyContent='space-around' gap={4} alignItems='center' p={0.5} mt={1}>
    
    <Text as='b' fontSize='sm'>Service - {formData.price}</Text>
    <Text as='b' fontSize='sm'>Total Amount(INR - In Words) -{numberToWords(totalBill)}</Text>
    </Box>
          </CardBody>
          </SimpleGrid>
        <CardFooter display='flex' justifyContent='space-between' p={25} textAlign='left'  >
            <Box color='purple' fontSize='15px' fontFamily='sans-serif'>
              {printData.map((item)=>(
                <>
            {/* <Text>A/C NO - {item.acNo}</Text>
            <Text>IFSC CODE - {item.ifsc}</Text>
            <Text>BRANCH - {item.branch}</Text> */}
            {/* <Text>CONTACT NO - {item.phoneNo}</Text> */}
            
            
            <Text>Booking Party Signature</Text>
            <Text>Date-</Text>
            </>
          ))}
            </Box>
            <Box mr={20} color='purple' fontSize='15px' fontFamily='sans-serif'>
            <Text>Authorized Signature</Text>
            <Text>Date-</Text>
            </Box>

        </CardFooter>
        
      </Card>:''}
     {mergeData ? <Button type="submit" variant='solid' color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={handlePrint}>Print</Button> : ""}
     
    
     </Box>
      
    </Box>
    
    )

}
export default GetMergeBill