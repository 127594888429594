import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Select,
    Box,
    Flex,Image,
    Text,Button,Card,CardBody,SimpleGrid,
    Textarea,CardHeader,CardFooter,TableContainer,Table,TableCaption,Thead,Tr,Th,Td,Tbody,UnorderedList,ListItem,OrderedList

  } from "@chakra-ui/react";
  import { numberToWords } from "../../utils/numberToWords";
  import axios from 'axios';
  import { useSelector,useDispatch } from "react-redux";
  //import image1 from '../../../assets/img/room-2.jpg'
  import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
 // import {useSelector} from 'react-redux'
  import room1 from '../../assets/img/room-1.jpg'
  import room2 from '../../assets/img/room-2.jpg'
  import room3 from '../../assets/img/room-3.jpg'
  import room4 from '../../assets/img/room-1.jpg'
  import room5 from '../../assets/img/room-2.jpg'
  import room6 from '../../assets/img/room-3.jpg'
  import "react-calendar/dist/Calendar.css";
  import Calendar from "react-calendar";
  import React, { useState,useEffect } from "react";
  import { useLocation,useNavigate } from "react-router-dom";
  
  
   
    
  import toast from "react-hot-toast";
    const array = [
      { src: room1, label: 'Room 1' },
      { src: room2, label: 'Room 2' },
      { src: room3, label: 'Room 3' },
      { src: room4, label: 'Room 4' },
      { src: room5, label: 'Room 5' },
      { src: room6, label: 'Room 6' },
    ];
    
  
  
  
    const Estimate = () => {
      const customerId=localStorage.getItem('customerId')
      const location = useLocation();
      const navigate = useNavigate();
      const {updateId}=location.state || {};
    console.log(customerId);
    const [roomData,setRoomData]=useState([]);
    const [roomBook,setRoomBook]=useState([]);
    const [printData,setPrintData]=useState([])
    const [toggle,setToggle]=useState(false)
    const componentRef = useRef();
    
   
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    const fetchData=async()=>{
        const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getRoomdata/${customerId}`)
        setRoomData(response.data)
    }
    const bookedRooms=roomData.filter((item)=>{
     return item.availability == true
    })
    const handleRoomSelectChange = (e) => {
      const roomNo = e.target.value;
      const selectedRoom = roomData.find((room) => room.roomNo === roomNo);
  
      // if (selectedRoom) {
      //   // Check if the room is already selected
      //   if (!selectedRooms.some((room) => room.roomNo === selectedRoom.roomNo)) {
      //     setSelectedRooms([...selectedRooms, selectedRoom]);
      //   } else {
      //     // Room already selected, remove it from selectedRooms
      //     setSelectedRooms(selectedRooms.filter((room) => room.roomNo !== roomNo));
      //   }
      // }
    };
    console.log(updateId,'updateId')
   
    const fetchData3=async()=>{
      const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getPrintData/${customerId}`)
      setPrintData(response.data)
      const rooms = response.data;
      const updatedRooms = await Promise.all(
        rooms.map(async (room) => {
          const imageResponse = await axios.get('https://api.hotel.tdrsoftware.in/image', {
            params: { customerId, key: room.image }
          });
          room.imageUrl = imageResponse.data;
          return room;
        })
      );
      console.log(updatedRooms, 'updatedRooms');
      setPrintData(updatedRooms);
  }
   console.log(printData,"print")
   


   
   
  
  
      
      
      console.log(roomBook.roomNo,'roomBook')
  
      //console.log(roomData)
      const [dueAmount, setDueAmount] = useState(0); 
      const [selectedRooms, setSelectedRooms] = useState([]);
      const [formData, setFormData] = useState({



        name: "",
        
        
        date: "",
        address:"",
        
       phoneNo:"",
        
        gstNo: "",
        hallRoom: "",
        projector:'',
        sound:'',
        foodTotoal:'',
        breakfast:"",
        breakfastQuantity:0,
        breakfastPrice:0,
        registration:"2024-",
        lunch:'',
        lunchQuantity:0,
        lunchPrice:0,
        
        dinner: "",
        dinnerQuantity:0,
        dinnerPrice:0,
        beverage: "",
        beverageQuantity:0,
        beveragePrice:0,
        water:'water',
        waterQuantity:0,
        waterPrice:0,
        others:'',
        othersQuantity:0,
        othersPrice:0,
        room:'',
        stay:'',
        roomPrice:0,remarks:"",
        total:0,
        
      });
      const upDataData=async()=>{
        try {
          const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getEstimateById/${customerId}/${updateId}`)
          let data=response.data.estimate;
          console.log(data,'data')
        setFormData({
          name:data.name || "",
          
          
          date:data.date || "",
          address: data.address || "",
          
         phoneNo: data.phoneNo || "",
          
          gstNo:data.gstNo || "",
          hallRoom:data.hallRoom || "",
          projector:data.projector || '',
          sound:data.sound || '',
          foodTotoal: data.foodTotoal || '',
          breakfast: data.breakfast ||"",
          breakfastQuantity:data.breakfastQuantity|| 0,
          breakfastPrice:data.breakfastPrice || 0,
          registration:data.registration || "2024-",
          lunch:data.lunch || '',
          lunchQuantity:data.lunchQuantity || 0,
          lunchPrice:data.lunchPrice || 0,
          
          dinner:data.dinner || "",
          dinnerQuantity:data.dinnerQuantity|| 0,
          dinnerPrice:data.dinnerPrice || 0,
          beverage:data.beverage || "",
          beverageQuantity:data.beverageQuantity ||0,
          beveragePrice:data.beveragePrice || 0,
          water:data.water || 'water',
          waterQuantity:data.waterQuantity ||0,
          waterPrice:data.waterPrice ||0,
          others:data.others || '',
          othersQuantity:data.othersQuantity ||0,
          othersPrice:data.othersPrice||  0,
          room:data.room || '',
          stay:data.stay || '',
          roomPrice:data.roomPrice ||0,remarks:data.remarks || "",
          total:data.total||0,
        })
        console.log(response.data)
        } catch (error) {
          toast.error(error.message)
        }
        
        
  
      }
      let roomNumber=localStorage.getItem('RoomNo')
     
      useEffect(()=>{
        
        fetchData();
        fetchData3();
        if(updateId){
          upDataData();
        }
      },[customerId])
     
      console.log(roomData,"roomData")
      const handleToggle=()=>{
        if(formData.nationality === "Others"){
          setToggle(!toggle)
        }
       }
      
        
    const handleDueFocus = () => {
      setFormData({
        ...formData,
        child: formData.pax - formData.adult,
      });
    };
       console.log(formData.nationality,"nationality")
       console.log(toggle)
      const validateForm = () => {
        const requiredFields = [
            "name",
        
        
            "date",
            "address",
            
           "phoneNo",
            // "hallRoom",
            "gstNo",
            // "projector",
            // 'sound',
            // "foodCharges",
            // "breakfast",
            // "lunch",
            // "dinner",
            // "beverage",
            // "water",
            // "others",
            // "room",
         
        ];
    
        for (const field of requiredFields) {
          if (!formData[field]) {
            return false;
          }
        }
        return true;
      };
      const generateRandomRegistration = () => {
        const year = new Date().getFullYear(); // Get current year
        const randomDigits = Math.floor(10000 + Math.random() * 90000); // Generate random 5-digit number
        return `${year}-${randomDigits}`;
      };
      
  
      // const handleRoomChange = (e) => {
      //   const selected = roomData.find(item => item.category === e.target.value);
      //   if (selected) {
      //     setFormData({
      //       ...formData,
      //       roomNo: selected.roomNo,
            
            
            
  
      //     });
      //   }
      //   console.log(formData);
      // };
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
      const bookedRoomNos=roomData.filter((item)=>{
        if(item.availability == true){
          if(item.category === formData.category && item.ac === formData.ac){
            return item.roomNo
          }
        }
      })

      let HallRoomTotal=formData.hallRoom ?parseInt(formData.hallRoom)*0.18+parseInt(formData.hallRoom):0
      let SoundTotal=formData.sound ? parseInt(formData.sound)*0.18+parseInt(formData.sound):0
      let ProjectorTotal=formData.projector ? parseInt(formData.projector)*0.18+parseInt(formData.projector):0;
      let hallRoomCgst=formData.hallRoom ?parseInt(formData.hallRoom)*0.09:0;
      let soundCgst=formData.hallRoom ?parseInt(formData.hallRoom)*0.09:0;
      let projectorCgst=formData.hallRoom ?parseInt(formData.hallRoom)*0.09:0;
      let BreakfastTotal=parseInt(formData.breakfastQuantity)*parseInt(formData.breakfastPrice)*0.05+parseInt(formData.breakfastQuantity)*parseInt(formData.breakfastPrice)
      let LunchTotal=parseInt(formData.lunchQuantity)*parseInt(formData.lunchPrice)*0.05+parseInt(formData.lunchQuantity)*parseInt(formData.lunchPrice)
      let DinnerTotal=parseInt(formData.dinnerQuantity)*parseInt(formData.dinnerPrice)*0.05+parseInt(formData.dinnerQuantity)*parseInt(formData.dinnerPrice)
      let BeverageTotal=parseInt(formData.beverageQuantity)*parseInt(formData.beveragePrice)*0.05+parseInt(formData.beverageQuantity)*parseInt(formData.beveragePrice)
      let WaterTotal=parseInt(formData.waterQuantity)*parseInt(formData.waterPrice)*0.05+parseInt(formData.waterQuantity)*parseInt(formData.waterPrice)
      let OthersTotal=parseInt(formData.othersQuantity)*parseInt(formData.othersPrice)*0.05+parseInt(formData.othersQuantity)*parseInt(formData.othersPrice)
      let RoomPrice=formData.roomPrice ? parseInt(formData.stay)*parseInt(formData.roomPrice)*0.12+parseInt(formData.stay)*parseInt(formData.roomPrice) : 0
      let total= HallRoomTotal+SoundTotal+ProjectorTotal+BeverageTotal+BreakfastTotal+LunchTotal+DinnerTotal+WaterTotal+OthersTotal+RoomPrice;
      let foodCgst=parseInt(formData.breakfastQuantity)*parseInt(formData.breakfastPrice)*0.0250+parseInt(formData.waterQuantity)*parseInt(formData.waterPrice)*0.0250+parseInt(formData.dinnerQuantity)*parseInt(formData.dinnerPrice)*0.0250+parseInt(formData.beverageQuantity)*parseInt(formData.beveragePrice)*0.0250+parseInt(formData.beverageQuantity)*parseInt(formData.beveragePrice)*0.0250+parseInt(formData.waterQuantity)*parseInt(formData.waterPrice)*0.0250+parseInt(formData.othersQuantity)*parseInt(formData.othersPrice)*0.0250;
      let foodSgst=parseInt(formData.breakfastQuantity)*parseInt(formData.breakfastPrice)*0.0250+parseInt(formData.waterQuantity)*parseInt(formData.waterPrice)*0.0250+parseInt(formData.dinnerQuantity)*parseInt(formData.dinnerPrice)*0.0250+parseInt(formData.beverageQuantity)*parseInt(formData.beveragePrice)*0.0250+parseInt(formData.beverageQuantity)*parseInt(formData.beveragePrice)*0.0250+parseInt(formData.waterQuantity)*parseInt(formData.waterPrice)*0.0250+parseInt(formData.othersQuantity)*parseInt(formData.othersPrice)*0.0250;
      let foodTotal=BeverageTotal+BreakfastTotal+LunchTotal+DinnerTotal+WaterTotal+OthersTotal;
      let totalSgst=hallRoomCgst+soundCgst+projectorCgst+foodCgst
      let totalCgst=hallRoomCgst+soundCgst+projectorCgst+foodCgst
      let totalTaxable=total-(hallRoomCgst+soundCgst+projectorCgst+foodCgst)*2
       console.log(total,'total');
       const rooms = formData.room;

// Split the string into an array
const roomArray = rooms?.split(',');

// Add quotes around each element
const quotedRooms = roomArray?.map(room => `"${room}"`);

// Join the array into a single string
const result = quotedRooms?.join(',');

console.log(result);
      const handleSubmit =async (e) => {
        e.preventDefault();
        e.preventDefault();
        if (!validateForm()) {
          toast.error("Please fill in all required fields.");
          return;
        }
        // Here you can send formData to your backend server or process it further
       
        // Reset form after submission
        
        setFormData({
            name: formData.name,
            registration:generateRandomRegistration(),
            
            date: formData.date,
            address:formData.address,
           phoneNo:formData.phoneNo,
            
            gstNo: formData.gstNo,
            hallRoom:parseInt( formData.hallRoom),
        projector:parseInt(formData.projector),
        sound:parseInt(formData.sound),
        foodCharges:'',
        
       
        breakfast:formData.breakfast,
        breakfastQuantity:parseInt(formData.breakfastQuantity),
        breakfastPrice:parseInt(formData.breakfastPrice),
        
        lunch:formData.lunch,
        lunchQuantity:parseInt(formData.lunchQuantity),
        lunchPrice:parseInt(formData.lunchPrice),
        
        dinner: formData.dinner,
        dinnerQuantity:parseInt(formData.dinnerQuantity),
        dinnerPrice:parseInt(formData.dinnerPrice),
        beverage: formData.beverage,
        beverageQuantity:parseInt(formData.beverageQuantity),
        beveragePrice:parseInt(formData.beveragePrice),
        water:formData.water,
        waterQuantity:parseInt(formData.waterQuantity),
        waterPrice:parseInt(formData.waterPrice),
        others:formData.others,
        othersQuantity:formData.othersQuantity,
        othersPrice:formData.othersPrice,
        room:formData.room,
        stay:formData.stay,
        
        roomPrice:formData.roomPrice,remarks:formData.remarks,
        total:total,
            
         
        });
     
        
      };
  
      console.log(formData,'formdata')
      const save = async (e) => {
        e.preventDefault();
      
        if (!validateForm()) {
          toast.error("Please fill in all required fields.");
          return;
        }
      
    
      
        try {
          // Update formData state with the new registration number
          const updatedFormData = {
            ...formData,
           total:total,
           room:formData.room,
           
           
          };

          setFormData(updatedFormData);
          if(updateId){
            const response = await axios.put(`https://api.hotel.tdrsoftware.in/api/updateEstimateBill/${customerId}/${updateId}`, {data:formData});
            console.log("Bill updated:", response.data);
            toast.success(response.data.message);
            navigate(location.pathname, { replace: true });
          }
      
      
          // Send POST request with updatedFormData
          
  
     else{
          const response=await axios.post("https://api.hotel.tdrsoftware.in/api/estimateBilling",{
            customerId:customerId,data:formData
          });
      
            console.log("Bill booked:", response.data);
            toast.success(response.data.message);
        }
          setFormData({
            ...formData,
            name: "",
        
        
            date: "",
            address:"",
            
           phoneNo:"",
            
            gstNo: "",
            hallRoom: "",
            projector:'',
            sound:'',
            foodTotoal:'',
            breakfast:"",
            breakfastQuantity:0,
            breakfastPrice:0,
            registration:"2024-",
            lunch:'',
            lunchQuantity:0,
            lunchPrice:0,
            
            dinner: "",
            dinnerQuantity:0,
            dinnerPrice:0,
            beverage: "",
            beverageQuantity:0,
            beveragePrice:0,
            water:'water',
            waterQuantity:0,
            waterPrice:0,
            others:'',
            othersQuantity:0,
            othersPrice:0,
            room:'',
            stay:'',
            roomPrice:0,remarks:"",
            total:0,
                    
          
          
          })
      
      
       
         
         
        } catch (error) {
          console.log(error);
          toast.error("Failed to submit data. Please try again.");
        }
      };
    console.log(formData);
    console.log(printData)
      // useEffect(() => {
      //   // Calculate due amount whenever relevant form fields change
      //   // const calculateDue = () => {
      //   //   const { totalAgreement, advance } = formData;
      //   //   const due = parseInt(totalAgreement) - parseInt(advance);
      //   //   setDueAmount(isNaN(due) ? 0 : due);
      //   // };
      //   // calculateDue();
      // }, [formData.totalAgreement, formData.advance]);
      
      // due=totalAgreement-advance
      return (
        <Box   bg='#EAE5DF '
        columns={{ sm: 2, md: 4 }}
        spacing='8'
        p='10'
        textAlign='center'
        
        color='black' width='100%' height='fit-content'   >
          
          <FormControl isRequired onSubmit={handleSubmit}>
            <Box display="grid"  gridGap={1} >
              <Flex
                display="flex"
                gap="10px"
                alignItems="center"
                justifyContent="space-evenly"
               
              >
                <Box width="40%"  display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                
                p={4}>
                  <FormLabel  >Name</FormLabel>
                  <Input w='70%' bg='#FAFAFA ' placeholder="Enter name"  name='name' value={formData.name} onChange={handleInputChange} />
                </Box>
        
                <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Booking Date</FormLabel>
                  <Input type='date' w='60%'  bg='#FAFAFA ' ac="text" placeholder="Enter Date" name='date' value={formData.date} onChange={handleInputChange} />
                </Box>
              </Flex>
          
              <Box
                display="flex"
                gap="10px"
                alignItems="center"
                justifyContent="space-evenly"
                
              >
                    <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Gst No</FormLabel>
                  <Input w='60%' bg='#FAFAFA ' placeholder="Enter Gst No" name='gstNo' value={formData.gstNo} onChange={handleInputChange}/>
                    
                </Box>
               
                <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>PhoneNo</FormLabel>
                  <Input w='60%' bg='#FAFAFA ' placeholder="Enter Phoneno" name='phoneNo' value={formData.phoneNo} onChange={handleInputChange}/>
                    
                </Box>
              </Box>
          
              <Box
                display="flex"
                gap="10px"
                alignItems="center"
                justifyContent="space-evenly"
                
              >
                <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Address</FormLabel>
                  <Input w='70%' bg='#FAFAFA ' placeholder="Enter Address" name='address' value={formData.address} onChange={handleInputChange}/>
                </Box>
                <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Remarks</FormLabel>
                  <Input w='70%' bg='#FAFAFA ' placeholder="Enter Remarks" name='remarks' value={formData.remarks} onChange={handleInputChange}/>
                </Box>
                
              </Box>
              <Box
                display="flex"
                gap="10px"
                alignItems="center"
                justifyContent="space-evenly"
                
              >
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>HallRoom</FormLabel>
                  <Input w='67%' bg='#FAFAFA ' placeholder="Enter Hall Charges" name='hallRoom' value={formData.hallRoom} onChange={handleInputChange}/>
                  
                </Box>
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Sound</FormLabel>
                  <Input w='67%' bg='#FAFAFA ' placeholder="Sound Price" name='sound' value={formData.sound} onChange={handleInputChange}/>
                  
                </Box>
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Projector</FormLabel>
                  <Input w='67%' bg='#FAFAFA ' placeholder="Projector" name='projector' value={formData.projector} onChange={handleInputChange}/>
                  
                </Box>
                
                
                  
                
              </Box>
              <Box
                display="flex"
                gap="10px"
                alignItems="center"
                justifyContent="space-evenly"
                
              >
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Breakfast</FormLabel>
                  <Input w='67%' bg='#FAFAFA ' placeholder="Enter breakfast Item" name='breakfast' value={formData.breakfast} onChange={handleInputChange}/>
                  
                </Box>
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Quantity</FormLabel>
                  <Input w='67%' bg='#FAFAFA ' placeholder="Quantity" name='breakfastQuantity' value={formData.breakfastQuantity} onChange={handleInputChange}/>
                  
                </Box>
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Price</FormLabel>
                  <Input w='70%' bg='#FAFAFA ' placeholder="Price" name='breakfastPrice' value={formData.breakfastPrice} onChange={handleInputChange}/>
                  
                </Box>
                
                
                  
                
              </Box>
              <Box
                display="flex"
                gap="10px"
                alignItems="center"
                justifyContent="space-evenly"
                
              >
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Lunch</FormLabel>
                  <Input w='67%' bg='#FAFAFA ' placeholder="Enter Lunch Item" name='lunch' value={formData.lunch} onChange={handleInputChange}/>
                  
                </Box>
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Quantity</FormLabel>
                  <Input w='67%' bg='#FAFAFA ' placeholder="Quantity" name='lunchQuantity' value={formData.lunchQuantity} onChange={handleInputChange}/>
                  
                </Box>
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Price</FormLabel>
                  <Input w='70%' bg='#FAFAFA ' placeholder="Price" name='lunchPrice' value={formData.lunchPrice} onChange={handleInputChange}/>
                  
                </Box>
                
                
                  
                
              </Box>
              <Box
                display="flex"
                gap="10px"
                alignItems="center"
                justifyContent="space-evenly"
                
              >
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Dinner</FormLabel>
                  <Input w='67%' bg='#FAFAFA ' placeholder="Enter Dinner Item" name='dinner' value={formData.dinner} onChange={handleInputChange}/>
                  
                </Box>
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Quantity</FormLabel>
                  <Input w='67%' bg='#FAFAFA ' placeholder="Quantity" name='dinnerQuantity' value={formData.dinnerQuantity} onChange={handleInputChange}/>
                  
                </Box>
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Price</FormLabel>
                  <Input w='70%' bg='#FAFAFA ' placeholder="Price" name='dinnerPrice' value={formData.dinnerPrice} onChange={handleInputChange}/>
                  
                </Box>
                
                
                  
                
              </Box>
              <Box
                display="flex"
                gap="10px"
                alignItems="center"
                justifyContent="space-evenly"
                
              >
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Beverage</FormLabel>
                  <Input w='67%' bg='#FAFAFA ' placeholder="Enter  Beverage" name='beverage' value={formData.beverage} onChange={handleInputChange}/>
                  
                </Box>
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Quantity</FormLabel>
                  <Input w='67%' bg='#FAFAFA ' placeholder="Quantity" name='beverageQuantity' value={formData.beverageQuantity} onChange={handleInputChange}/>
                  
                </Box>
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Price</FormLabel>
                  <Input w='70%' bg='#FAFAFA ' placeholder="Price" name='beveragePrice' value={formData.beveragePrice} onChange={handleInputChange}/>
                  
                </Box>
                
                
                  
                
              </Box>
              <Box
                display="flex"
                gap="10px"
                alignItems="center"
                justifyContent="space-evenly"
                
              >
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Water</FormLabel>
                  <Input w='67%' bg='#FAFAFA ' placeholder="Enter  Water" name='water' value={formData.water} onChange={handleInputChange}/>
                  
                </Box>
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Quantity</FormLabel>
                  <Input w='67%' bg='#FAFAFA ' placeholder="Quantity" name='waterQuantity' value={formData.waterQuantity} onChange={handleInputChange}/>
                  
                </Box>
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Price</FormLabel>
                  <Input w='70%' bg='#FAFAFA ' placeholder="Price" name='waterPrice' value={formData.waterPrice} onChange={handleInputChange}/>
                  
                </Box>
                
                
                  
                
              </Box>
              <Box
                display="flex"
                gap="10px"
                alignItems="center"
                justifyContent="space-evenly"
                
              >
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Others</FormLabel>
                  <Input w='67%' bg='#FAFAFA ' placeholder="Enter  Others" name='others' value={formData.others} onChange={handleInputChange}/>
                  
                </Box>
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Quantity</FormLabel>
                  <Input w='67%' bg='#FAFAFA ' placeholder="Quantity" name='othersQuantity' value={formData.othersQuantity} onChange={handleInputChange}/>
                  
                </Box>
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Price</FormLabel>
                  <Input w='70%' bg='#FAFAFA ' placeholder="Price" name='othersPrice' value={formData.othersPrice} onChange={handleInputChange}/>
                  
                </Box>
                
                
                  
                
              </Box>
              <Box
                display="flex"
                gap="10px"
                alignItems="center"
                justifyContent="space-evenly"
                
              >
             
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Rooms</FormLabel>
                  <Input w='67%' bg='#FAFAFA ' placeholder="Enter Rooms" name='room' value={formData.room} onChange={handleInputChange}/>
                  
                </Box>
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Stay</FormLabel>
                  <Input w='67%' bg='#FAFAFA ' placeholder="No of Days" name='stay' value={formData.stay} onChange={handleInputChange}/>
                  
                </Box>
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Price</FormLabel>
                  <Input w='70%' bg='#FAFAFA ' placeholder="Price" name='roomPrice' value={formData.roomPrice} onChange={handleInputChange}/>
                  
                </Box>
                
                
                  
                
              </Box>
             
             
             
              
         
              </Box>
              
              
              
             
            
          
            <Button  variant='solid' mr={4} color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={handleSubmit}>Generate Bill </Button>
            <Button  variant='solid' color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={save}>Save</Button>
          </FormControl>
           <Card mt={10}  textAlign='center'  ref={componentRef} p={5} border='1px solid black' mr={2} ml={2}>
    <Text color='purple' fontSize='18px' fontFamily='sans-serif' mt={5}>Estimate Billing</Text>
    {/* <Text color='purple' fontSize='18px' fontFamily='sans-serif' mt={5}>Serial Number-- {formData.registration}</Text> */}
      <CardHeader display='flex' justifyContent='center' alignItems='center' gap={3} p={2}  >
     
      <Box>
          {printData.map((item)=>(
          <Image src={item.imageUrl} w='180px' h='120px' p={2} bg='#FAFAFA' borderRadius='12px' />
         ))}
        </Box>
     
        <Box color='purple' fontSize='18px' fontFamily='sans-serif'  textAlign='left' >
        {printData.map((item)=>(<> 
          <Text fontSize='2xl' as='b' >{item.name}</Text>
          <Text fontSize='sm' >{item.adress}</Text>
          <Text fontSize='sm'>{item.phoneNo}</Text>
          <Text fontSize='sm'>{item.website}</Text>
          
          <Text fontSize='sm'>Email id:{item.email}</Text>
          <Text fontSize='sm'>Gst No:{item.gstNo}</Text>
          </> 
        ))}
        </Box>
      </CardHeader>
        <Box   p={4} textAlign='left'>
       

        

        
         
        

            <CardBody   borderRadius="5px"  border='1px solid black'>
              <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' color='purple'> 
                <Box display='flex' flexDirection='column' alignItems='left' fontSize='sm'>
                  <Text >Party Name/Company Name -- {formData.name}</Text>
                  <Text>Address -- {formData.address}</Text>
                  <Text>Gst No -- {formData.gstNo}</Text>
                </Box>
                <Box display='flex' flexDirection='column' alignItems='center'>
                  <Text>Bill no -- {formData.registration}</Text>
                  <Text>Date -- {formData.date}</Text>
                  <Text>Phone -- {formData.phoneNo}</Text>
                 
                </Box>

              </Box>
            
            <TableContainer width='full' mt={1} fontSize='sm'>
        <Table size='sm' variant='unstyled' colorScheme='green' >
          <TableCaption>Estimate Billing</TableCaption>
          <Thead>
            <Tr>
              <Th border='1px solid black'>Name</Th>
              <Th border='1px solid black'>Rate</Th>
              <Th border='1px solid black'>Cgst</Th>
              <Th border='1px solid black'>Sgst</Th>
              
            </Tr>
          </Thead>
          <Tbody>
           <Tr>
            <Td border='1px solid black'>HallRoom Charges</Td>
            <Td border='1px solid black'>{formData.hallRoom? formData.hallRoom: 0}</Td>
            <Td border='1px solid black'>{ formData.hallRoom? parseInt(formData.hallRoom)*0.09.toFixed(2)+" (9%)": 0}</Td>
            <Td border='1px solid black'>{formData.hallRoom? parseInt(formData.hallRoom)*0.09.toFixed(2)+" (9%)" : 0}</Td>
           </Tr>
           <Tr>
            <Td border='1px solid black'>Projector</Td>
            <Td border='1px solid black'>{formData.projector ? formData.projector : 0}</Td>
            <Td border='1px solid black'>{formData.projector ?parseInt(formData.projector)*0.09.toFixed(2)+" (9%)":0}</Td>
            <Td border='1px solid black'>{ formData.projector ?parseInt(formData.projector)*0.09.toFixed(2)+" (9%)": 0}</Td>
           </Tr>
           <Tr>
            <Td border='1px solid black'>Sound</Td>
            <Td border='1px solid black'>{formData.sound ? formData.sound : 0}</Td>
            <Td border='1px solid black'>{formData.sound ? parseInt(formData.sound)*0.09.toFixed(2)+" (9%)":0}</Td>
            <Td border='1px solid black'>{formData.sound ?parseInt(formData.sound)*0.09.toFixed(2)+" (9%)" : 0}</Td>
           </Tr>
           <Tr>
            <Td border='1px solid black'>FoodCharges(Quantity X {formData.breakfastQuantity})</Td>
            <Td border='1px solid black'>{formData.breakfast?foodTotal-(foodCgst+foodSgst):0}</Td>
            <Td border='1px solid black'>{formData.breakfast?foodCgst.toFixed(2)+" (2.5%)":0}</Td>
            <Td border='1px solid black'>{formData.breakfast?foodSgst.toFixed(2)+" (2.5%)":0}</Td>
           </Tr>
           
           
           <Tr>
            <Td border='1px solid black'>Room Charges({formData.room}-- ({formData.stay}days))</Td>
            <Td border='1px solid black'>{formData.roomPrice?formData.roomPrice : 0}</Td>
            <Td border='1px solid black'>{formData.roomPrice ? parseInt(formData.roomPrice)*0.06.toFixed(2)+" (6%)" : 0}</Td>
            <Td border='1px solid black'>{formData.roomPrice ? parseInt(formData.roomPrice)*0.06.toFixed(2)+" (6%)": 0}</Td>
           </Tr>
          </Tbody>
        </Table>
      </TableContainer>
            
      {/* <Text as='b' display='flex' justifyContent='end' alignItems='center' p={5}> Remarks--{formData.remarks}</Text>
      <Text as='b' display='flex' justifyContent='end' alignItems='center' p={5}>
            Total: {total ? numberToWords(total) : 'Zero'} ({total})
        </Text> */}
        <Box display='flex' justifyContent='space-between' alignItems='center' color='purple' fontSize='sm'>
          <Box>
            <Text>Bank Details</Text>
        {printData.map((item)=>(
                <>
            <Text>A/C NO - {item.acNo}</Text>
            <Text>IFSC CODE - {item.ifsc}</Text>
            <Text>BRANCH - {item.branch}</Text>
            {/* <Text>CONTACT NO - {item.phoneNo}</Text> */}
            
            
            
            </>
          ))}
          </Box>
          <Box display='flex' flexDirection='row'   p={2}>
          <Box display='flex' flexDirection='column' border='1px solid black' >
            <Text  borderBottom='1px solid black' bg='gray.200' p={1}>Summary</Text>
            <Text p={0.5}>Total Value</Text>
            <Text p={0.5}>Taxable Value</Text>
            <Text p={0.5}>CGST Amount</Text>
            <Text p={0.5}>SGST Amount</Text>
            <Text p={0.5}>Grand Total</Text>
          </Box>
          <Box display='flex' flexDirection='column' borderTop="1px solid black"
      borderRight="1px solid black"
      borderBottom="1px solid black"
      borderLeft="0" >
         <Text p={1} borderBottom='1px solid black' bg='gray.200'>Amount</Text>
          <Text p={0.5}>{totalTaxable}</Text>
            <Text p={0.5}>{totalTaxable}</Text>
            <Text p={0.5}>{totalCgst}</Text>
            <Text p={0.5}>{totalSgst}</Text>
            <Text p={0.5} borderTop='1px solid black'>{total}</Text>
          </Box>
          </Box>
        </Box>
        <Box display='flex' justifyContent='space-between' alignItems='center' fontSize='sm'>
             <Text as='b' display='flex' justifyContent='end' alignItems='center' p={5}> Remarks--{formData.remarks}</Text>
      <Text as='b' display='flex' justifyContent='end' alignItems='center' p={5}>
            Total Amount(INR - In Words): {total ? numberToWords(total) : 'Zero'} 
        </Text>
        </Box>
             
            
            </CardBody>
         
          
        
        
        </Box>
      <CardFooter display='flex' justifyContent='space-between' p={5} textAlign='left' >
     
        
          <Box color='purple' fontSize='sm' fontFamily='sans-serif'>
          {printData.map((item)=>(
                <>
            {/* <Text>A/C NO - {item.acNo}</Text>
            <Text>IFSC CODE - {item.ifsc}</Text>
            <Text>BRANCH - {item.branch}</Text> */}
            {/* <Text>CONTACT NO - {item.phoneNo}</Text> */}
            
            
            <Text>Booking Party Signature</Text>
            <Text>Date-</Text>
            </>
          ))}
          </Box>
          <Box mr={20} color='purple' fontSize='15px' fontFamily='sans-serif'>
          <Text>Authorized Signature</Text>
          <Text>Date-</Text>
          </Box>

      </CardFooter>
      
    </Card>
   {formData ? <Button type="submit" variant='solid' color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={handlePrint}>Print</Button> : ""}
      
        </Box>
      );
  
    
  
    }
  
  
  
  
  
  export default Estimate