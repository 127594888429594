import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  SimpleGrid,
  Box,
  Input,
  FormLabel,Select,
  InputGroup,
  InputLeftAddon,useDisclosure

} from '@chakra-ui/react';
import { useState,useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import axios from 'axios'
import toast from 'react-hot-toast';

const CashUpdateModal = ({isOpen,onClose,id,customerId,toggle1,setToggle1,onOpen,setSelectId,data}) => {
    
    console.log(id,'id')    
    const [formData,setFormData]=useState({
        name: "",
        registration:"",
        date:"",
        phoneNo:"",
        roomNo:"",
        paymentType:"",
        remarks:"",
        cash:"",
        discount:"",
        transaction:'',
        online:"",
        mode:''
       
    
    
    })
    
    

    const handleEdit = async (e) => {
        e.preventDefault();
        
        try {
            // Make the PUT request with formData directly
            const response = await axios.put(`https://api.hotel.tdrsoftware.in/api/updateCashRecipt/${customerId}/${id}`,{data:formData});
            
            toast.success(response.data.message);
            console.log(response, 'response');
            //setSelectId(null);
            // Optionally reset the form after a successful update
            setFormData({
               name: "",
        registration:"",
        date:"",
        phoneNo:"",
        roomNo:"",
        paymentType:"",
        remarks:"",
        cash:"",
        discount:"",
        transaction:'',
        online:"",
        mode:''
            });
    
        } catch (error) {
            toast.error("Error updating Cash Data: " + error.message);
            console.error("Update failed:", error);
        }
    };
   
  //  console.log(data?.name,'data')
   

    useEffect(() => {
        if (data) {
            setFormData({
                name: data.name || "",
                registration: data.registration || "",
                date: data.date || "",
                phoneNo: data.phoneNo || "",
                roomNo: data.roomNo || "",
                paymentType: data.paymentType || "",
                remarks: data.remarks || "",
                cash: data.cash || "",
                discount: data.discount || "",
                transaction: data.transaction || '',
                online: data.online || "",
                mode: data.mode || "",
                
            });
        }
    }, [data]);
    const scroll='inside'
   
    const handleInputChange=(e)=>{
     const {name,value}=e.target;
     setFormData({
        ...formData,
       [name] : value 
     })
    }
    const handleSubmit=(e)=>{
       
    }
    const handleClick=()=>{
      onClose()
      setSelectId(null);
    }
  return (
    <Modal  width='full' size='3xl' isOpen={isOpen} onClose={onClose} onOpen={onOpen} scrollBehavior={scroll}  >
      <ModalOverlay />
      <ModalContent bg='#f9f1e3'>
        <ModalHeader display='flex' justifyContent='space-between' alignItems='center'>
            <Text>Cash Invoice- {data?.name}</Text>
            <Button colorScheme='red' onClick={handleClick} ><IoMdClose/></Button></ModalHeader>
       
        <ModalBody >
         <SimpleGrid columns={2} gap={4} placeItems='left'>
            <InputGroup>
            <InputLeftAddon>Name</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='name' placeholder='Enter name' value={formData.name} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Registration</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='registration' placeholder='Enter Registration' value={formData.registration} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Date</InputLeftAddon>
           <Input width='70%' type='date' bg='white' onChange={handleInputChange} name='date' placeholder='Enter Date' value={formData.date} />
            
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Phone No</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='phoneNo' placeholder='Enter phone number' value={formData.phoneNo} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Room No</InputLeftAddon>
           <Input width='68%' bg='white' onChange={handleInputChange} name='roomNo' placeholder='Enter Room No' value={formData.roomNo} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Payment Type</InputLeftAddon>
           <Select width='70%' bg='white' onChange={handleInputChange} name='paymentType' placeholder='Enter paymentType' value={formData.paymentType} >
            <option value="Advance">Advance</option>
            <option value="Settlement">Settlement</option>
           </Select>
           </InputGroup>
           <InputGroup>
           <InputLeftAddon>Remarks</InputLeftAddon>
           <Input bg='white' onChange={handleInputChange} name='remarks' placeholder='Enter remarks' value={formData.remarks} />
           </InputGroup>
           <InputGroup>
           <InputLeftAddon>Cash Paid</InputLeftAddon>
           <Input bg='white' onChange={handleInputChange} name='cash' placeholder='Enter cash amount' value={formData.cash} />
           </InputGroup>
          <InputGroup>
          <InputLeftAddon>Discount
          </InputLeftAddon>
          <Input bg='white' onChange={handleInputChange} name='discount'  placeholder='Enter discount' value={formData.discount} />
          </InputGroup>
           <InputGroup>
           <InputLeftAddon>Mode</InputLeftAddon>
           <Select bg='white' onChange={handleInputChange} name='mode' placeholder='Enter payment mode' value={formData.mode} >
           <option value="Googlepe">Googlepe</option>
           <option value="Phonepe">Phonepe</option>
           <option value="Paytm">Paytm</option>
           </Select>
           </InputGroup>
          
          <InputGroup>
          <InputLeftAddon>Transaction Id</InputLeftAddon>
          <Input bg='white' onChange={handleInputChange} name='transaction' placeholder='Enter Transaction Id' value={formData.transaction} />
          </InputGroup>


          <InputGroup>
          <InputLeftAddon>Online Paid</InputLeftAddon>
          <Input bg='white' onChange={handleInputChange} name='online' placeholder='Enter online amount' value={formData.online} />
          </InputGroup>
          
           

         </SimpleGrid>
         <Box display='flex' alignItems='center' justifyContent='center' mt={2}>
         <Button colorScheme='green' onClick={(e)=>handleEdit(e)} >Edit</Button>
         </Box>
        </ModalBody>

        <ModalFooter>
         
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CashUpdateModal;
