import { useRef,useState } from 'react';
import { useDispatch } from 'react-redux';
import { MdModeEditOutline } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { deleteFood, updateFood } from '../../redux/reducers/foodItemReducer';
import { Box, Textarea, Image,Text, Input,Button } from '@chakra-ui/react';

function Notes({ text, id,quantity,price,total }) {
    const [toatal1,setTotal1]=useState(total)
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const deleteItem = () => {
    dispatch(deleteFood(id));
    // console.log(id);
  };

  const changeFocus = () => {
    inputRef.current.disabled = false;
    inputRef.current.focus();
  };

  const update = () => {
    updateFood({ id, text,price,quantity,total: inputRef.current.value });
    inputRef.current.disabled = true;
    
  };
console.log(total)
  return (
    <Box className='notes' display='flex' alignItems='center'>
      <Box cursor='pointer' display='flex'>
        <Input
        bg='#FAFAFA '
          ref={inputRef}
          defaultValue={text}
          disabled={true}
          resize='none'
          
        />
         <Input
        bg='#FAFAFA '
          ref={inputRef}
          defaultValue={quantity}
          disabled={true}
          resize='none'
         
        />
         <Input
        bg='#FAFAFA '
          ref={inputRef}
          defaultValue={price}
          disabled={true}
          resize='none'
          
        />
        <Input  bg='#FAFAFA ' ref={inputRef} defaultValue={total} />
        {/* <Button onClick={update}>Update</Button> */}
      </Box>
      <Box className='icons' display='flex' ml={4}>
        {/* <MdModeEditOutline
          onClick={changeFocus}
          src='./update.svg'
          alt='update'
          color='blue'
          cursor='pointer'
          mr={2}
        /> */}
        <MdDeleteOutline
          onClick={deleteItem}
          color='red'
          cursor='pointer'
        />
      </Box>
    </Box>
  );
}

export default Notes;
