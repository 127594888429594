import React, { useEffect, useState } from 'react';
import { Table, Input, TableCaption, Thead, Tbody, Tr, Th, Td, Button, TableContainer, Box, Flex, FormControl, FormLabel } from "@chakra-ui/react";
import axios from 'axios';
import ExcelJS from 'exceljs';
//const ExcelJS = require('exceljs');
import * as XLSX from 'xlsx';

const Report = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [printData, setPrintData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const customerId = localStorage.getItem('customerId');

  const fetchData = async () => {
    const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getMergedData/${customerId}`);
    setData(response.data);
    setFilteredData(response.data);
  };

  const fetchData3 = async () => {
    const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getPrintData/${customerId}`);
    setPrintData(response.data);
    const rooms = response.data;
    const updatedRooms = await Promise.all(
      rooms.map(async (room) => {
        const imageResponse = await axios.get('https://api.hotel.tdrsoftware.in/image', {
          params: { customerId, key: room.image }
        });
        room.imageUrl = imageResponse.data;
        return room;
      })
    );
    setPrintData(updatedRooms);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterData(event.target.value, startDate, endDate);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate') {
      setStartDate(value);
    } else if (name === 'endDate') {
      setEndDate(value);
    }
    filterData(searchTerm, name === 'startDate' ? value : startDate, name === 'endDate' ? value : endDate);
  };

  const filterData = (term, startDate, endDate) => {
    let filtered = data;

    // Filter by search term
    if (term) {
      filtered = filtered.filter(item =>
        (item.registration && item.registration.includes(term)) ||
        (item.roomBill && item.roomBill.some(bill => bill.name.toLowerCase().includes(term.toLowerCase()))) ||
        (item.roomBill && item.roomBill.some(bill => bill.phoneNo && bill.phoneNo.includes(term)))
      );
    }

    // Filter by date range
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      filtered = filtered.filter(item =>
        item.roomBill && item.roomBill.some(bill => {
          const checkInDate = new Date(bill.checkIn);
          const checkOutDate = new Date(bill.checkOut);

          // Check if the booking period intersects with the date range
          return (checkInDate <= end && checkOutDate >= start);
        })
      );
    }

    setFilteredData(filtered);
  };

  const handlePrint = (item) => {
    // Print functionality code as previously defined
  };
//  const XLSX = require('xlsx');
//const XLSX = require('xlsx');

const exportToExcel = () => {
  // Flatten the data to include roomBill, foodBill, foodItem, and cashBill details
  const flattenedData = filteredData.map(record => {
    const foodBillData = record.foodBill.map(food => {
      // Flatten foodItem data within each foodBill entry
      const foodItems = food.foodItem.map(item => ({
        registration: record.registration,
        // foodBillId: food._id, // Assuming _id is the identifier for each foodBill
        // foodBillDate: food.date, // Assuming date field is available in foodBill
         // Assuming date field is available in foodBill
        ...item,
      }));
      return foodItems;
    }).flat();
    const cashBillData = record.cashBill.map(cash => ({
      registration: record.registration,
      ...cash,
    }));

    
    const roomBillData = record.roomBill.map(room => ({
      registration: record.registration,
      ...room,
      ...foodBillData,
      ...cashBillData
    }));
   

   

    
    // Combine all data into a single array
     return [...roomBillData,...foodBillData,...cashBillData];
    
  });
  //console.log(exportToExcel,'exportToExcel')

  // Flatten the array of arrays into a single array
  const cleanedData = flattenedData.flat();
  console.log(cleanedData,'cleanedData')

  // Create a worksheet from the cleaned data
  const worksheet = XLSX.utils.json_to_sheet(cleanedData);

  // Capitalize the first letter of each header
  const headers = Object.keys(cleanedData[0] || {});
  headers.forEach((header, index) => {
    const cellAddress = XLSX.utils.encode_cell({ c: index, r: 0 });
    worksheet[cellAddress].v = header.charAt(0).toUpperCase() + header.slice(1);
  });

  // Add borders to all cells in the worksheet
  const range = XLSX.utils.decode_range(worksheet['!ref']);
  for (let R = range.s.r; R <= range.e.r; ++R) {
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = { c: C, r: R };
      const cellRef = XLSX.utils.encode_cell(cellAddress);

      if (!worksheet[cellRef]) continue;
      if (!worksheet[cellRef].s) worksheet[cellRef].s = {};

      worksheet[cellRef].s.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
    }
  }

  // Create a new workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Report Data');

  // Write the workbook to an Excel file
  XLSX.writeFile(workbook, 'ReportData.xlsx');
};


  
  
  
  
  useEffect(() => {
    fetchData();
    fetchData3();
  }, [customerId]);

  return (
    <div>
      <Flex direction="row" justifyContent='center' alignItems='center' mb={4} gap={4}>
        <Input
          w='40%'
          type="text"
          placeholder="Search by Phone No or Name or Registration"
          value={searchTerm}
          onChange={handleSearchChange}
          mt={5}
        />

        <Box display='flex' flexDirection='row' gap={4} >
          <FormControl>
            <FormLabel htmlFor="startDate">Start Date</FormLabel>
            <Input
              id="startDate"
              name="startDate"
              type="date"
              value={startDate}
              onChange={handleDateChange}
            />
          </FormControl>
          <FormControl >
            <FormLabel htmlFor="endDate">End Date</FormLabel>
            <Input
              id="endDate"
              name="endDate"
              type="date"
              value={endDate}
              onChange={handleDateChange}
            />
          </FormControl>
        </Box>
      </Flex>
      <Button colorScheme="blue" onClick={exportToExcel} p={2} mb={5} >
       Export to Excel File
      </Button>

      <TableContainer width='full'>
        <Table size='lg' variant='striped' colorScheme='blue'>
          <TableCaption>Merge Bill Data</TableCaption>
          <Thead>
            <Tr>
              <Th>Registration No</Th>
              <Th>Room Bill</Th>
              <Th>Food Bill</Th>
              <Th>Cash Bill</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.map((data) => (
              <Tr key={data.registration}>
                <Td>{data.registration}</Td>
                <Td>
                  {data.roomBill && data.roomBill.length > 0 ? (
                    data.roomBill.map((bill) => (
                      <div key={bill._id}>
                        <Table size='sm' variant='striped' colorScheme='teal'>
                          <Thead>
                            <Tr>
                              <Th>Name</Th>
                              <Th>Address</Th>
                              <Th>Phone No</Th>
                              <Th>Check-In</Th>
                              <Th>Check-Out</Th>
                              <Th>Room No</Th>
                              <Th>Total</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            <Tr>
                              <Td>{bill.name}</Td>
                              <Td>{bill.address}</Td>
                              <Td>{bill.phoneNo}</Td>
                              <Td>{bill.checkIn}</Td>
                              <Td>{bill.checkOut}</Td>
                              <Td>{bill.roomNo}</Td>
                              <Td>{bill.total}</Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </div>
                    ))
                  ) : (
                    <p>No room bill data</p>
                  )}
                </Td>
                <Td>
                  {data.foodBill && data.foodBill.length > 0 ? (
                    <Table size='sm' variant='striped' colorScheme='teal'>
                      <Thead>
                        <Tr>
                          <Th>Food Item</Th>
                          <Th>Room No</Th>
                          <Th>Food Rate</Th>
                          <Th>Sgst</Th>
                          <Th>Cgst</Th>
                          <Th>Total</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {data.foodBill.map((bill) => (
                          <Tr key={bill._id}>
                            <Td>{bill.foodItem.map((item) => item.name).join(', ')}</Td>
                            <Td>{bill.roomNo}</Td>
                            <Td>{bill.foodRate}</Td>
                            <Td>{bill.sgst}</Td>
                            <Td>{bill.cgst}</Td>
                            <Td>{bill.totalBilling}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  ) : (
                    <p>No food bill data</p>
                  )}
                </Td>
                <Td>
                  {data.cashBill && data.cashBill.length > 0 ? (
                    <Table size='sm' variant='striped' colorScheme='teal'>
                      <Thead>
                        <Tr>
                          <Th>Payment Type</Th>
                          <Th>Cash</Th>
                          <Th> Transaction Id</Th>
                          <Th>Online</Th>
                          
                        </Tr>
                      </Thead>
                      <Tbody>
                        {data.cashBill.map((bill) => (
                          <Tr key={bill._id}>
                            <Td>{bill.paymentType}</Td>
                            <Td>{bill.cash}</Td>
                            <Td>{bill.transaction}</Td>
                            <Td>{bill.online}</Td>
                           
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  ) : (
                    <p>No Cash bill data</p>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Report;
