import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Input,
  Table,
  TableCaption,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
} from "@chakra-ui/react";
import toast from 'react-hot-toast';
import * as XLSX from 'xlsx'


const FoodBillData = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [printData,setPrintData]=useState([])

  const customerId = localStorage.getItem('customerId');

  const fetchData = async () => {
    const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getFoodBillData/${customerId}`);
    setData(response.data);
    setFilteredData(response.data);
  };
  const handleClick = async (id) => {
    try {
      const response = await axios.put(`https://api.hotel.tdrsoftware.in/api/updateFoodBill/${customerId}/${id}`);
      alert(response.data.message);
      window.location.reload('/foodBillData');
    } catch (error) {
      toast.error(error);
    }
  };
  const fetchData3=async()=>{
    const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getPrintData/${customerId}`)
    setPrintData(response.data)
    const rooms = response.data;
    const updatedRooms = await Promise.all(
      rooms.map(async (room) => {
        const imageResponse = await axios.get('https://api.hotel.tdrsoftware.in/image', {
          params: { customerId, key: room.image }
        });
        room.imageUrl = imageResponse.data;
        return room;
      })
    );
    console.log(updatedRooms, 'updatedRooms');
    setPrintData(updatedRooms);
}
  useEffect(() => {
    fetchData();
    fetchData3()
  }, [customerId]);
 // console.log(data)

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterData(event.target.value, startDate, endDate);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    filterData(searchTerm, event.target.value, endDate);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
    filterData(searchTerm, startDate, event.target.value);
  };
  const exportToExcel = () => {
    // Filter out _id and createdAt fields from the data
    const cleanedData = filteredData.map(({ _id, createdAt, ...rest }) => rest);
  
    // Create a worksheet from the cleaned data
    const worksheet = XLSX.utils.json_to_sheet(cleanedData);
  
    // Capitalize the first letter of each header
    const headers = Object.keys(cleanedData[0] || {});
    headers.forEach((header, index) => {
      const cellAddress = XLSX.utils.encode_cell({ c: index, r: 0 });
      worksheet[cellAddress].v = header.charAt(0).toUpperCase() + header.slice(1);
    });
  
    // Calculate totals for cash and online columns
    let totalCash = 0;
    let totalOnline = 0;
    
    cleanedData.forEach((row) => {
      totalCash += parseInt(row.cash) || 0;
      totalOnline += parseInt(row.online) || 0;
    });
  
    // Add the totals row
    const totalRow = {
      cash: totalCash,
      online: totalOnline,
      // Add any other columns you want to include in the totals row
    };
    
    // Append the total row to the worksheet
    XLSX.utils.sheet_add_json(worksheet, [totalRow], { skipHeader: true, origin: -1 });
  
    // Add borders to all cells in the worksheet
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = { c: C, r: R };
        const cellRef = XLSX.utils.encode_cell(cellAddress);
  
        if (!worksheet[cellRef]) continue;
        if (!worksheet[cellRef].s) worksheet[cellRef].s = {};
        
        worksheet[cellRef].s.border = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }
  
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Cash Data');
  
    // Write the workbook to an Excel file
    XLSX.writeFile(workbook, 'FoodBillData.xlsx');
  };

  const filterData = (term, start, end) => {
    let filtered = data.filter(order => {
      const orderDate = new Date(order.createdAt).getTime();
      const startFilter = start ? new Date(start).getTime() : null;
      const endFilter = end ? new Date(end).getTime() : null;

      if (!term && !startFilter && !endFilter) {
        return true; // No filters applied, return all data
      }

      const matchesTerm = term && (
        order.registration.includes(term) ||
        order.roomNo.includes(term) // Include roomNo in search
      );

      const withinDateRange = (!startFilter || orderDate >= startFilter) &&
        (!endFilter || orderDate <= endFilter);

      return matchesTerm && withinDateRange;
    });

    setFilteredData(filtered);
  };

  const handlePrint = (order) => {
    const foodItemsContent = order.foodItem.map(item => `
      <tr>
        <td>${item.name}</td>
        <td>${order.roomNo}</td>
        <td>${item.quantity}</td>
        <td>${item.price}</td>
      </tr>
    `).join('');
  
    const content = `
       <html>
      <head>
        <title>Food Invoice</title>
        <style>
          body { font-family: Arial, sans-serif;color:purple }
          table { width: 100%; border-collapse: collapse; }
          th, td { border: 1px solid #dddddd; text-align: left; padding: 8px; }
          th { background-color: #f2f2f2; }
        </style>
      </head>
      <body>
        <h2 style="text-align:center">Food Invoice</h2>
        <div style="display: flex; justify-content: center; align-items: center; gap:18px">
          ${printData.map((item) => `
            
              <img src="${item.imageUrl}" style="width: 200px; height: 200px;borderRadius:12px" />
              <div>
                <h1>${item.name}</h1>
                <h5>${item.adress}</h5>
                <h5>Phone:${item.phoneNo}</h5>
                <h5>Website:${item.website}</h5>
                <h5>Email id: ${item.email}</h5>
                <h5>Gst No: ${item.gstNo}</h5>
              </div>
            
          `).join('')}
        </div>
        <div style="display:flex;flex-direction:row;gap:10px;justify-content:space-evenly">
        <h4>Room No-${order.roomNo}</h4>
       
        <h4>Status-${order.status ? "Due" : "Paid"}</h4>
        </div>
        <table>
          <thead>
            <tr>
              <th>Food Name</th>
              <th>Quantity</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            ${order.foodItem.map((foodItem) => `
              <tr>
                <td>${foodItem.name}</td>
                <td>${foodItem.quantity}</td>
                <td>${foodItem.price}</td>
              </tr>
            `).join('')}
          </tbody>
        </table>
        <table style="margin-top:10px">
          <thead>
            <tr>
              <th>Amount</th>
              <th>Taxable Amount</th>
              <th>Cgst(2.5%)</th>
              <th>Sgst(2.5%)</th>
              <th>Total</th>
              
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>${order.foodRate}</td>
              <td>${order.foodRate}</td>
              <td>${order.sgst}</td>
              <td>${order.cgst}</td>
              <td>${order.totalBilling}</td>
             
            </tr>
          </tbody>
        </table>
        <script>
          window.onload = () => window.print();
        </script>
      </body>
    </html>
    `;
  
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.open();
    printWindow.document.write(content);
    printWindow.document.close();
  };
  
  return (
    <Box>
      <Box mb={4} display="flex" justifyContent="space-between">
        <Input
          w='40%'
          type="text"
          placeholder="Search by Registration or Room No"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <Input
          w='20%'
          type="date"
          placeholder="Start Date"
          value={startDate}
          onChange={handleStartDateChange}
          mr={2}
        />
        <Input
          w='20%'
          type="date"
          placeholder="End Date"
          value={endDate}
          onChange={handleEndDateChange}
          mr={2}
        />
      </Box>
      <Button colorScheme="blue" onClick={exportToExcel} p={2} mb={5}>
       Export to Excel File
      </Button>
      <TableContainer width='full'>
        <Table size='sm' variant='striped' colorScheme='green'>
          <TableCaption>Food Data</TableCaption>
          <Thead>
            <Tr>
              <Th>Food Item</Th>
              <Th>Registration</Th>
              <Th>Room No</Th>
              <Th>Food Rate</Th>
              <Th>Total Bill</Th>
              <Th>Status</Th>
              <Th>Paid</Th>
              <Th>Created At</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.map((order, index) => (
              <Tr key={index}>
                <Td>{order.foodItem.map(item => item.name).join(', ')}</Td>
                <Td>{order.registration}</Td>
                <Td>{order.roomNo}</Td>
                <Td>{order.foodRate}</Td>
                <Td>{order.totalBilling}</Td>
                <Td>{order.status ? "Due" : "Paid"}</Td>
                <Td><Button colorScheme='blue' onClick={()=>handleClick(order._id)}>Change</Button></Td>
                <Td>{new Date(order.createdAt).toLocaleString('en-GB')}</Td>
                <Td>
                  <Button colorScheme="blue" size="sm" onClick={() => handlePrint(order)}>Print</Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FoodBillData;
