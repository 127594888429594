import React, { createContext, useContext, useState } from 'react';

const BillItemsContext = createContext();

export const useBillItems = () => useContext(BillItemsContext);

 export const BillItemsProvider = ({ children }) => {
  const [billItems, setBillItems] = useState([]);

  const addToBill = (item) => {
    const existingItemIndex = billItems.findIndex((billItem) => billItem._id === item._id);

    if (existingItemIndex !== -1) {
      const newBillItems = [...billItems];
      newBillItems[existingItemIndex].quantity += 1;
      newBillItems[existingItemIndex].price = newBillItems[existingItemIndex].quantity * newBillItems[existingItemIndex].rate;
      setBillItems(newBillItems);
    } else {
      setBillItems([...billItems, { ...item, quantity: 1, price: item.rate }]);
    }
  };

  const updateQuantity = (index, quantity) => {
    const newBillItems = [...billItems];
    newBillItems[index].quantity = quantity;
    newBillItems[index].price = quantity * newBillItems[index].rate;
    setBillItems(newBillItems);
  };

  const deleteItem = (index) => {
    const newBillItems = billItems.filter((_, i) => i !== index);
    setBillItems(newBillItems);
  };

  const clearBill = () => {
    setBillItems([]);
  };

  return (
    <BillItemsContext.Provider value={{ billItems, addToBill, updateQuantity, deleteItem, clearBill }}>
      {children}
    </BillItemsContext.Provider>
  );
};

export default BillItemsContext
