import { configureStore } from "@reduxjs/toolkit";
import  foodReducer  from './reducers/foodItemReducer';
import authReducer from './reducers/authReducer';
import cartReducer from './reducers/cartReducer';

 const store=configureStore({
    reducer: {
        food:foodReducer,
        auth:authReducer,
        cart: cartReducer,
    },
});
export default store