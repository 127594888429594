import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  SimpleGrid,
  Box,
} from '@chakra-ui/react';
import { IoMdClose } from "react-icons/io";
import axios from 'axios'
import toast from 'react-hot-toast';

const CustomerStatusModal = ({ isOpen, onClose, bookingData,toggle2,setToggle2,setBookingData,customerId,registration }) => {
    const [data,setData]=useState([])
    console.log(registration,customerId);
    console.log(bookingData)
  const fetchData=async()=>{
    try {
        const response=await axios.get(`https://api.hotel.tdrsoftware.in/api/getMergedData/${customerId}/${registration}`);
       // toast.success(response.data.message)
        setData(response.data)
        console.log(response)
    } catch (error) {
        toast.error(error.message)
    }
  }
  const scroll='inside'
   
//   const handleInputChange=(e)=>{
//    const {name,value}=e.target;
//    setFormData({
//       ...formData,
//      [name] : value 
//    })
//   }
  useEffect(()=>{
    fetchData()
  },[customerId])
    const handleClick=()=>{
       setToggle2(!toggle2)
      // setBookingData([])
    }
  return (
    <Modal isOpen={isOpen} onClose={onClose} width='full' size='3xl' scrollBehavior={scroll}  >
      <ModalOverlay />
      <ModalContent bg='#f9f1e3'>
        <ModalHeader display='flex' justifyContent='space-between' alignItems='center'>
            <Text fontSize='sm'>Customer Status</Text>
            <Button colorScheme='red' onClick={handleClick} ><IoMdClose/></Button></ModalHeader>
       
        <ModalBody >
            <Box display='flex' gap='4x' flexDirection='column' justifyContent='start' alignItems='start'  >
                <Text fontSize='xl' as='b' mb={2}>Room Bill</Text>
        
          {data.roomBillData ? data.roomBillData.map((room)=>(
             <SimpleGrid columns={3} gap={4} placeItems='left'  >
           
            <Text as='i' >Name--{room.name}</Text>
            <Text as='i' >Address--{room.address}</Text>
            <Text as='i' >Phone--{room.phoneNo}</Text>
            <Text as='i' >CheckIn--{room.checkIn}</Text>
            <Text as='i' >CheckOut--{room.checkOut}</Text>
            <Text as='i' >Registration--{room.registration}</Text>
            <Text as='i' >Room No--{room.roomNo}</Text>
            <Text as='i' >Rate--{room.rate}</Text>
            <Text as='i' >Cgst--{room.cgst}</Text>
            <Text as='i' >Sgst--{room.sgst}</Text>
            <Text as='i' fontSize='xl' >Total--{room.total}</Text>
            </SimpleGrid>
          )) : "No Room Bill Data"}

         
         </Box>
         <Box display='flex' mt={5} gap='4x' flexDirection='column' justifyContent='start' alignItems='start' >
                <Text fontSize='xl' as='b' mb={2}>Food Bill</Text>
        
          {data.foodData ? data.foodData.map((room,index)=>(
             <Box display='flex' flexDirection='row' mt={3} gap='10' border='1px solid black' p={3} borderRadius='4px' bg='white'>
                <Text as='b'>Order:{index+1}</Text>
            <table style={{ borderCollapse: 'collapse', width: '37%',padding:'3px' }}>
                <thead>
                <tr style={{ border: '1px solid black' }}>
                <td style={{ border: '1px solid black' }}>Name</td>
                <td  style={{ border: '1px solid black' }}>Quantity</td>
                <td  style={{ border: '1px solid black' }}>Price</td>
                </tr>
                </thead>
                <tbody>
                    {room.foodItem.map((item)=>(
                       <tr style={{ border: '1px solid black' }}>
                        <td style={{ border: '1px solid black' }}>{item.name}</td>
                        <td  style={{ border: '1px solid black' }}>{item.quantity}</td>
                        <td  style={{ border: '1px solid black' }}>{item.price}</td>
                       </tr> 
                    ))}
                    </tbody>
                
            </table>
            <SimpleGrid columns={2} gap={2} placeItems='left'>
            <Text as='i' >Room-{room.roomNo}</Text>
            <Text as='i' >Status-{room.status ? 'Due' : "Paid"}</Text>
            <Text as='i' >Cgst-{room.cgst}</Text>
            <Text as='i' >Sgst-{room.sgst}</Text>
            <Text as='i' >Total-{room.totalBilling}</Text>
           
            </SimpleGrid> </Box>
          )) : "No Food Bill Data"}

         
         </Box>

         <Box display='flex' mt={5} gap='4x' flexDirection='column' justifyContent='start' alignItems='start' >
                <Text fontSize='xl' as='b' mb={2}>Cash Bill</Text>
        
          {data.cashData ? data.cashData.map((cash,index)=>(
            <>
           
             <SimpleGrid columns={3} gap={4} placeItems='left'  border='1px solid black' p={3} borderRadius='6px' bg='white'>
            <Text as='i'>Cash Bill:{index+1}</Text>
            <Text as='i'>name:{cash.name}</Text>
            <Text as='i' >Room No--{cash.roomNo}</Text>
            <Text as='i' >Phone--{cash.phoneNo}</Text>
            <Text as='i' >Status--{cash.status ? 'Due' : "Paid"}</Text>
            <Text as='i' >Paid At--{new Date(cash.createdAt).toLocaleString('en-GB')}</Text>
            <Text as='i' >Remarks--{cash.remarks}</Text>
            <Text as='i' >Payment Type--{cash.paymentType}</Text>
            <Text as='i' >Cash--{cash.cash}</Text>
            <Text as='i' >Online Mode--{cash.mode}</Text>
            <Text as='i' >Online Paid--{cash.online}</Text>
            <Text as='i' >Transaction Id--{cash.transaction}</Text>
           
            </SimpleGrid>
            </>
          )) : "No Food Bill Data"}

         
         </Box>
        </ModalBody>

        <ModalFooter>
         
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomerStatusModal;
