import logo from "./logo.svg";
import { useState } from "react";
import "./App.css";
import { Form } from "./modules/customer";
import { layout } from "@chakra-ui/react";
import Navbar from "./components/Navbar/Navbar";
//import Sidebar from "./components/Sidebar";
import Layout from "./layout/index";
import { Route,Routes } from "react-router-dom";
import Login from "./modules/auth/Login/Login";
import Signup from "./modules/auth/Signup/Signup";
import  Notes  from "./modules/billing/Note";
import ShowTodo from "./modules/billing/AddFood";
import  TextField  from "./modules/billing/TextField";

import { Toaster } from "react-hot-toast";

function App() {
 // const [showInput, setShowInput] = useState(false);
  return (
    <div className="App" >
      <Toaster  toastOptions={{
    className: '',
    style: {
      
      padding: '10px',
      color: '#713200',
      width:"300px"
    },
  }}   />
      <Layout/>
      {/* <Routes>
        
        ]<Route path='/' element={<Login/>}/>
        ]<Route path='/home' element={<Layout/>}/>
      </Routes> */}
      
     
    </div>
  );
}

export default App;
