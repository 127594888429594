import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  SimpleGrid,
  Box,
} from '@chakra-ui/react';
import { IoMdClose } from "react-icons/io";
import axios from 'axios'

const BookingInfoModal = ({ isOpen, onClose, bookingData,toggle,setToggle,setBookingData }) => {
  
    const handleClick=()=>{
       setToggle(!toggle)
      // setBookingData([])
    }
  return (
    <Modal isOpen={isOpen} onClose={onClose} width='full' size='3xl'  >
      <ModalOverlay />
      <ModalContent bg='#f9f1e3'>
        <ModalHeader display='flex' justifyContent='space-between' alignItems='center'>
            <Text>Booking Information</Text>
            <Button colorScheme='red' onClick={handleClick} ><IoMdClose/></Button></ModalHeader>
       
        <ModalBody >
          <SimpleGrid columns={3} spacing={3} as='i'>
            {bookingData.map((item,index)=>(
                <>
            <Box>
              <Text><strong>Name:</strong> {item.name}</Text>
            </Box>
            <Box>
              <Text><strong>Email:</strong> {item.email}</Text>
            </Box>
            <Box>
              <Text><strong>Nationality:</strong> {item.nationality}</Text>
            </Box>
            <Box>
              <Text><strong>Purpose:</strong> {item.purpose}</Text>
            </Box>
            <Box>
              <Text><strong>Registration:</strong> {item.registration}</Text>
            </Box>
            <Box>
              <Text><strong>Pax:</strong> {item.pax}</Text>
            </Box>
            <Box>
              <Text><strong>Adult:</strong> {item.adult}</Text>
            </Box>
            <Box>
              <Text><strong>Child:</strong> {item.child}</Text>
            </Box>
            <Box>
              <Text><strong>Check-In Date:</strong> {item.checkIn}</Text>
            </Box>
            <Box>
              <Text><strong>Check-Out Date:</strong> {item.checkOut}</Text>
            </Box>
            <Box>
              <Text><strong>Room No:</strong> {item.roomNo}</Text>
            </Box>
            <Box>
              <Text><strong>Category:</strong> {item.category}</Text>
            </Box>
            <Box>
              <Text><strong>AC:</strong> {item.ac}</Text>
            </Box>
            <Box>
              <Text><strong>Phone No:</strong> {item.phoneNo}</Text>
            </Box>
            <Box>
              <Text><strong>Address:</strong> {item.address}</Text>
            </Box>
            <Box>
              <Text><strong>Adhar:</strong> {item.adhar}</Text>
            </Box>
            <Box>
              <Text><strong>ArrivedFrom:</strong> {item.arrivedFrom}</Text>
            </Box>
            <Box>
              <Text><strong>Next Destination:</strong> {item.nextDestination}</Text>
            </Box>
            <Box>
              <Text><strong>Passport:</strong> {item.passport}</Text>
            </Box>
            <Box>
              <Text><strong>Issue Date:</strong> {item.issue}</Text>
            </Box>
            <Box>
              <Text><strong>Arrival:</strong> {item.arrival}</Text>
            </Box>
            <Box>
              <Text><strong>Place of Issue:</strong> {item.placeOfIssue}</Text>
            </Box>
            <Box>
              <Text><strong>Stay:</strong> {item.stay}</Text>
            </Box>
            <Box>
              <Text><strong>Visa:</strong> {item.visa}</Text>
            </Box>
            <Box>
              <Text><strong>Visa Issue date:</strong> {item.issueVissa}</Text>
            </Box>
            <Box>
              <Text><strong>Expiry:</strong> {item.expiry}</Text>
            </Box>
            </>
            ))}
            {/* Add more fields here as needed */}
          </SimpleGrid>
        </ModalBody>

        <ModalFooter>
         
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BookingInfoModal;
